import * as h from "vhtml";
import { Section, EducationContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import { fontStyles } from "../font_styles";
import { constructTextWithLineBreaks } from "../../../utility/index";

type EducationEntry = {
  institution: string;
  location: string;
  start: string;
  end: string;
  degree?: string;
  description?: string;
};

type RenderEducationSectionParams = {
  index: number;
  isLastRow: boolean;
  height: number;
  isFirstRow: boolean;
  left: EducationEntry;
  right?: EducationEntry;
};

const renderEducationSection = ({
  index,
  isLastRow,
  height,
  isFirstRow,
  left,
  right,
}: RenderEducationSectionParams) => (
  <div
    class={`education ${isLastRow === true ? "education--last" : ""}`}
    data-section-name={`education-row:${index}`}
    data-assumed-height={height}
  >
    {isFirstRow === true ? (
      <h1 class="education__headline">
        <svg
          aria-hidden={true}
          focusable={false}
          data-prefix="fas"
          data-icon="graduation-cap"
          class="svg-inline--fa fa-graduation-cap fa-w-20"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewbox="0 0 640 512"
        >
          <path
            fill="currentColor"
            d="M622.34 153.2L343.4 67.5c-15.2-4.67-31.6-4.67-46.79 0L17.66 153.2c-23.54 7.23-23.54 38.36 0 45.59l48.63 14.94c-10.67 13.19-17.23 29.28-17.88 46.9C38.78 266.15 32 276.11 32 288c0 10.78 5.68 19.85 13.86 25.65L20.33 428.53C18.11 438.52 25.71 448 35.94 448h56.11c10.24 0 17.84-9.48 15.62-19.47L82.14 313.65C90.32 307.85 96 298.78 96 288c0-11.57-6.47-21.25-15.66-26.87.76-15.02 8.44-28.3 20.69-36.72L296.6 284.5c9.06 2.78 26.44 6.25 46.79 0l278.95-85.7c23.55-7.24 23.55-38.36 0-45.6zM352.79 315.09c-28.53 8.76-52.84 3.92-65.59 0l-145.02-44.55L128 384c0 35.35 85.96 64 192 64s192-28.65 192-64l-14.18-113.47-145.03 44.56z"
          ></path>
        </svg>
        <span>Bildungsweg</span>
      </h1>
    ) : (
      ""
    )}
    <div class="education__row">
      {[left, right]
        .filter((entry) => entry !== undefined)
        .map(
          ({
            location,
            institution,
            start,
            end,
            degree,
            description,
          }: EducationEntry) => (
            <div class="education__item">
              <div class="education__sub-headline">
                {institution} <span class="education__city">{location}</span>
              </div>
              <div class="education__date">
                {start} - {end}
              </div>
              {degree !== undefined ? (
                <div class="education__degree">{degree}</div>
              ) : (
                ""
              )}
              {description !== undefined ? (
                <div class="education__description">{description}</div>
              ) : (
                ""
              )}
            </div>
          )
        )}
    </div>
  </div>
);
export const constructEducationSections = (
  education: Array<EducationContent>
): Array<Section> => {
  const sections: Array<Section> = [];

  type PreformattedEducationContent = EducationContent & {
    subHeadlineHeight: number;
  };
  const preformattedEducation: Array<PreformattedEducationContent> =
    education.map((educationEntry) => {
      const subHeadlineMarginBottom = 8;
      const { height } = constructTextWithLineBreaks({
        text: `${educationEntry.institution} ${educationEntry.location}`,
        font: fontStyles.subHeadline,
        containerWidth: 312,
      });
      return {
        ...educationEntry,
        subHeadlineHeight: height + subHeadlineMarginBottom,
      };
    });

  for (let i = 0; i < preformattedEducation.length; i += 2) {
    const leftEducation = preformattedEducation[i];
    const rightEducation = preformattedEducation[i + 1];

    const isLastRow = i + 2 >= preformattedEducation.length;

    const getEntryHeight = (entry: PreformattedEducationContent) => {
      const { subHeadlineHeight } = entry;
      const timeSpanHeight = 24;
      const degreeHeight = entry.degree === undefined ? 0 : 19;
      const descriptionHeight = entry.description === undefined ? 0 : 21;
      const margin = isLastRow === true ? 75 : 50;

      return (
        subHeadlineHeight +
        timeSpanHeight +
        degreeHeight +
        descriptionHeight +
        margin
      );
    };

    const rowHeight =
      rightEducation === undefined
        ? getEntryHeight(leftEducation)
        : Math.max(
            getEntryHeight(leftEducation),
            getEntryHeight(rightEducation)
          );
    const headerHeightInPx = i === 0 ? 73 : 0;
    const sectionHeight = headerHeightInPx + rowHeight;

    sections.push({
      isVisible: education.length !== 0,
      containerPreference: ["default"],
      tags: ["cv"],
      height: sectionHeight,
      render: () =>
        renderEducationSection({
          isFirstRow: i === 0,
          isLastRow: isLastRow,
          left: {
            start: format(leftEducation.start, "MM/yyyy"),
            end:
              leftEducation.end === undefined
                ? "Heute"
                : format(leftEducation.end, "MM/yyyy"),
            institution: leftEducation.institution,
            location: leftEducation.location,
            degree: leftEducation.degree,
            description: leftEducation.description,
          },
          right:
            rightEducation === undefined
              ? undefined
              : {
                  start: format(rightEducation.start, "MM/yyyy"),
                  end:
                    rightEducation.end === undefined
                      ? "Heute"
                      : format(rightEducation.end, "MM/yyyy"),
                  institution: rightEducation.institution,
                  location: rightEducation.location,
                  degree: rightEducation.degree,
                  description: rightEducation.description,
                },
          index: i,
          height: sectionHeight,
        }),
    });
  }
  return sections;
};
