import * as yup from 'yup';
import { ValidationContext } from './other';

// e.g. 2002-02-12T00:00:00.000Z
export const serializedDateValidation = (message: string, optional: boolean) => {
  if (optional === true) {
    return yup
      .string()
      .nullable()
      .test('date-check', 'Das angegebene Datum ist nicht gültig.', (dateString) => {
        if (dateString === null || dateString === undefined) {
          return true;
        }
        if (typeof dateString !== 'string') {
          return false;
        }
        const date = new Date(dateString);
        return !isNaN(date.getTime());
      })
      .strict(true);
  }
  return yup
    .string()
    .required(message)
    .test('date-check', 'Das angegebene Datum ist nicht gültig.', (dateString) => {
      if (typeof dateString !== 'string') {
        return false;
      }
      const date = new Date(dateString);
      return !isNaN(date.getTime());
    })
    .strict(true);
};

export const jsDateValidation = (message: string, optional: boolean) => {
  if (optional === true) {
    return yup.date().nullable().strict(true);
  }
  return yup.date().required(message).strict(true);
};

// Letter
export const subject = yup
  .string()
  .required('Gib einen Betreff an.')
  .max(140, 'Der Betreff darf maximal 140 Zeichen haben.')
  .strict(true);
export const letter = yup.string().required('Bitte füge einen Text fürs Anschreiben hinzu.').strict(true);

export const letterSchema = yup.object().shape({
  subject: subject,
  letter: letter,
});

// Company
export const companyName = yup
  .string()
  .required('Gib den Namen des Unternehmens an.')
  .max(75, 'Der Unternehmensname darf maximal 75 Zeichen haben.')
  .strict(true);
export const companyContactPerson = yup
  .string()
  .max(75, 'Der Ansprechpartner darf maximal 75 Zeichen haben.')
  .nullable()
  .strict(true);
export const companyStreet = yup
  .string()
  .max(50, 'Der Straßenname des Unternehmenssitzes darf maximal 50 Zeichen haben.')
  .nullable()
  .strict(true);
export const companyStreetNumber = yup
  .string()
  .max(10, 'Die Hausnummer darf maximal 10 Zeichen haben.')
  .nullable()
  .strict(true);
export const companyPostcode = yup
  .string()
  .min(4, 'Die Postleitzahl sollte aus mindestens vier Zeichen bestehen.')
  .max(5, 'Die Postleitzahl darf maximal aus fünf Zeichen bestehen.')
  .nullable()
  .strict(true);
export const companyCity = yup
  .string()
  .max(25, 'Die Stadt des Unternehmenssitzes darf maximal 25 Zeichen haben.')
  .nullable()
  .strict(true);

export const companySchema = yup.object().shape({
  companyName: companyName,
  companyContactPerson: companyContactPerson,
  companyStreet: companyStreet,
  companyStreetNumber: companyStreetNumber,
  companyPostcode: companyPostcode,
  companyCity: companyCity,
});

// Resume
export const firstName = yup
  .string()
  .required('Gib Deinen Vornamen an.')
  .max(25, 'Der Vorname darf maximal 25 Zeichen haben.')
  .strict(true);
export const lastName = yup
  .string()
  .required('Gib Deinen Nachnamen an.')
  .max(25, 'Der Nachname darf maximal 25 Zeichen haben.')
  .strict(true);

export const birthday = (validationContext: ValidationContext) => {
  if (validationContext === 'serialize') {
    return serializedDateValidation('Gib Dein Geburtsdatum an.', false);
  }
  if (validationContext === 'entity') {
    return jsDateValidation('Gib Dein Geburtsdatum an.', false);
  }
  // e.g. 12.12.2002
  return yup
    .string()
    .required('Gib Dein Geburtsdatum an.')
    .matches(/^\d{2}\.\d{2}\.\d{4}$/, 'Das Datum muss dem Muster DD.MM.JJJJ entsprechen.')
    .test('date-check', 'Das angegebene Datum ist nicht gültig.', (dateString) => {
      if (typeof dateString !== 'string') {
        return false;
      }
      const [day, month, year] = dateString.split('.');
      const date = new Date(`${year}-${month}-${day}`);
      return !isNaN(date.getTime());
    })
    .strict(true);
};

export const birthplace = yup
  .string()
  .required('Gib Deinen Geburtsort an.')
  .max(25, 'Der Nachname darf maximal 25 Zeichen haben.')
  .strict(true);
export const telephone = yup
  .string()
  .max(25, 'Die Telefonnummer darf maximal 25 Zeichen haben.')
  .nullable()
  .strict(true);
export const email = yup
  .string()
  .required('Gib Deine E-Mail an.')
  .email('Bitte gib eine gültige E-Mail an.')
  .max(255, 'Die E-Mail-Adresse darf maximal 255 Zeichen haben.')
  .strict(true);
export const postcode = yup
  .string()
  .required('Gib eine gültige Postleitzahl an.')
  .min(4, 'Die Postleitzahl sollte aus mindestens vier Zeichen bestehen.')
  .max(5, 'Die Postleitzahl darf maximal aus fünf Zeichen bestehen.')
  .strict(true);
export const city = yup
  .string()
  .required('Gib Deinen aktuellen Wohnort an.')
  .max(25, 'Der aktuelle Wohnort darf maximal 25 Zeichen haben.')
  .strict(true);
export const street = yup
  .string()
  .required('Gib den Namen Deiner Straße an.')
  .max(50, 'Der Straßenname darf maximal 50 Zeichen haben.')
  .strict(true);
export const streetNumber = yup
  .string()
  .required('Gib Deine Hausnummer an.')
  .max(10, 'Die Hausnummer darf maximal 10 Zeichen haben.')
  .strict(true);

export const personalDataSchema = (validationContext: ValidationContext) =>
  yup
    .object()
    .shape({
      firstName: firstName,
      lastName: lastName,
      birthday: birthday(validationContext),
      birthplace: birthplace,
      street: street,
      streetNumber: streetNumber,
      postcode: postcode,
      city: city,
      email: email,
      telephone: telephone,
    })
    .strict(true);

// Education
export const educationStart = (validationContext: ValidationContext) => {
  if (validationContext === 'serialize') {
    return serializedDateValidation('Gib ein Startdatum an.', false);
  }
  if (validationContext === 'entity') {
    return jsDateValidation('Gib ein Startdatum an.', false);
  }
  return yup
    .string()
    .required('Gib ein Startdatum an.')
    .matches(/^\d{2}\.\d{4}$/, 'Der Datumseintrag muss dem Muster MM.JJJJ entsprechen.')
    .test('date-check', 'Das angegebene Datum ist nicht gültig.', (dateString) => {
      if (typeof dateString !== 'string') {
        return false;
      }
      const [month, year] = dateString.split('.');
      const date = new Date(`${year}-${month}-01`);
      return !isNaN(date.getTime());
    })
    .strict(true);
};

export const educationEnd = (validationContext: ValidationContext) => {
  if (validationContext === 'serialize') {
    return serializedDateValidation('Gib ein Enddatum an.', true);
  }
  if (validationContext === 'entity') {
    return jsDateValidation('Gib ein Enddatum an.', true);
  }
  yup
    .string()
    .matches(/^\d{2}\.\d{4}$/, 'Der Datumseintrag muss dem Muster MM.JJJJ entsprechen.')
    .test('date-check', 'Das angegebene Datum ist nicht gültig.', (dateString) => {
      if (dateString === null || dateString === undefined) {
        return true;
      }
      if (typeof dateString !== 'string') {
        return false;
      }
      const [month, year] = dateString.split('.');
      const date = new Date(`${year}-${month}-01`);
      return !isNaN(date.getTime());
    })
    .nullable()
    .strict(true);
};
export const institution = yup
  .string()
  .required('Gib den Namen der Bildungseinrichtung an.')
  .max(50, 'Der Name der Bildungseinrichtung darf maximal 50 Zeichen haben.')
  .strict(true);
export const educationLocation = yup
  .string()
  .required('Gib den Ort der Bildungseinrichtung an.')
  .max(25, 'Der Ort der Bildungseinrichtung darf maximal 25 Zeichen haben.')
  .strict(true);
export const degree = yup.string().max(60, 'Der Abschluss darf maximal 60 Zeichen haben.').nullable().strict(true);
export const educationDescription = yup
  .string()
  .max(80, 'Die Beschreibung darf maximal 80 Zeichen haben.')
  .nullable()
  .strict(true);

export const educationEntrySchema = (validationContext: ValidationContext) =>
  yup
    .object()
    .shape({
      start: educationStart(validationContext),
      end: educationEnd(validationContext),
      institution: institution,
      degree: degree,
      location: educationLocation,
      description: educationDescription,
    })
    .strict(true);

export const educationList = (validationContext: ValidationContext) =>
  yup
    .array()
    .of(educationEntrySchema(validationContext))
    .max(15, 'Du kannst maximal 15 Bildungseinträge machen.')
    .test('education-is-array', 'Bildungsweg muss als Liste repräsentiert werden.', (education) =>
      Array.isArray(education)
    )
    .strict(true);

export const educationSchema = yup.object().shape({
  education: educationList('form'),
});

// Work experience
export const workExperienceStart = (validationContext: ValidationContext) => {
  if (validationContext === 'serialize') {
    return serializedDateValidation('Gib ein Startdatum an', false);
  }
  if (validationContext === 'entity') {
    return jsDateValidation('Gib ein Startdatum an.', false);
  }
  return yup
    .string()
    .required('Gib ein Startdatum an.')
    .matches(/^\d{2}\.\d{4}$/, 'Der Datumseintrag muss dem Muster MM.JJJJ entsprechen.')
    .test('date-check', 'Das angegebene Datum ist nicht gültig.', (dateString) => {
      if (typeof dateString !== 'string') {
        return false;
      }
      const [month, year] = dateString.split('.');
      const date = new Date(`${year}-${month}-01`);
      return !isNaN(date.getTime());
    })
    .strict(true);
};
export const workExperienceEnd = (validationContext: ValidationContext) => {
  if (validationContext === 'serialize') {
    return serializedDateValidation('Gib ein Enddatum an', true);
  }
  if (validationContext === 'entity') {
    return jsDateValidation('Gib ein Enddatum an.', true);
  }
  return yup
    .string()
    .matches(/^\d{2}\.\d{4}$/, 'Der Datumseintrag muss dem Muster MM.JJJJ entsprechen.')
    .test('date-check', 'Das angegebene Datum ist nicht gültig.', (dateString) => {
      if (dateString === null || dateString === undefined) {
        return true;
      }
      if (typeof dateString !== 'string') {
        return false;
      }
      const [month, year] = dateString.split('.');
      const date = new Date(`${year}-${month}-01`);
      return !isNaN(date.getTime());
    })
    .nullable()
    .strict(true);
};
export const type = yup
  .string()
  .required('Bitte gib deine Beschäftigungsart an.')
  .oneOf(
    ['employed', 'internship', 'voluntary', 'self_employed', 'working_student', 'apprenticeship'],
    'Bitte gib eine gültige Beschäftigungsart an.'
  )
  .strict(true);
export const workplace = yup
  .string()
  .required('Gib den Namen des Unternehmens an.')
  .max(75, 'Der Name des Unternehmens darf maximal 75 Zeichen haben.')
  .strict(true);
export const employment = yup
  .string()
  .required('Gib die Berufsbezeichnung an.')
  .max(60, 'Die Berufsbezeichnung darf maximal 60 Zeichen haben.')
  .strict(true);
export const workExperienceLocation = yup
  .string()
  .required('Gib den Standort des Unternehmens an.')
  .max(25, 'Der Standort des Unternehmens darf maximal 25 Zeichen haben.')
  .strict(true);
export const workExperienceDescription = yup.lazy((value) =>
  Array.isArray(value)
    ? yup.array().strict(true)
    : yup.string().max(500, 'Die Beschreibung darf maximal 500 Zeichen haben.').nullable().strict(true)
);

export const workExperienceEntrySchema = (validationContext: ValidationContext) =>
  yup.object().shape({
    start: workExperienceStart(validationContext),
    end: workExperienceEnd(validationContext),
    type: type,
    workplace: workplace,
    employment: employment,
    location: workExperienceLocation,
    description: workExperienceDescription,
  });

// yup's .required() does not allow empty arrays so we have to implement this with test
export const workExperienceList = (validationContext: ValidationContext) =>
  yup
    .array()
    .of(workExperienceEntrySchema(validationContext))
    .max(15, 'Du kannst maximal 15 Tätigkeitseinträge machen.')
    .test('work-experience-is-array', 'Berufserfahrung muss als Liste repräsentiert werden.', (workExperience) =>
      Array.isArray(workExperience)
    )
    .strict(true);
export const workExperienceSchema = yup.object().shape({
  workExperience: workExperienceList('form'),
});

// Skills
export const skillNameSchema = yup
  .string()
  .required('Bitte gib ein Bezeichnung der Fähigkeit an.')
  .max(60, 'Die Bezeichnung darf maximal 60 Zeichen haben.')
  .strict(true);

export const skillEntitySchema = yup.object().shape({
  name: skillNameSchema,
  sort: yup.number(),
  level: yup.string().nullable(),
});

export function skillSchema(x: 'form'): typeof skillEntitySchema;
export function skillSchema(x: 'entity'): typeof skillEntitySchema;
export function skillSchema(x: 'serialize'): typeof skillNameSchema;
export function skillSchema(validationContext: ValidationContext) {
  if (validationContext === 'serialize') {
    return skillNameSchema;
  }
  return skillEntitySchema;
}

export const skillEntityCategorySchema = yup.object().shape({
  name: yup.string().required('Bitte gib der Kategorie einen Namen.'),
  skills: yup.array().of(skillEntitySchema).strict(true),
});

export const skillCategorySchema = (validationContext) =>
  yup.object().shape({
    name: yup.string().required('Bitte gib der Kategorie einen Namen.'),
    skills: yup.array().of(skillSchema(validationContext)).strict(true),
  });

export const skillCategoryList = (validationContext: ValidationContext) =>
  yup
    .array()
    .of(skillCategorySchema(validationContext))
    .max(15, 'Du kannst maximal 15 Fähigkeitseinträge machen.')
    .test('skill-categories-is-array', 'Fähigkeiten müssen als Liste repräsentiert werden.', (skillCategories) =>
      Array.isArray(skillCategories)
    )
    .strict(true);

export const skillCategoriesSchema = (validationContext: ValidationContext) =>
  yup
    .object()
    .shape({
      skillCategories: skillCategoryList(validationContext),
    })
    .strict(true);

// Feedback
export const feedbackSchema = yup
  .object()
  .shape({
    companyFeedback: yup
      .string()
      .oneOf(['accepted', 'rejected'], 'Bitte gib einen gültigen Wert an.')
      .required('Bitte teile uns mit, ob Du eine Zusage oder Absage erhalten hast.')
      .strict(true),
    industry: yup
      .string()
      .required('Bitte gib die Branche an in der Du Dich beworben hast.')
      .min(2, 'Deine Branche sollte aus mindestens 2 Zeichen bestehen.')
      .strict(true),
    note: yup
      .string()
      .required('Bitte schreibe einen kurzen Erfahrungsbericht.')
      .min(20, 'Dein Feedback sollte aus mindestens 20 Zeichen bestehen.')
      .strict(true),
    acceptedUsageAsReviewOnWebsite: yup
      .boolean()
      .oneOf([true], 'Bitte akzeptiere die Nutzung Deines Feedbacks.')
      .strict(true),
  })
  .strict(true);
