import * as h from "vhtml";

import { PersonalDataContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import {
  DIN_A4_HEIGHT_IN_PX,
  AutoFittedText,
  Fragment,
  CroppedImage,
} from "../../../utility/index";
import { fontStyles } from "../font_styles";

type RenderPersonalDataParams = Omit<PersonalDataContent, "birthday"> & {
  birthday?: string;
};

const renderPersonalData = ({
  cvImage,
  firstName,
  lastName,
  street,
  streetNumber,
  postcode,
  city,
  telephone,
  email,
  birthplace,
  birthday,
}: RenderPersonalDataParams) => (
  <Fragment>
    <div data-section-name="personal-data" class="personal-data__picture">
      <CroppedImage portraitContent={cvImage} containerWidth={190} />
    </div>
    <div class="personal-data__name">
      <AutoFittedText
        text={firstName}
        widthInPx={190}
        maxHeightInPx={100}
        isUpperCase={true}
        fontFamily={fontStyles.autoFittedName.family}
        className="personal-data__first-name"
      />
      <AutoFittedText
        text={lastName}
        widthInPx={190}
        maxHeightInPx={120}
        fontFamily={fontStyles.autoFittedName.family}
        className="personal-data__last-name"
      />
    </div>
    <div class="personal-data__entry">
      <div class="personal-data__icon">
        <svg
          data-prefix="fal"
          data-icon="map-marker-alt"
          class="personal-data__icon--map svg-inline--fa fa-map-marker-alt fa-w-12"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewbox="0 0 384 512"
        >
          <path d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"></path>
        </svg>
      </div>
      <div class="personal-data__entry-text personal-data__entry-text--two-liner">
        {street} {streetNumber}
        <br />
        {postcode} {city}
      </div>
    </div>
    {telephone !== undefined ? (
      <div class="personal-data__entry">
        <div class="personal-data__icon">
          <svg
            class="personal-data__icon--phone svg-inline--fa fa-phone-alt fa-w-16"
            data-prefix="fal"
            data-icon="phone-alt"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewbox="0 0 512 512"
          >
            <path d="M493.09 351.3L384.7 304.8a31.36 31.36 0 0 0-36.5 8.9l-44.1 53.9A350 350 0 0 1 144.5 208l53.9-44.1a31.35 31.35 0 0 0 8.9-36.49l-46.5-108.5A31.33 31.33 0 0 0 125 .81L24.2 24.11A31.05 31.05 0 0 0 0 54.51C0 307.8 205.3 512 457.49 512A31.23 31.23 0 0 0 488 487.7L511.19 387a31.21 31.21 0 0 0-18.1-35.7zM456.89 480C222.4 479.7 32.3 289.7 32.1 55.21l99.6-23 46 107.39-72.8 59.5C153.3 302.3 209.4 358.6 313 407.2l59.5-72.8 107.39 46z"></path>
          </svg>
        </div>
        <div class="personal-data__entry-text">{telephone}</div>
      </div>
    ) : (
      ""
    )}
    <div class="personal-data__entry">
      <div class="personal-data__icon">
        <svg
          class="personal-data__icon--mail svg-inline--fa fa-envelope fa-w-16"
          data-prefix="fal"
          data-icon="envelope"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewbox="0 0 512 512"
        >
          <path d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"></path>
        </svg>
      </div>
      <div class="personal-data__entry-text">{email}</div>
    </div>
    <div class="personal-data__entry">
      <div class="personal-data__icon">
        <svg
          class="personal-data__icon--birthday svg-inline--fa fa-birthday-cake fa-w-14"
          data-prefix="fal"
          data-icon="birthday-cake"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewbox="0 0 448 512"
        >
          <path d="M96 96c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm48 160h-32V112h-32v144h-96V112h-32v144h-96V112H80v144H48c-26.5 0-48 21.5-48 48v208h448V304c0-26.5-21.5-48-48-48zm16 224H32v-72.043C48.222 398.478 55.928 384 74.75 384c27.951 0 31.253 32 74.75 32 42.843 0 47.217-32 74.5-32 28.148 0 31.201 32 74.75 32 43.357 0 46.767-32 74.75-32 18.488 0 26.245 14.475 42.5 23.955V480zm0-112.374C406.374 359.752 394.783 352 373.5 352c-43.43 0-46.825 32-74.75 32-27.695 0-31.454-32-74.75-32-42.842 0-47.218 32-74.5 32-28.148 0-31.202-32-74.75-32-21.463 0-33.101 7.774-42.75 15.658V304c0-8.822 7.178-16 16-16h352c8.822 0 16 7.178 16 16v63.626z"></path>
        </svg>
      </div>
      <div class="personal-data__entry-text personal-data__entry-text--two-liner">
        {birthday}
        <br />
        {birthplace}
      </div>
    </div>
  </Fragment>
);

export const constructPersonalDataSection = (
  personalData: PersonalDataContent
) => ({
  containerPreference: ["split__left"],
  height: DIN_A4_HEIGHT_IN_PX,
  tags: ["head", "cv"],
  render: () =>
    renderPersonalData({
      ...personalData,
      birthday:
        personalData.birthday === undefined
          ? undefined
          : format(personalData.birthday, "dd.MM.yyyy"),
    }),
});
