import {
  Section,
  ColorScheme,
  ThemeConfiguration,
} from "@apply-high/interfaces";
import { renderJobApplication } from "./job_application";
import * as h from "vhtml";

import { Layout, Page, ContainerSectionRelation } from "../interfaces";

class ThemeRenderer {
  private _theme: ThemeConfiguration;
  private _layout: Layout;
  private _colorScheme: ColorScheme;
  private _addWatermark: boolean;

  public constructor({
    theme,
    layout,
    colorScheme,
    addWatermark,
  }: RenderHTMLParams) {
    this._theme = theme;
    this._layout = layout;
    this._colorScheme = colorScheme;
    this._addWatermark = addWatermark;
  }

  public render() {
    let cssVariables = [
      {
        key: "primaryColor",
        value: this._colorScheme.primaryColor,
      },
      {
        key: "secondaryColor",
        value: this._colorScheme.secondaryColor,
      },
    ];
    if (this._theme.globalParameters !== undefined) {
      cssVariables = cssVariables.concat(
        Object.keys(this._theme.globalParameters).map((key) => ({
          key: key,
          value: this._theme.globalParameters![key],
        }))
      );
    }
    const pages = this._renderPages();

    const cssVariableStyleDeclaration = `
:root {
${cssVariables.map(({ key, value }) => `--${key}: ${value};\n`).join("")}
}
`;
    const fontDeclarations = this._theme.fontResources.reduce(
      (css, { fontFamily, resource }) =>
        `${css}
@font-face {
    font-family: '${fontFamily}';
    src: url(${resource.content}) format('${resource.type}');
    font-weight: normal;
    font-style: normal;
}

`,
      ""
    );
    const templateParams = {
      cssResources: this._theme.cssResources,
      pages: pages,
      addWatermark: this._addWatermark,
      cssVariables: cssVariableStyleDeclaration,
      fontDeclarations: fontDeclarations,
    };

    const html = renderJobApplication(templateParams);

    return html;
  }

  private _renderPages(): Array<string> {
    const pagesToRender = this._layout.pages;
    const pages = pagesToRender.map((page) => this._getPageHtml(page));

    return pages;
  }

  private _renderSection(sectionIndex: number) {
    const section: Section = this._theme.sections[sectionIndex];

    return section.render();
  }

  private _renderSections(containerSectionRelation: ContainerSectionRelation) {
    const containerHtml = containerSectionRelation.sectionIndices
      .map((index) => this._renderSection(index))
      .join("");

    return containerHtml;
  }

  private _getPageLayoutContainerParameters(page: Page) {
    const templatingParameters: {
      [containerId: string]: string;
    } = {};

    for (const relation of page.containerSectionRelations) {
      templatingParameters[relation.containerId] = (h as any)(null, {
        dangerouslySetInnerHTML: {
          __html: this._renderSections(relation),
        },
      });
    }

    return templatingParameters;
  }

  private _getPageHtml(page: Page): string {
    const layout = this._theme.pageLayouts[page.pageLayoutIndex];

    const pageLayoutTemplatingParameters =
      this._getPageLayoutContainerParameters(page);
    return layout.render(pageLayoutTemplatingParameters);
  }
}

type RenderHTMLParams = {
  theme: ThemeConfiguration;
  layout: Layout;
  colorScheme: ColorScheme;
  addWatermark: boolean;
};

export const renderHTML = (renderHTMLParams: RenderHTMLParams): string => {
  const themeRenderer = new ThemeRenderer(renderHTMLParams);

  return themeRenderer.render();
};
