import * as h from "vhtml";
import { PersonalDataContent, PortraitContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import { CroppedImage } from "../../../utility/index";

type RenderPersonalDataParams = {
  cvImage: PortraitContent;
  firstName?: string;
  lastName?: string;
  birthInfo: string;
  street?: string;
  streetNumber?: string;
  postcode?: string;
  city?: string;
  phone?: string;
  email?: string;
};

const renderPersonalData = ({
  cvImage,
  firstName,
  lastName,
  birthInfo,
  street,
  streetNumber,
  postcode,
  city,
  phone,
  email,
}: RenderPersonalDataParams) => (
  <div data-section-name="personal-data" class="personal-data">
    <div class="personal-data__left-balloon">Lebenslauf</div>
    <div class="personal-data__info">
      <div class="personal-data__name">
        <span class="personal-data__first-name">{firstName}</span>{" "}
        <span class="personal-data__last-name">{lastName}</span>
      </div>
      <div class="personal-data__birthday">{birthInfo}</div>
      <div class="personal-data__address">
        {street} {streetNumber}
        <br />
        {postcode} {city}
      </div>
      {phone !== undefined ? (
        <div class="personal-data__phone">{phone}</div>
      ) : (
        ""
      )}
      <div class="personal-data__email">{email}</div>
    </div>
    <div class="personal-data__portrait-container">
      <div class="personal-data__portrait">
        <CroppedImage containerWidth={298} portraitContent={cvImage} />
      </div>
    </div>
    <div class="personal-data__image-background"></div>
  </div>
);

export const constructPersonalDataSection = (
  personalData: PersonalDataContent
) => ({
  containerPreference: ["personal_data"],
  tags: ["head", "cv"],
  height: 400,
  render: () => {
    const birthday =
      personalData.birthday === undefined
        ? undefined
        : format(personalData.birthday, "dd.MM.yyyy");

    const birthInfo = [birthday, personalData.birthplace]
      .filter((x) => x !== undefined)
      .join(", ");

    return renderPersonalData({
      cvImage: personalData.cvImage!,
      firstName: personalData.firstName,
      lastName: personalData.lastName,
      birthInfo: birthInfo,
      street: personalData.street,
      streetNumber: personalData.streetNumber,
      postcode: personalData.postcode,
      city: personalData.city,
      phone: personalData.telephone,
      email: personalData.email,
    });
  },
});
