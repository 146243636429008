import * as h from "vhtml";
import { PortraitContent } from "@apply-high/interfaces";

export type CropImageParameters = {
  portraitContent?: PortraitContent;
  containerWidth: number;
};
export const CroppedImage = ({
  portraitContent,
  containerWidth,
}: CropImageParameters) => {
  if (portraitContent === undefined) {
    return (
      <div style="position: relative; height: 100%; width: 100%; overflow: hidden; background: lightgray;"></div>
    );
  }

  const {
    imagePath,
    imageWidth,
    imageHeight,
    cropX,
    cropY,
    cropWidth,
    rotation,
  } = portraitContent;

  // we don't need to look at containerHeight and cropHeight, because
  // that should give the same result as long as the container ratio
  // is the same as the image ratio: Cx/Cy = Ix/Iy => Cx/Ix = Cy/Iy
  const scaleFactor = containerWidth / cropWidth;

  // we rotate around point (0|0). that means the rotated image will
  // rotate out of the display area. Therefore we need to move it
  // back.
  let xOffsetDueToRotation = 0;
  let yOffsetDueToRotation = 0;
  if (rotation === 90) {
    xOffsetDueToRotation = imageHeight;
  } else if (rotation === 180) {
    xOffsetDueToRotation = imageWidth;
    yOffsetDueToRotation = imageHeight;
  } else if (rotation === 270) {
    yOffsetDueToRotation = imageWidth;
  }

  const xOffset = xOffsetDueToRotation - cropX;
  const yOffset = yOffsetDueToRotation - cropY;

  return (
    <div style="position: relative; height: 100%; width: 100%; overflow: hidden;">
      <img
        src={imagePath}
        style={`
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: 0 0;
      transform: scale(${scaleFactor}) translate(${xOffset}px, ${yOffset}px) rotate(${rotation}deg);
      `}
      />
    </div>
  );
};

// TODO: remove this
export const cropImage = (cropImageParameters: CropImageParameters) => (
  <CroppedImage {...cropImageParameters} />
);
