import * as h from "vhtml";
import { LanguageContent, SkillCategoryContent } from "@apply-high/interfaces";

import { fontStyles } from "../font_styles";
import { constructTextWithLineBreaks } from "../../../utility/index";

type Skill = {
  name: string;
  showDots: boolean;
  filledDots: Array<null>;
  unfilledDots: Array<null>;
};

type SkillCategory = {
  name: string;
  skills: Array<Skill>;
};

type SkillRow = {
  skillCategories: Array<SkillCategory>;
};

type RenderSkillsSectionParams = {
  height: number;
  skillRows: Array<SkillRow>;
};

const renderSkillsSection = ({
  height,
  skillRows,
}: RenderSkillsSectionParams) => (
  <div class="skills" data-section-name="skills" data-assumed-height={height}>
    <h1 class="skills__headline">
      <svg
        aria-hidden={true}
        focusable={false}
        data-prefix="fas"
        data-icon="brain"
        class="svg-inline--fa fa-brain fa-w-18"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewbox="0 0 576 512"
      >
        <path
          fill="currentColor"
          d="M208 0c-29.9 0-54.7 20.5-61.8 48.2-.8 0-1.4-.2-2.2-.2-35.3 0-64 28.7-64 64 0 4.8.6 9.5 1.7 14C52.5 138 32 166.6 32 200c0 12.6 3.2 24.3 8.3 34.9C16.3 248.7 0 274.3 0 304c0 33.3 20.4 61.9 49.4 73.9-.9 4.6-1.4 9.3-1.4 14.1 0 39.8 32.2 72 72 72 4.1 0 8.1-.5 12-1.2 9.6 28.5 36.2 49.2 68 49.2 39.8 0 72-32.2 72-72V64c0-35.3-28.7-64-64-64zm368 304c0-29.7-16.3-55.3-40.3-69.1 5.2-10.6 8.3-22.3 8.3-34.9 0-33.4-20.5-62-49.7-74 1-4.5 1.7-9.2 1.7-14 0-35.3-28.7-64-64-64-.8 0-1.5.2-2.2.2C422.7 20.5 397.9 0 368 0c-35.3 0-64 28.6-64 64v376c0 39.8 32.2 72 72 72 31.8 0 58.4-20.7 68-49.2 3.9.7 7.9 1.2 12 1.2 39.8 0 72-32.2 72-72 0-4.8-.5-9.5-1.4-14.1 29-12 49.4-40.6 49.4-73.9z"
        ></path>
      </svg>
      <span>Fähigkeiten</span>
    </h1>
    {skillRows.map(({ skillCategories }) => (
      <div class="skills__container">
        {skillCategories.map(({ name, skills }) => (
          <div class="skills__block">
            <div class="skills__sub-headline">{name}</div>
            <table>
              {skills.map(({ name, showDots, filledDots, unfilledDots }) => (
                <tr>
                  <td>• {name}</td>
                  {showDots === true ? (
                    <td class="skills__name-level">
                      {filledDots.map(() => (
                        <div class="skills__dot skills__dot--filled"></div>
                      ))}
                      {unfilledDots.map(() => (
                        <div class="skills__dot skills__dot--unfilled"></div>
                      ))}
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
            </table>
          </div>
        ))}
      </div>
    ))}
  </div>
);

export const constructSkillsSection = (
  languages: Array<LanguageContent>,
  skillCategories: Array<SkillCategoryContent>
) => {
  const headlineHeightInPx = 68;
  const subHeadlineHeightInPx = 27;
  const rowMarginBottomInPx = 25;

  const allSkillCategories: Array<SkillCategory> = skillCategories.map(
    (category) => ({
      name: category.name,
      skills: category.skills.map((skill) => ({
        name: skill,
        showDots: false,
        filledDots: [],
        unfilledDots: [],
      })),
    })
  );

  if (languages.length !== 0) {
    allSkillCategories.unshift({
      name: "Sprachen",
      skills: languages.map((language) => ({
        name:
          language.isOfficialLanguageLevel === true
            ? `${language.name} (${language.level})`
            : language.name,
        showDots: language.isOfficialLanguageLevel === false,
        filledDots:
          language.isOfficialLanguageLevel === false
            ? Array(Math.ceil((language.level as unknown as number) / 25)).fill(
                null
              )
            : [],
        unfilledDots:
          language.isOfficialLanguageLevel === false
            ? Array(
                4 - Math.ceil((language.level as unknown as number) / 25)
              ).fill(null)
            : [],
      })),
    });
  }

  const sortedSkillCategories = allSkillCategories.sort((a, b) =>
    a.skills.length <= b.skills.length ? 1 : -1
  );

  const skillRows: Array<SkillRow> = [];
  let sectionHeight = headlineHeightInPx;

  while (sortedSkillCategories.length !== 0) {
    const skillTriple: Array<SkillCategory> = sortedSkillCategories.splice(
      0,
      3
    );
    const listOfCategoryHeights = skillTriple.map((category) => {
      return category.skills.reduce((totalHeight, skill) => {
        const { height } = constructTextWithLineBreaks({
          text: skill.name,
          font: fontStyles.baseLight,
          lineHeight: 21,
          containerWidth: 166,
        });
        return totalHeight + height;
      }, 0);
    });

    const contentHeightInPx = Math.max(...listOfCategoryHeights);

    const rowHeight =
      subHeadlineHeightInPx + contentHeightInPx + rowMarginBottomInPx;
    sectionHeight += rowHeight;
    skillRows.push({
      skillCategories: skillTriple,
    });
  }

  return {
    isVisible: skillRows.length !== 0,
    containerPreference: ["default"],
    tags: ["cv"],
    height: sectionHeight,
    render: () =>
      renderSkillsSection({
        height: sectionHeight,
        skillRows: skillRows,
      }),
  };
};
