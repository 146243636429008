import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";

import {
  Fragment,
  DIN_A4_HEIGHT_IN_PX,
  DIN_A4_WIDTH_IN_PX,
} from "../../../utility/index";

type LayoutParams = {
  letter: string;
};

const Layout = ({ letter }: LayoutParams) => (
  <Fragment>
    <svg
      class="abstract-background"
      id="anschreiben"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewbox="0 0 595.28 841.89"
    >
      <path
        class="primary-color--very-dark"
        xmlns="http://www.w3.org/2000/svg"
        d="M359.3,0c-0.4,1.2-0.8,2.5-1.2,3.8c-2.9,8.9-5.5,17.8-8.4,26.7c-3.7,11.6-8.9,22.4-14.3,33 c-6.2,12.2-13.2,23.9-19.2,36.2c-1.7,3.5-3.4,7.1-5,10.7c-3.6,7.8-7.7,15.3-12.3,22.4c-2.6,4-5.2,8.2-7.8,12.3 c-5,7.7-11.2,14.5-18.1,19.9c-11,8.9-22.9,16.2-35.5,21.6c-3.1,1.3-6.2,2.8-9.3,4.3c-8.4,3.9-17.1,6.6-26,8.2 c-8.4,1.6-17,2.1-25.5,3.2c-5.3,0.7-10.5,2.2-15.7,3.5c-12.3,3.1-24.4,7.1-36.7,10.5c-8.1,2.2-16.2,4.1-24.1,6.9 c-8.5,3-17,5.6-25.5,8.3C66,234.2,57.5,238,49,241.8c-10.1,4.4-20.1,8.8-30.5,12.1c-6.1,1.9-12.3,3.3-18.5,4.2V62.4 c2.3-1,4.8-0.7,6.9,0.7c9.2,5.3,19.2,8,29.2,10.4c6.8,1.6,13.8,1.8,20.7,1.9c4.4,0.1,8.8,0.9,13.2,0.7c1.8,0,3.7-0.2,5.5-0.4 c3.3-0.5,6.6,0.1,9.6,1.7c9.2,4.4,18.8,5.6,28.6,6c4.9,0.2,9.7,0.5,14.6,0.5c7.5,0.1,13.3-3.4,17.8-10c2.9-4.3,5.4-8.9,7.3-13.9 c1-2.6,1.5-5.5,1.4-8.3c-0.1-7.4,2.6-13.1,7.7-17.5c1.3-1.2,2.7-2.3,4.1-3.3c9.6-6.3,17.2-15.2,24.1-24.9c1.4-2.1,3-4.1,4.6-5.9 H359.3z"
      />

      <path
        class="primary-color--darker"
        xmlns="http://www.w3.org/2000/svg"
        d="M.2,799.19a48.88,48.88,0,0,0,13.36-5.05c7.35-4.08,13.79-9.44,20.42-14.53s12.53-10.84,19.15-15.83c1.84-1.39,3.65-2.84,5.59-4.08,3.32-2.11,6.07-4.9,9.1-7.35,4.89-4,9.73-8,14.8-11.7a125.15,125.15,0,0,0,10.44-8.35,50.4,50.4,0,0,0,7.06-8.7c3.42-4.9,6.71-9.92,11.08-14.06a53.67,53.67,0,0,1,12.24-9.1,87.79,87.79,0,0,1,9.49-4c7.39-2.75,14.77-5.55,22.15-8.33a17.71,17.71,0,0,1,3.47-.82c2.91-.47,5.84-.66,8.77-1,6.54-.68,13.08-.28,19.61-.38a112.14,112.14,0,0,1,15.2,1.3c3.79.46,7.57.87,11.34,1.5,5.26.88,10.45,2.06,15.68,3.07,6.47,1.26,12.93,2.7,19.45,3.61a130,130,0,0,0,23.33,1.59c1.83-.08,3.61.5,5.47.43a119.08,119.08,0,0,0,15.65-1.6c3.83-.65,7.68-1.42,11.6-1.25,3.76.16,7.47-.52,11.22-.51,4.95,0,9.89.35,14.81.61,7.32.38,14.65.56,22,1,8.39.47,16.77,0,25.13.42a116.69,116.69,0,0,1,11.62,1.19c3.69.56,7.4,1.05,11.05,1.83a75.44,75.44,0,0,1,14.64,5.12c7.45,3.27,14.92,6.48,22.35,9.78,4.38,1.94,8.68,4.05,13.05,6q9.61,4.29,19.3,8.42c5.64,2.42,11.28,4.82,17,7.1q9.11,3.65,18.54,6.37c6.5,1.91,13,3.85,19.38,6.09,6.69,2.34,13.44,4.54,20.18,6.76,3.85,1.28,7.79,2.3,11.65,3.57,5.38,1.78,10.54,4.13,15.82,6.15,7.08,2.7,14.08,5.59,21.11,8.4a2.76,2.76,0,0,1,1.4.87,4.21,4.21,0,0,1,.2,2.11q0,32,0,63.91a3.81,3.81,0,0,1-.22,2.11H.2Z"
      />
    </svg>
    {letter}
  </Fragment>
);

export const letterLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "letter",
      height: DIN_A4_HEIGHT_IN_PX,
      width: DIN_A4_WIDTH_IN_PX,
    },
  ],
};
