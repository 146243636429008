import parse from "date-fns/parse";
import { JobApplication } from "@apply-high/interfaces";
import {
  getCoreCompetencies,
  isLanguageCategory,
  isOfficialLanguageLevel,
} from "./skills";

export const createRenderContent = (
  jobApplication: JobApplication,
  isSerialized: boolean
) => {
  const skillsWithoutLanguages = jobApplication.skillCategories.filter(
    (skillCategory) => isLanguageCategory(skillCategory.name) === false
  );

  return {
    // Required by validation but actually don't matter >
    subject: "...",
    letter: "...",
    companyData: {},
    // <
    personalData: {
      cvImage: jobApplication.image !== null ? jobApplication.image : undefined,
      firstName: jobApplication.firstName,
      lastName: jobApplication.lastName,
      city: jobApplication.city,
      postcode: jobApplication.postcode,
      street: jobApplication.street,
      streetNumber: jobApplication.streetNumber,
      telephone:
        jobApplication.telephone.trim() === ""
          ? undefined
          : jobApplication.telephone,
      email: jobApplication.email,
      birthday:
        jobApplication.birthday.trim() === ""
          ? undefined
          : isSerialized === true
          ? formatConsistentDate(
              jobApplication.birthday,
              "dd.MM.yyyy"
            ).toISOString()
          : formatConsistentDate(jobApplication.birthday, "dd.MM.yyyy"),
      birthplace: jobApplication.birthplace,
    },
    workExperiences: jobApplication.workExperience.map((entry) => ({
      ...entry,
      start:
        isSerialized === true
          ? formatConsistentDate(entry.start, "MM.yyyy").toISOString()
          : formatConsistentDate(entry.start, "MM.yyyy"),
      end:
        entry.end.trim() === ""
          ? undefined
          : isSerialized === true
          ? formatConsistentDate(entry.end, "MM.yyyy").toISOString()
          : formatConsistentDate(entry.end, "MM.yyyy"),
      description: Array.isArray(entry.description)
        ? entry.description.filter(
            (descriptionEntry) => descriptionEntry.trim() !== ""
          )
        : entry.description.trim() === ""
        ? undefined
        : entry.description,
    })),
    education: jobApplication.education.map((entry) => ({
      ...entry,
      start:
        isSerialized === true
          ? formatConsistentDate(entry.start, "MM.yyyy").toISOString()
          : formatConsistentDate(entry.start, "MM.yyyy"),
      end:
        entry.end.trim() === ""
          ? undefined
          : isSerialized === true
          ? formatConsistentDate(entry.end, "MM.yyyy").toISOString()
          : formatConsistentDate(entry.end, "MM.yyyy"),
    })),
    skills: skillsWithoutLanguages.map((skillCategory) => ({
      name: skillCategory.name,
      skills: skillCategory.skills.map((skill) => skill.name),
    })),
    languages:
      jobApplication.skillCategories
        .find(
          (skillCategory) => isLanguageCategory(skillCategory.name) === true
        )
        ?.skills.map((skill) => ({
          name: skill.name,
          level: skill.level!,
          isOfficialLanguageLevel: isOfficialLanguageLevel(skill.level),
        })) ?? [],
    coreCompetencies: getCoreCompetencies(skillsWithoutLanguages).map(
      (coreCompetency) => coreCompetency.name
    ),
  };
};

export const formatConsistentDate = (
  dateString: string,
  formatString: string
) => {
  const parsedDate = parse(dateString, formatString, new Date());
  const offsetInMilliseconds = parsedDate.getTimezoneOffset() * 60 * 1000;

  return new Date(parsedDate.getTime() - offsetInMilliseconds);
};
