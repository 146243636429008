import { CssFontStyles, convertFontStylesToCss } from "../../utility/index";

export const fontStyles = {
  coverHeadline: {
    sizeInPx: 70,
    family: "DancingScript-Regular",
  },
  coverName: {
    sizeInPx: 48,
    family: "Lato-Regular",
  },
  headline: {
    sizeInPx: 40,
    family: "DancingScript-Regular",
  },
  subHeadline: {
    sizeInPx: 16,
    family: "Lato-Bold",
  },
  yearHeadlineFont: {
    sizeInPx: 30,
    family: "Lato-Regular",
  },
  timeSpanFont: {
    sizeInPx: 22,
    family: "Lato-Regular",
  },
  bigRegular: {
    sizeInPx: 16,
    family: "Lato-Regular",
  },
  baseBold: {
    sizeInPx: 14,
    family: "Lato-Bold",
  },
  baseLight: {
    sizeInPx: 14,
    family: "Lato-Light",
  },
  baseRegular: {
    sizeInPx: 14,
    family: "Lato-Regular",
  },
};

export const cssFontStyles: CssFontStyles = convertFontStylesToCss(fontStyles);
