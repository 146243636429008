import { RenderContent } from "@apply-high/interfaces";
import { getThemeConstructor, getThemeSettings } from "./themes/index";
import { generateLayout } from "./generate_layout";
import { renderHTML } from "./theme_renderer/theme_renderer";

type RenderJobApplicationParams = {
  themeSlug: string;
  renderContent: RenderContent;
  colorId: string;
  tag?: string;
  addWatermark: boolean;
};

export const renderJobApplication = ({
  themeSlug,
  renderContent,
  colorId,
  tag,
  addWatermark,
}: RenderJobApplicationParams) => {
  const themeConfigurationConstructor = getThemeConstructor(themeSlug);
  const colorScheme = getThemeSettings(themeSlug).colors.find(
    ({ id }) => id === colorId
  );
  if (colorScheme === undefined) {
    throw new Error(
      `Theme "${themeSlug}" has no colorScheme defined for id "${colorId}"`
    );
  }
  const themeConfiguration = themeConfigurationConstructor(
    renderContent,
    colorScheme
  );
  const layout = generateLayout(themeConfiguration, tag);
  const HTMLCode = renderHTML({
    theme: themeConfiguration,
    layout,
    addWatermark,
    colorScheme,
  });

  return HTMLCode;
};
