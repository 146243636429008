import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";
import { DIN_A4_HEIGHT_IN_PX, Fragment } from "../../../utility";

const DIN_A4_INNER_HEIGHT_IN_PX = DIN_A4_HEIGHT_IN_PX - 100;

type LayoutParams = {
  standard: string;
};

const Layout = ({ standard }: LayoutParams) => (
  <Fragment>
    <div
      class="standard"
      data-container-name="standard"
      data-assumed-height={DIN_A4_INNER_HEIGHT_IN_PX}
    >
      {standard}
    </div>
    <div class="standard__sidebar"></div>
  </Fragment>
);

export const standardLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "standard",
      height: DIN_A4_INNER_HEIGHT_IN_PX,
      width: 285,
    },
  ],
};
