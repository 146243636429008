import React, { Fragment } from "react";
import { NormalizedPortrait, PortraitRotation } from "@apply-high/interfaces";
import { Area, Point } from "react-easy-crop/types";
import Cropper from "react-easy-crop";

import { ReactComponent as Plus } from "../assets/svgs/plus-light.svg";
import { ReactComponent as Minus } from "../assets/svgs/minus-light.svg";
import { Button } from "@apply-high/components";

export type ImageCropData = {
  data: Blob;
  imageWidth: number;
  imageHeight: number;
  cropX: number;
  cropY: number;
  cropWidth: number;
  cropHeight: number;
  rotation: PortraitRotation;
};

type ImageEditorProps = {
  file: File;
  widthToLengthRatio: number;
  onChange: (imageCropData: ImageCropData) => void;
  onAbort: () => void;
  cropData?: NormalizedPortrait;
};

type ImageEditorState = {
  crop: Point;
  zoom: number;
  croppedAreaPixels: Area;
  sourceImageObjectURL: string | null;
};

const createImage = (url): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });
};

const getCroppedImage = async (
  imageSrc: string,
  pixelCrop: Area,
  rotation: PortraitRotation = 0
): Promise<ImageCropData> => {
  const image = await createImage(imageSrc);
  let cropX = pixelCrop.x;
  let cropY = pixelCrop.y;

  const DIN_A4_HEIGHT_IN_PX = 1128;
  const DIN_A4_WIDTH_IN_PX = 798;
  const scaleFactor = Math.min(
    1,
    Math.max(
      DIN_A4_WIDTH_IN_PX / image.width,
      DIN_A4_HEIGHT_IN_PX / image.height
    )
  );
  const scaledWidth = image.width * scaleFactor;
  const scaledHeight = image.height * scaleFactor;
  cropX = cropX * scaleFactor;
  cropY = cropY * scaleFactor;

  const canvas = document.createElement("canvas");
  canvas.width = scaledWidth;
  canvas.height = scaledHeight;

  const context = canvas.getContext("2d");
  context.drawImage(
    image,
    0,
    0,
    image.width,
    image.height,
    0,
    0,
    scaledWidth,
    scaledHeight
  );

  const blob = await new Promise<Blob>((resolve) => {
    canvas.toBlob((file) => resolve(file), "image/jpeg", 0.85);
  });

  return {
    data: blob,
    imageWidth: Math.round(scaledWidth),
    imageHeight: Math.round(scaledHeight),
    cropX: Math.round(cropX),
    cropY: Math.round(cropY),
    cropWidth: Math.round(pixelCrop.width * scaleFactor),
    cropHeight: Math.round(pixelCrop.height * scaleFactor),
    rotation: rotation,
  };
};

const INITIAL_CROP: Point = { x: 0, y: 0 };
const INITIAL_ZOOM = 1;
const MIN_ZOOM = 1;
const MAX_ZOOM = 3;

class ImageCropper extends React.PureComponent<
  ImageEditorProps,
  ImageEditorState
> {
  constructor(props) {
    super(props);

    this.state = {
      zoom: INITIAL_ZOOM,
      crop: INITIAL_CROP,
      croppedAreaPixels: this.getInitialCroppedAreaPixels(),
      sourceImageObjectURL: URL.createObjectURL(props.file),
    };
  }

  componentWillUnmount() {
    const { sourceImageObjectURL } = this.state;
    URL.revokeObjectURL(sourceImageObjectURL);
  }

  onCutSelection = async (croppedAreaPixels: Area) => {
    const { sourceImageObjectURL } = this.state;
    const { onChange, cropData } = this.props;

    const updatedCropData = await getCroppedImage(
      sourceImageObjectURL,
      croppedAreaPixels
    );

    // Deep equality check to avoid unnecessary callback invocations.
    if (
      cropData?.imageWidth === updatedCropData.imageWidth &&
      cropData?.imageHeight === updatedCropData.imageHeight &&
      cropData?.cropX === updatedCropData.cropX &&
      cropData?.cropY === updatedCropData.cropY &&
      cropData?.cropWidth === updatedCropData.cropWidth &&
      cropData?.cropHeight === updatedCropData.cropHeight &&
      cropData?.rotation === updatedCropData.rotation
    ) {
      return;
    }

    onChange(updatedCropData);
  };

  getInitialCroppedAreaPixels = (): Area => {
    const { cropData } = this.props;

    if (cropData === undefined) {
      return null;
    }

    return {
      width: cropData.cropWidth,
      height: cropData.cropHeight,
      x: cropData.cropX,
      y: cropData.cropY,
    };
  };

  render() {
    const { crop, zoom, sourceImageObjectURL } = this.state;
    const { widthToLengthRatio, onAbort } = this.props;

    return (
      <Fragment>
        <div className="position-relative" style={{ height: 300 }}>
          <Cropper
            crop={crop}
            image={sourceImageObjectURL}
            aspect={widthToLengthRatio}
            initialCroppedAreaPixels={this.getInitialCroppedAreaPixels()}
            onCropChange={(crop) => this.setState({ crop: crop })}
            onCropComplete={async (croppedArea, croppedAreaPixels) => {
              this.setState({ croppedAreaPixels: croppedAreaPixels });
              await this.onCutSelection(croppedAreaPixels);
            }}
            // Zoom
            zoom={zoom}
            minZoom={MIN_ZOOM}
            maxZoom={MAX_ZOOM}
            onZoomChange={(zoom) => this.setState({ zoom: zoom })}
          />
        </div>
        <div className="mt-2 d-flex justify-content-between">
          <div>
            <Button
              circled
              disabled={zoom === MIN_ZOOM}
              variant="easy"
              title="Verkleinern"
              onClick={() =>
                this.setState((prevState) => {
                  const updatedZoom = prevState.zoom - 0.1;

                  return { zoom: Math.max(updatedZoom, MIN_ZOOM) };
                })
              }
            >
              <Minus height={20} />
            </Button>
            <Button
              circled
              disabled={zoom === MAX_ZOOM}
              variant="easy"
              title="Vergrößern"
              onClick={() =>
                this.setState((prevState) => {
                  const updatedZoom = prevState.zoom + 0.1;

                  return { zoom: Math.min(updatedZoom, MAX_ZOOM) };
                })
              }
            >
              <Plus height={20} />
            </Button>
          </div>
          <div>
            <Button variant="easy" size="small" onClick={onAbort}>
              Bild ändern
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ImageCropper;
