import * as h from "vhtml";

import { jobApplicationCSS } from "./job_application_css";
import { normalizeCSS } from "./normalize_css";
import { previewJS } from "./preview_js";
import { watermark } from "./watermark_svg";

type RenderJobApplicationParams = {
  pages: Array<string>;
  /* Expects:
   * :root {
   *   --key: value,
   *   --key2: value2,
   * } */
  cssVariables: string;
  cssResources: Array<string>;
  fontDeclarations: string;
  addWatermark: boolean;
};

export const renderJobApplication = ({
  pages,
  cssVariables,
  cssResources,
  fontDeclarations,
  addWatermark,
}: RenderJobApplicationParams) => (
  <html lang="de">
    <head>
      <title>Bewerbung</title>
      <meta charset="utf-8" />
      {[
        ...cssResources,
        cssVariables,
        fontDeclarations,
        jobApplicationCSS,
        normalizeCSS,
      ].map((cssResource) => (
        <style>
          {(h as any)(null, {
            dangerouslySetInnerHTML: {
              __html: cssResource,
            },
          })}
        </style>
      ))}
    </head>
    <body>
      {pages.map((page, index) => (
        <div class="din-a4-portrait">
          {addWatermark === true ? (
            <div class="watermark">
              {(h as any)(null, {
                dangerouslySetInnerHTML: {
                  __html: watermark(index),
                },
              })}
            </div>
          ) : (
            ""
          )}
          {page}
        </div>
      ))}
      <script>
        {(h as any)(null, {
          dangerouslySetInnerHTML: {
            __html: previewJS,
          },
        })}
      </script>
    </body>
  </html>
);
