import {
  EducationContent,
  Section,
  WorkExperienceContent,
} from "@apply-high/interfaces";

import {
  constructWorkExperienceSection,
  PreformattedWorkExperienceContent,
  preformatWorkExperienceEntries,
} from "./work_experience.section";
import {
  preformatEducationEntries,
  constructEducationSection,
  PreformattedEducationContent,
} from "./education.section";
import { DIN_A4_HEIGHT_IN_PX } from "../../../utility";

const sectionMarginBottom = 50;

const PAGE_HEIGHT =
  DIN_A4_HEIGHT_IN_PX -
  100 - // minus padding for the page
  20 - // a little buffer. trouble if predefined entrygroup is bigger than containerHeight
  sectionMarginBottom; // also need to consider margin bottom of section

type PreformattedEntry =
  | PreformattedEducationContent
  | PreformattedWorkExperienceContent;

const createSectionGroups = <T extends PreformattedEntry>(
  entries: Array<T>,
  currentPageHeight = 0
) => {
  /*
    first section also has a headline. so we start by adding headline height
   */
  const headlineHeight = 61;
  currentPageHeight += headlineHeight;
  /*
    An EntryGroup collects entries which are put into one section
    later on.
   */
  type EntryGroup = Array<T>;
  const entryGroupList: Array<EntryGroup> = [[]];

  const fitsOnCurrentPage = (entry: T): boolean =>
    entry.entryHeight + currentPageHeight < PAGE_HEIGHT;

  const addToCurrentPage = (entry: T) => {
    const currentPage = entryGroupList[entryGroupList.length - 1];
    currentPage.push(entry);
    currentPageHeight += entry.entryHeight;
  };

  const addToNewPage = (entry: T) => {
    entryGroupList.push([]);
    currentPageHeight = 0;
    addToCurrentPage(entry);
  };

  for (const entry of entries) {
    if (fitsOnCurrentPage(entry) === true) {
      addToCurrentPage(entry);
    } else {
      addToNewPage(entry);
    }
  }

  return {
    entryGroups: entryGroupList,
    spaceTakenOnLastPage: currentPageHeight + sectionMarginBottom,
  };
};

export const constructTimelines = (
  education: Array<EducationContent>,
  workExperiences: Array<WorkExperienceContent>
): Array<Section> => {
  const preformattedWorkExperienceEntries: Array<PreformattedWorkExperienceContent> =
    preformatWorkExperienceEntries(workExperiences);

  const preformattedEducationEntries: Array<PreformattedEducationContent> =
    preformatEducationEntries(education);

  const { entryGroups: workExperienceEntryGroups, spaceTakenOnLastPage } =
    createSectionGroups(preformattedWorkExperienceEntries);
  /*
    if education timeline fills quite a bit of last page, then don't
    bother seperating workexperience timeline to fit rest of that page.
    In that case workep either fits in whole or goes to next page
  */
  const useLastPage = spaceTakenOnLastPage < PAGE_HEIGHT / 2;
  const educationEntryGroups = createSectionGroups(
    preformattedEducationEntries,
    useLastPage === true ? spaceTakenOnLastPage : 0
  ).entryGroups;

  const workExperienceSections: Array<Section> = workExperienceEntryGroups.map(
    constructWorkExperienceSection
  );
  const educationSections: Array<Section> = educationEntryGroups.map(
    constructEducationSection
  );

  return workExperienceSections.concat(educationSections);
};
