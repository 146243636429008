import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";

import { DIN_A4_HEIGHT_IN_PX } from "../../../utility/index";

const DIN_A4_INNER_HEIGHT_IN_PX = DIN_A4_HEIGHT_IN_PX - 100;

type LayoutParams = {
  split__left: string;
  split__right: string;
};

const Layout = ({ split__left, split__right }: LayoutParams) => (
  <div class="split">
    <div class="split__left">{split__left}</div>
    <div
      class="split__right"
      data-container-name="split right"
      data-assumed-height={DIN_A4_INNER_HEIGHT_IN_PX}
    >
      {split__right}
    </div>
  </div>
);

export const splitLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "split__left",
      height: DIN_A4_HEIGHT_IN_PX,
      width: 285,
      minSectionsHeight: DIN_A4_HEIGHT_IN_PX,
    },
    {
      id: "split__right",
      height: DIN_A4_INNER_HEIGHT_IN_PX,
      width: 722,
    },
  ],
};
