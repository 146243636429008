import * as h from "vhtml";
import { RenderContent } from "@apply-high/interfaces";
import { format } from "date-fns";
import { de } from "date-fns/locale";

import {
  formatContactPerson,
  DIN_A4_HEIGHT_IN_PX,
  DIN_A4_WIDTH_IN_PX,
  separateLetterIntoParts,
  Fragment,
} from "../../../utility/index";
import { fontStyles } from "../font_styles";

type FirstLetterSectionParameters = {
  subject?: string;
  text?: string;
  corporateName?: string;
  formattedContactPerson?: string;
  companyStreet?: string;
  companyStreetNumber?: string;
  companyPostcode?: string;
  companyCity?: string;
  currentDate: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  streetNumber?: string;
  postcode?: string;
  city?: string;
  phone?: string;
  email?: string;
};

const renderFirstLetterSection = ({
  corporateName,
  formattedContactPerson,
  companyStreet,
  companyStreetNumber,
  companyPostcode,
  companyCity,
  currentDate,
  firstName,
  lastName,
  street,
  streetNumber,
  postcode,
  city,
  phone,
  email,
  text,
  subject,
}: FirstLetterSectionParameters) => (
  <div data-section-name="letter" class="letter">
    <div class="letter__header">
      <div>
        <div class="letter__company-name">{corporateName}</div>
        {formattedContactPerson === undefined ? null : (
          <Fragment>
            {" "}
            {formattedContactPerson} <br />{" "}
          </Fragment>
        )}
        <div class="letter__company-street">
          {companyStreet} {companyStreetNumber}
        </div>
        <div class="letter__company-city">
          {companyPostcode} {companyCity}
        </div>
      </div>
      <div class="letter__personal-data">
        <div class="letter__name">
          {firstName} {lastName}
        </div>
        <div class="letter__street">
          {street} {streetNumber}
        </div>
        <div class="letter__city">
          {postcode} {city}
        </div>
        {phone === undefined ? undefined : (
          <Fragment>
            {" "}
            {phone} <br />{" "}
          </Fragment>
        )}
        <div class="letter__email">{email}</div>
        <div class="letter__date">{currentDate}</div>
      </div>
    </div>
    <div class="letter__content">
      <div class="letter__subject">{subject}</div>
      {text}
    </div>
  </div>
);

const renderLetterFollowUp = (text: string) => (
  <div class="letter__follow-up">{text}</div>
);

export const constructLetterSections = (content: RenderContent) => {
  const sections = separateLetterIntoParts({
    letter: content.letter,
    lineHeight: 21,
    font: fontStyles.letterFont,
    containerWidth: DIN_A4_WIDTH_IN_PX - 95 - 75,
    firstPageHeight: 635,
    laterPagesHeight: DIN_A4_HEIGHT_IN_PX - 95 - 95,
    constructFirstSection: (text) => ({
      containerPreference: ["letter"],
      height: DIN_A4_HEIGHT_IN_PX,
      tags: ["letter"],
      render: () =>
        renderFirstLetterSection({
          subject: content.subject,
          text: text,
          currentDate: format(Date.now(), " dd. MMMM yyyy", {
            locale: de,
          }),
          firstName: content.personalData.firstName,
          lastName: content.personalData.lastName,
          street: content.personalData.street,
          streetNumber: content.personalData.streetNumber,
          postcode: content.personalData.postcode,
          city: content.personalData.city,
          phone: content.personalData.telephone,
          email: content.personalData.email,
          corporateName: content.companyData.corporateName,
          formattedContactPerson: formatContactPerson(
            content.companyData.contactPerson
          ),
          companyStreet: content.companyData.street,
          companyStreetNumber: content.companyData.streetNumber,
          companyPostcode: content.companyData.postcode,
          companyCity: content.companyData.city,
        }),
    }),
    constructLaterSection: (text) => ({
      containerPreference: ["letter_follow_up"],
      height: DIN_A4_HEIGHT_IN_PX,
      tags: ["letter"],
      render: () => renderLetterFollowUp(text),
    }),
  });

  return sections;
};
