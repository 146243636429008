import { Schema, ValidateOptions } from 'yup';

export type RequestSchema<BodyType, QueryType> = Schema<{ body?: BodyType; query?: QueryType }>;

type ConstructShapeDataType<S> = S extends Schema<infer ShapeType> ? ShapeType : never;

export async function validate<SchemaType extends Schema<any>>(
  schema: SchemaType,
  data: ConstructShapeDataType<SchemaType>,
  options?: ValidateOptions
): Promise<typeof data> {
  return await schema.validate(data, options);
}

export function isValidSync<SchemaType extends Schema<any>>(
  schema: SchemaType,
  data: ConstructShapeDataType<SchemaType>,
  options?: ValidateOptions
): boolean {
  return schema.isValidSync(data, options);
}

export function isValid<SchemaType extends Schema<any>>(
  schema: SchemaType,
  data: ConstructShapeDataType<SchemaType>,
  options?: ValidateOptions
): Promise<boolean> {
  return schema.isValid(data, options);
}

// Sometimes we don't know whether an objects types are correct during compile time.
// Database entries e.g. may contain null.
// This function allows schema checks on types which look like the schema, but may contain null instead of properties.
type AllowNullForProperties<Obj> = {
  [property in keyof Obj]: Obj[property] | null;
};

type AllowNullForObjectOrArrayProperties<Obj> = Obj extends Array<infer A>
  ? Array<AllowNullForProperties<A>>
  : AllowNullForProperties<Obj>;

export function isValidWithNullCheck<SchemaType extends Schema<any>>(
  schema: SchemaType,
  data: AllowNullForObjectOrArrayProperties<ConstructShapeDataType<SchemaType>>,
  options?: ValidateOptions
): Promise<boolean> {
  return schema.isValid(data, options);
}

export async function validateWithNullCheck<SchemaType extends Schema<any>>(
  schema: SchemaType,
  data: AllowNullForObjectOrArrayProperties<ConstructShapeDataType<SchemaType>>,
  options?: ValidateOptions
): Promise<typeof data> {
  return await schema.validate(data, options);
}
