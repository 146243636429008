import * as yup from 'yup';

import { renderContentSchema } from '../index';

export const partner = yup.string().oneOf(['apply-high', 'jp-associates']).required();

export const postDownloadPdfSchema = yup
  .object()
  .shape({
    body: yup
      .object()
      .shape({
        partner: partner,
        hasOptIn: yup.boolean().oneOf([true], 'Bitte akzeptiere, um Deinen Lebenslauf herunterzuladen.'),
        jobApplication: renderContentSchema,
      })
      .strict(true),
  })
  .strict(true);

export const postGetHelpSchema = yup
  .object()
  .shape({
    body: yup
      .object()
      .shape({
        partner: partner,
        jobApplicationId: yup.string().required(),
      })
      .strict(true),
  })
  .strict(true);

export const requestDemoSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Bitte geben Sie eine gültige E-Mail Adresse an.')
      .required('Bitte geben Sie Ihre E-Mail an.')
      .lowercase()
      .strict(true),
  })
  .strict(true);

export const postRequestDemoSchema = yup
  .object()
  .shape({
    body: requestDemoSchema,
  })
  .strict(true);
