import * as h from "vhtml";
import { PersonalDataContent, PortraitContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import { CroppedImage, getFontSizeToMakeTextFit } from "../../../utility/index";

const sectionHeight = 285;

type RenderPersonalDataSectionParams = {
  height: number;
  nameFontSize: number;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  birthplace?: string;
  street?: string;
  streetNumber?: string;
  postcode?: string;
  city?: string;
  phone?: string;
  email?: string;
  cvImage: PortraitContent;
};

const renderPersonalDataSection = ({
  height,
  nameFontSize,
  firstName,
  lastName,
  birthday,
  birthplace,
  street,
  streetNumber,
  postcode,
  city,
  phone,
  email,
  cvImage,
}: RenderPersonalDataSectionParams) => (
  <div
    class="personal-data"
    data-section-name="personal-data"
    data-assumed-height={height}
  >
    <div class="personal-data__page-header">
      <div>Bewerbung</div>
      <div>Lebenslauf</div>
    </div>
    <div class="personal-data__information">
      <div>
        <div class="personal-data__name" style={`font-size: ${nameFontSize}px`}>
          {firstName}
          <br />
          {lastName}
        </div>
        <div class="personal-data__birthday">
          {birthday}, {birthplace}
        </div>
      </div>
      <div class="personal-data__information-right">
        <div>
          {street}, {streetNumber}
        </div>
        <div>
          {postcode}, {city}
        </div>
        <div>{phone}</div>
        <div>{email}</div>
      </div>
    </div>
    <div class="personal-data__image">
      <CroppedImage portraitContent={cvImage} containerWidth={200} />
    </div>
  </div>
);

export const constructPersonalDataSection = (
  personalData: PersonalDataContent
) => ({
  containerPreference: ["default"],
  height: sectionHeight,
  tags: ["head", "cv"],
  render: () =>
    renderPersonalDataSection({
      cvImage: personalData.cvImage!,
      nameFontSize: (() => {
        const fontSizes = [personalData.firstName, personalData.lastName]
          .filter((text) => text !== undefined)
          .map((text) =>
            getFontSizeToMakeTextFit(text as string, "Lato-Medium", 200)
          );

        if (fontSizes.length === 0) {
          return 0;
        }
        const fontSize = Math.min(...fontSizes);

        return fontSize;
      })(),
      firstName: personalData.firstName,
      lastName: personalData.lastName,
      street: personalData.street,
      streetNumber: personalData.streetNumber,
      postcode: personalData.postcode,
      city: personalData.city,
      phone: personalData.telephone,
      email: personalData.email,
      height: sectionHeight,
      birthday:
        personalData.birthday === undefined
          ? undefined
          : format(personalData.birthday, "dd.MM.yyyy"),
      birthplace: personalData.birthplace,
    }),
});
