import { CssFontStyles, convertFontStylesToCss } from "../../utility/index";

export const fontStyles = {
  coverHeadline: {
    sizeInPx: 50,
    family: "Merriweather-Black",
  },
  autoFittedName: {
    sizeInPx: 70,
    family: "Merriweather-Black",
  },
  letterFont: {
    sizeInPx: 14,
    family: "Merriweather-Regular",
  },
  baseBold: {
    sizeInPx: 14,
    family: "Merriweather-Bold",
  },
  bigRegular: {
    sizeInPx: 16,
    family: "Merriweather-Regular",
  },
  baseRegular: {
    sizeInPx: 14,
    family: "Merriweather-Regular",
  },
  headline: {
    sizeInPx: 40,
    family: "Merriweather-Black",
  },
  subHeadline: {
    sizeInPx: 24,
    family: "Merriweather-Black",
  },
  letterHeadline: {
    sizeInPx: 16,
    family: "Merriweather-Black",
  },
  dateBold: {
    sizeInPx: 20,
    family: "Merriweather-Black",
  },
  dateRegular: {
    sizeInPx: 20,
    family: "Merriweather-Regular",
  },
} as const;

export const cssFontStyles: CssFontStyles = convertFontStylesToCss(fontStyles);
