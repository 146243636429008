import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";

import {
  DIN_A4_HEIGHT_IN_PX,
  DIN_A4_WIDTH_IN_PX,
  Fragment,
} from "../../../utility/index";

type LayoutParams = {
  curriculum_vitae: string;
};

const Layout = ({ curriculum_vitae }: LayoutParams) => (
  <Fragment>
    <svg
      class="splash-background"
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewbox="0 0 595.3 841.9"
    >
      <g>
        <path
          d="M209.9,1.2c0.7,0.3,1.4,0.5,2.2,0.6C223,3.5,234.4,4.5,244.8,7c9.9,2.4,19.2,5.8,27.9,9.5c12.7,5.4,22.5,12.5,29.4,20.8
	      c5.4,6.5,10.2,13.3,14.2,20.1c9.1,15.7,7.6,32.3,11.3,48.4c3.3,14.3,13.6,26.8,33.6,35.8c22.7,10.3,48.5,12.3,74.9,6.6
	      c11.6-2.5,21.3-8.1,31.8-12.3c34.8-13.7,66.1-11.1,85.2-6c17.4,4,32.2,10.9,42.1,19.7V1.2H209.9L209.9,1.2z"
        />
        <path
          d="M317.2,322.5c0-23.9-20.3-44.1-44-44.4c-24.6-0.3-45.6,21.9-45.1,45.5c0.4,21.9,18.9,44.3,46.1,43.8
	      C297.5,367,317.2,346.8,317.2,322.5z"
        />
        <path
          d="M366.2,518.2c1.9,48,43.2,87.4,92.4,86.4c50.7-2.1,88.3-42.2,87.8-91c-0.5-50.7-41.5-90.2-92.3-89.4
	      C406.2,425,364.2,466.5,366.2,518.2z"
        />
        <path
          d="M-187,442.2v401h292.3c39.1-44.4,62.8-102.1,62.8-165.3c0-140-116.2-253.4-259.6-253.4C-124.1,424.4-156.5,430.4-187,442.2z
        "
        />
      </g>
    </svg>
    <div class="curriculum-vitae__layout">{curriculum_vitae}</div>
  </Fragment>
);

export const cvLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "curriculum_vitae",
      height: DIN_A4_HEIGHT_IN_PX - 140,
      width: DIN_A4_WIDTH_IN_PX,
    },
  ],
};
