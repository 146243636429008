import { SkillCategoryForm, SkillForm } from "@apply-high/interfaces";
import { v4 as uuid } from "uuid";

export const LANGUAGE_LEVEL_OPTIONS: Array<{
  label: string;
  options: Array<{ value: string | number; text: string }>;
}> = [
  {
    label: "Sprachniveau (GER)",
    options: [
      { value: "C2", text: "C2" },
      { value: "C1", text: "C1" },
      { value: "B2", text: "B2" },
      { value: "B1", text: "B1" },
      { value: "A2", text: "A2" },
      { value: "A1", text: "A1" },
    ],
  },
  {
    label: "Sprachniveau",
    options: [
      { value: "100", text: "Muttersprache" },
      { value: "75", text: "Fließend" },
      { value: "50", text: "Gut" },
      { value: "25", text: "Grundkenntnisse" },
    ],
  },
];

export const INDUSTRY_IT = "it";
export const INDUSTRY_SALES = "sales";
export const INDUSTRY_FINANCE = "finance";

export const INDUSTRIES = {
  [INDUSTRY_IT]: "IT",
  [INDUSTRY_SALES]: "Sales",
  [INDUSTRY_FINANCE]: "Finance",
};

export const SKILL_CATEGORY_SUGGESTIONS_FOR_INDUSTRY = {
  [INDUSTRY_IT]: {
    Programmiersprachen: ["Python", "Java", "JavaScript", "TypeScript", "PHP"],
    Datenbanken: ["MySQL", "MariaDB", "PostgreSQL"],
    "DevOps & CI/CD": [
      "GitLab CI/CD",
      "GitHub Actions",
      "Docker",
      "Kubernetes",
    ],
    Betriebssysteme: ["Windows", "Linux", "MacOS"],
  },
  // [INDUSTRY_SALES]: {},
  [INDUSTRY_FINANCE]: {
    Weiterbildungen: [
      "Bilanzbuchhalter:in",
      "Finanzbuchhalter:in",
      "Steuerfachwirth:in",
      "Steuerberater:in",
    ],
    "ERP-Systeme": ["SAP", "Navision", "Datev", "Sage"],
  },
};

export const SKILL_CATEGORY_SUGGESTIONS = [
  "Sprachen",
  "PC-Kenntnisse",
  "Zertifikate",
  "Führerscheine",
  "Hobbys",
  "Sonstiges",
];

const SKILL_CATEGORY_LABEL_MAPPING: Record<string, string> = {
  Sprachen: "Sprache",
  "PC-Kenntnisse": "PC-Kenntnis",
  Zertifikate: "Zertifikat",
  Führerscheine: "Führerschein",
  Hobbys: "Hobby",
};

export const isLanguageCategory = (categoryName: string) => {
  return categoryName.trim() === "Sprachen";
};

export const getSingularLabelBySkillCategory = (
  categoryName: string
): string => {
  const singularLabel = SKILL_CATEGORY_LABEL_MAPPING[categoryName.trim()];

  if (singularLabel === undefined) {
    return "Fähigkeit";
  }

  return singularLabel;
};

export const getDescriptionByLevel = (level: string): string | null => {
  let option;

  for (const optionGroup of LANGUAGE_LEVEL_OPTIONS) {
    option = optionGroup.options.find((option) => option.value === level);

    if (option !== undefined) {
      break;
    }
  }

  if (option === undefined) {
    return null;
  }

  return option.text;
};

export const getSkillCategorySuggestions = (
  usedSkillCategories: Array<SkillCategoryForm>,
  industry: string
) => {
  const usedSkillCategoryNames = usedSkillCategories.map(
    (skillCategory) => skillCategory.name
  );
  let suggestions = SKILL_CATEGORY_SUGGESTIONS;

  if (industry !== "") {
    suggestions = [
      ...Object.keys(SKILL_CATEGORY_SUGGESTIONS_FOR_INDUSTRY[industry] ?? {}),
      ...suggestions,
    ];
  }

  return suggestions.filter(
    (suggestion) => usedSkillCategoryNames.includes(suggestion) === false
  );
};

export const getSkillSuggestionsBySkillCategory = (categoryName: string) => {
  for (const industrySuggestions of Object.values(
    SKILL_CATEGORY_SUGGESTIONS_FOR_INDUSTRY
  )) {
    if (industrySuggestions[categoryName] !== undefined) {
      return industrySuggestions[categoryName];
    }
  }

  return [];
};

export const getDefaultSkillEntryByCategoryName = (
  categoryName: string,
  index: number
): SkillForm => {
  if (isLanguageCategory(categoryName) === true) {
    return {
      id: uuid(),
      name: "",
      sort: index,
      level: "100",
      isCoreCompetency: false,
    };
  }

  return {
    id: uuid(),
    name: "",
    sort: index,
    level: "",
    isCoreCompetency: false,
  };
};

export const isOfficialLanguageLevel = (level: string): boolean => {
  const OFFICIAL_LANGUAGE_LEVELS = ["C2", "C1", "B2", "B1", "A2", "A1"];

  return OFFICIAL_LANGUAGE_LEVELS.includes(level);
};

export const getFlatSkills = (skillCategories: SkillCategoryForm[]) => {
  const skills = new Set<SkillForm>();

  for (const skillCategory of skillCategories) {
    for (const skill of skillCategory.skills) {
      skills.add(skill);
    }
  }

  return Array.from(skills.values());
};

export const getCoreCompetencies = (skillCategories: SkillCategoryForm[]) => {
  return getFlatSkills(skillCategories).filter(
    (skill) => skill.isCoreCompetency === true
  );
};

export const getSkillsAvailableAsCoreCompetency = (
  skillCategories: SkillCategoryForm[],
  coreCompetencies: SkillForm[]
) => {
  return getFlatSkills(skillCategories).filter(
    (skill) =>
      coreCompetencies.some(
        (coryCompetency) => coryCompetency.id === skill.id
      ) === false
  );
};
