import * as h from "vhtml";
import { WorkExperienceContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import { fontStyles } from "../font_styles";
import {
  constructTextWithLineBreaks,
  getTranslatedWorkExperienceType,
} from "../../../utility/index";

const headlineHeight = 61;
const employmentHeight = fontStyles.smallRegular.sizeInPx;
const entryMarginBottom = 15;
const sectionMarginBottom = 50;

export type PreformattedWorkExperienceContent = WorkExperienceContent & {
  descriptionHtml?: string;
  subHeadlineHtml: string;
  entryHeight: number;
};

type RenderWorkExperienceParams = {
  index: number;
  showHeadline: boolean;
  height: number;
  workExperiences: Array<{
    start: string;
    end: string;
    workplace: string;
    location: string;
    employment: string;
    descriptionHtml?: string;
    type: string;
  }>;
};
const renderWorkExperience = ({
  index,
  showHeadline,
  height,
  workExperiences,
}: RenderWorkExperienceParams) => (
  <div
    class="timeline"
    data-section-name={`work-experience:${index}`}
    data-assumed-height={height}
  >
    {showHeadline === true ? (
      <div class="headline">
        <svg
          class="headline__icon"
          data-prefix="fas"
          data-icon="map"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewbox="0 0 576 512"
        >
          <path d="M0 117.66v346.32c0 11.32 11.43 19.06 21.94 14.86L160 416V32L20.12 87.95A32.006 32.006 0 0 0 0 117.66zM192 416l192 64V96L192 32v384zM554.06 33.16L416 96v384l139.88-55.95A31.996 31.996 0 0 0 576 394.34V48.02c0-11.32-11.43-19.06-21.94-14.86z"></path>
        </svg>
        Berufserfahrung
      </div>
    ) : (
      ""
    )}
    {workExperiences.map(
      ({
        start,
        end,
        workplace,
        location,
        type,
        employment,
        descriptionHtml,
      }) => (
        <div class="timeline__container">
          <div class="timeline__time">
            <div class="timeline__end">{end}</div>
            <div class="timeline__start">{start}</div>
          </div>
          <div class="timeline__middle">
            <div class="timeline__line">
              <div class="timeline__dot"></div>
            </div>
          </div>
          <div class="timeline__content">
            <div class="timeline__headline">
              {workplace}, {location}
            </div>
            <div class="timeline__employment">
              {type}, {employment}
            </div>
            {descriptionHtml !== undefined ? (
              <div class="timeline__description">{descriptionHtml}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      )
    )}
  </div>
);

/*
  XX Berufserfahrung                                                           <-- headlineHeight

  Heute062019 |       Steuerberater van Heiden, Oberhausen                     <-- subHeadlineHeight
  08/2018     o       Praktikum Steuerfachangestellte                          <-- employmentHeight
              |       Beschäftigung mit Buchführung, betriebswirtschaftlichen  <-- descriptionHeight
              |       Auswertungen und Jahresabschlüßen
              |                                                                <-- entryMarginBottom

  <-- sectionMarginBottom
  XX Sprachen
  ..
*/
export const constructWorkExperienceSection = (
  workExperiences: Array<PreformattedWorkExperienceContent>,
  index: number
) => {
  const sectionHeight =
    (index === 0 ? headlineHeight : 0) +
    workExperiences.reduce(
      (totalHeight, entry) => totalHeight + entry.entryHeight,
      0
    ) +
    sectionMarginBottom -
    entryMarginBottom; // last entry has no entryMarginBottom, because that is used for gaps inbetween entries

  return {
    isVisible: workExperiences.length !== 0,
    containerPreference: ["split__right", "standard"],
    tags: ["cv"],
    height: sectionHeight,
    render: () =>
      renderWorkExperience({
        workExperiences: workExperiences.map((workExperience) => ({
          start: format(workExperience.start, "MM/yyyy"),
          end:
            workExperience.end === undefined
              ? "Heute"
              : format(workExperience.end, "MM/yyyy"),
          workplace: workExperience.workplace,
          location: workExperience.location,
          employment: workExperience.employment,
          descriptionHtml: workExperience.descriptionHtml,
          type: getTranslatedWorkExperienceType(workExperience.type),
        })),
        index: index,
        showHeadline: index === 0,
        height: sectionHeight,
      }),
  };
};

export const preformatWorkExperienceEntries = (
  workExperiences: Array<WorkExperienceContent>
): Array<PreformattedWorkExperienceContent> => {
  const preformattedWorkExperiences: Array<PreformattedWorkExperienceContent> =
    workExperiences.map((workExperienceEntry) => {
      const subHeadlineText = constructTextWithLineBreaks({
        text: `${workExperienceEntry.workplace}, ${workExperienceEntry.location}`,
        font: fontStyles.subHeadline,
        containerWidth: 300,
      });

      if (typeof workExperienceEntry.description !== "string") {
        const entryHeight =
          subHeadlineText.height + employmentHeight + entryMarginBottom;

        return {
          ...workExperienceEntry,
          subHeadlineHtml: subHeadlineText.htmlCode,
          entryHeight: entryHeight,
        };
      }

      const descriptionText = constructTextWithLineBreaks({
        text: workExperienceEntry.description,
        font: fontStyles.smallLight,
        containerWidth: 300,
        includeExplicitNewLines: true,
      });

      const entryHeight =
        subHeadlineText.height +
        employmentHeight +
        descriptionText.height +
        entryMarginBottom;

      return {
        ...workExperienceEntry,
        subHeadlineHtml: subHeadlineText.htmlCode,
        descriptionHtml: descriptionText.htmlCode,
        entryHeight: entryHeight,
      };
    });

  return preformattedWorkExperiences;
};
