import * as h from "vhtml";

import { measureText, getFontSizeToMakeTextFit } from "../text_util";
import { fontMap } from "../fontMap";
import { Fragment } from "./Fragment";

type FitTextParameters = {
  lines: Array<string>;
  fontFamily: keyof typeof fontMap;
  className?: string;
  isUpperCase?: boolean;
  letterSpacing?: number;
  maxHeightInPx?: number;
  lineHeightFactor?: number;
  widthInPx: number;
};

export const MultilineAutoFittedText = (
  fitTextParameters: FitTextParameters
): string => {
  const {
    lines,
    fontFamily,
    className,
    isUpperCase,
    letterSpacing,
    maxHeightInPx,
    widthInPx,
    lineHeightFactor,
  } = fitTextParameters;

  if (lines.length === 0) {
    return "";
  }

  const lineHeight = lineHeightFactor ?? 1.5;
  const fontSize = Math.min(
    ...lines.map((text) =>
      getFontSizeToMakeTextFit(
        text,
        fontFamily,
        widthInPx,
        isUpperCase,
        letterSpacing
      )
    )
  );

  if (
    maxHeightInPx === undefined ||
    fontSize * lineHeight * lines.length < maxHeightInPx
  ) {
    return (
      <div
        class={className}
        style={`font-family: ${fontFamily}; font-size: ${fontSize}px; line-height: normal;`}
      >
        {lines.map((text) => (
          <div>{text}</div>
        ))}
      </div>
    );
  }

  const maxFontSize = maxHeightInPx / lines.length / lineHeight;

  const smallestLetterSpacing = Math.min(
    ...lines.map((text) => {
      const missingWidth =
        measureText(text, fontFamily, fontSize, isUpperCase) -
        measureText(text, fontFamily, maxFontSize, isUpperCase);
      return missingWidth / (text.length - 1);
    })
  );

  // why weird substring logic? letter-spacing must not apply to last
  // letter. e.g. text-align: center should only regard the actual
  // text and not some letter-spacing to the right of the text
  return (
    <Fragment>
      {lines.map((text) => (
        <Fragment>
          <div
            class={className}
            style={`font-family: ${fontFamily}; font-size: ${maxFontSize}px; letter-spacing: ${smallestLetterSpacing}px; line-height: normal;`}
          >
            {text.substring(0, text.length - 1)}
            <span style="letter-spacing: 0">{text[text.length - 1]}</span>
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
};
