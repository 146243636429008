import { JobApplication } from "@apply-high/interfaces";

const LOCAL_STORAGE_KEY = "apply-high:whitelabel";

export const getFromLocalStorage = (): JobApplication => {
  const jobApplication = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (jobApplication === null) {
    return {
      image: null,
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      birthday: "",
      birthplace: "",
      postcode: "",
      city: "",
      street: "",
      streetNumber: "",
      workExperience: [],
      education: [],
      skillCategories: [],
    };
  }

  return JSON.parse(jobApplication);
};

export const saveToLocaleStorage = (jobApplication: JobApplication) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(jobApplication));
};
