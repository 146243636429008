import { CssFontStyles, convertFontStylesToCss } from "../../utility/index";

export const fontStyles = {
  coverHeadline: {
    sizeInPx: 0, // autocalculated by containerwidth
    family: "Lato-Regular",
  },
  autoFittedName: {
    sizeInPx: 0, // autocalculated by containerwidth
    family: "Lato-Medium",
  },
  baseLight: {
    sizeInPx: 14,
    family: "Lato-Light",
  },
  baseRegular: {
    sizeInPx: 14,
    family: "Lato-Regular",
  },
  baseBold: {
    sizeInPx: 14,
    family: "Lato-Bold",
  },
  headline: {
    sizeInPx: 24,
    family: "Lato-Regular",
  },
  subHeadline: {
    sizeInPx: 16,
    family: "Lato-Regular",
  },
  smallRegular: {
    sizeInPx: 12,
    family: "Lato-Regular",
  },
  smallLight: {
    sizeInPx: 12,
    family: "Lato-Light",
  },
} as const;

export const cssFontStyles: CssFontStyles = convertFontStylesToCss(fontStyles);
