import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";

import {
  DIN_A4_HEIGHT_IN_PX,
  DIN_A4_WIDTH_IN_PX,
} from "../../../utility/index";

type LayoutParams = {
  letter: string;
};

const Layout = ({ letter }: LayoutParams) => letter;

export const letterLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "letter",
      height: DIN_A4_HEIGHT_IN_PX,
      width: DIN_A4_WIDTH_IN_PX,
      minSectionsHeight: DIN_A4_HEIGHT_IN_PX,
    },
  ],
};
