import * as h from "vhtml";
import { EducationContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import { fontStyles } from "../font_styles";
import { constructTextWithLineBreaks, Fragment } from "../../../utility";

const headlineHeight = 68;
const degreeHeight = fontStyles.baseRegular.sizeInPx;
const entryMarginBottom = 20;
const sectionMarginBottom = 20;

export type PreformattedEducationContent = EducationContent & {
  descriptionHtml?: string;
  subHeadlineHtml: string;
  entryHeight: number;
};

type EducationParams = {
  index: number;
  height: number;
  education: Array<{
    start: string;
    end: string;
    institutionHtml: string;
    degree?: string;
    descriptionHtml?: string;
  }>;
  showHeadline: boolean;
};
const renderEducation = ({
  index,
  height,
  education,
  showHeadline,
}: EducationParams) => (
  <div data-section-name={`education:${index}`} data-assumed-height={height}>
    {showHeadline === true ? <div class="headline">Bildungsweg</div> : ""}
    <div class="timeline">
      {education.map(
        ({ end, start, institutionHtml, descriptionHtml, degree }) => (
          <Fragment>
            <div class="timeline__left">
              {start} - {end}
            </div>
            <div class="timeline__right">
              <div class="timeline__headline">{institutionHtml}</div>
              {degree !== undefined ? (
                <div class="timeline__subtitle">{degree}</div>
              ) : (
                ""
              )}
              {descriptionHtml !== undefined ? (
                <div class="timeline__description">{descriptionHtml}</div>
              ) : (
                ""
              )}
            </div>
          </Fragment>
        )
      )}
    </div>
  </div>
);

/*
   XX Bildungsweg                                                 <-- headlineHeight

   Heute       |       Hans-Böckler Berufskolleg, Oberhausen      <-- subHeadlineHeight
   08/2018     o       Fachhochschulreife                         <-- degreeHeight
   |       voraus NC: 1.6                             <-- descriptionHeight
   |                                                  <-- entryMarginBottom
   Heute       |       Hans-Böckler Berufskolleg, Oberhausen
   08/2018     o       Fachhochschulreife
   |       voraus NC: 1.6

   <-- sectionMarginBottom
   XX Berufserfahrung
   ..
 */
export const constructEducationSection = (
  preformattedEducation: Array<PreformattedEducationContent>,
  index: number
) => {
  const sectionHeight =
    (index === 0 ? headlineHeight : 0) +
    preformattedEducation.reduce(
      (totalHeight, entry) => totalHeight + entry.entryHeight,
      0
    ) +
    sectionMarginBottom;

  return {
    isVisible: preformattedEducation.length !== 0,
    containerPreference: ["split__right", "standard"],
    tags: ["cv"],
    height: sectionHeight,
    render: () =>
      renderEducation({
        education: preformattedEducation.map((education) => ({
          start: format(education.start, "MM/yyyy"),
          end:
            education.end === undefined
              ? "Heute"
              : format(education.end, "MM/yyyy"),
          institutionHtml: education.subHeadlineHtml,
          degree: education.degree,
          descriptionHtml: education.descriptionHtml,
        })),
        index: index,
        showHeadline: index === 0,
        height: sectionHeight,
      }),
  };
};

export const preformatEducationEntries = (
  education: Array<EducationContent>
): Array<PreformattedEducationContent> => {
  const preformattedEducation: Array<PreformattedEducationContent> =
    education.map((educationEntry, index) => {
      const subHeadlineText = constructTextWithLineBreaks({
        text: `${educationEntry.institution}, ${educationEntry.location}`,
        font: fontStyles.baseBold,
        containerWidth: 300,
      });

      const entryDegreeHeight =
        educationEntry.degree === undefined ? 0 : degreeHeight;

      // last entry has no bottom margin in grid
      const actualEntryMarginBottom =
        index === education.length - 1 ? 0 : entryMarginBottom;

      if (educationEntry.description === undefined) {
        const entryHeight =
          subHeadlineText.height + entryDegreeHeight + actualEntryMarginBottom;

        return {
          ...educationEntry,
          subHeadlineHtml: subHeadlineText.htmlCode,
          entryHeight: entryHeight,
        };
      }

      const descriptionText = constructTextWithLineBreaks({
        text: educationEntry.description,
        font: fontStyles.baseRegular,
        containerWidth: 300,
      });

      const entryHeight =
        subHeadlineText.height +
        entryDegreeHeight +
        (descriptionText.height ?? 0) +
        actualEntryMarginBottom;

      return {
        ...educationEntry,
        subHeadlineHtml: subHeadlineText.htmlCode,
        descriptionHtml: descriptionText?.htmlCode,
        entryHeight: entryHeight,
      };
    });

  return preformattedEducation;
};
