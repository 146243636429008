import * as h from "vhtml";
import { RenderContent, ColorScheme } from "@apply-high/interfaces";
import * as chroma from "chroma-js";
import { mapLanguageLevelToPercentage } from "../../../utility/language";

const drawLanguageCircle = (
  fillingColor: string,
  backgroundColor: string,
  percentage: number
): string => {
  const strokeWidth = 10;
  const radius = 25;
  const diameter = radius * 2;
  const padding = strokeWidth / 2;
  const radiusMinusPadding = radius - padding;

  if (percentage === 100) {
    return (
      <svg
        width={`${diameter}" height="${diameter}" viewbox="0 0 ${diameter} ${diameter}`}
      >
        <circle
          stroke={fillingColor}
          stroke-width={strokeWidth}
          cx={radius}
          cy={radius}
          r={radiusMinusPadding}
          fill="none"
        />
      </svg>
    );
  }

  const degree = 360 * (percentage / 100);
  const largeArcFlag = degree > 180 ? 1 : 0;
  const angleInRadians = ((degree - 90) * Math.PI) / 180.0;

  const targetX = Math.round(
    radius + radiusMinusPadding * Math.cos(angleInRadians)
  );
  const targetY = Math.round(
    radius + radiusMinusPadding * Math.sin(angleInRadians)
  );

  return (
    <svg
      width={diameter}
      height={diameter}
      viewbox={`0 0 ${diameter} ${diameter}`}
    >
      <circle
        stroke={backgroundColor}
        stroke-width={strokeWidth}
        cx={radius}
        cy={radius}
        r={radiusMinusPadding}
        fill="none"
      />
      <g stroke={fillingColor} fill="none" stroke-width={strokeWidth}>
        <path
          d={`M ${radius} ${padding} A ${radiusMinusPadding} ${radiusMinusPadding} 0 ${largeArcFlag} 1 ${targetX} ${targetY}`}
        />
      </g>
    </svg>
  );
};

type LanguageParams = {
  languages: Array<{
    name: string;
    level: string;
    circleSvg: string;
  }>;
  height: number;
};
const renderLanguages = ({ languages, height }: LanguageParams) => (
  <div
    class="languages"
    data-section-name="languages"
    data-assumed-height={height}
  >
    <div class="headline">
      <svg
        class="headline__icon"
        aria-hidden={true}
        focusable={false}
        data-prefix="fas"
        data-icon="comments"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewbox="0 0 576 512"
      >
        <path d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1.9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9.7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"></path>
      </svg>
      Sprachen
    </div>
    <div class="language__wrapper">
      {languages.map(({ name, level, circleSvg }) => (
        <div class="language">
          <div class="language__circle">{circleSvg}</div>
          <span class="language__name">{name}</span>
          <span class="language__level">{level}</span>
        </div>
      ))}
    </div>
  </div>
);

export const constructLanguagesSection = (
  content: RenderContent,
  colorScheme: ColorScheme
) => {
  const numEntriesPerRow = 4;
  const numEntries = content.languages.length;
  const numRows = Math.ceil(numEntries / numEntriesPerRow);
  const headlineHeight = 61;
  const heightPerRow = 120;
  const sectionMarginBottom = 40;
  const sectionHeight = headlineHeight + numRows * heightPerRow;

  return {
    isVisible: content.languages.length !== 0,
    containerPreference: ["split__right", "standard"],
    tags: ["cv"],
    height: sectionHeight,
    gapHeight: sectionMarginBottom,
    render: () =>
      renderLanguages({
        languages: content.languages.map((language) => {
          const { primaryColor } = colorScheme;
          const brightenedPrimaryColor = chroma(primaryColor)
            .brighten(1.5)
            .desaturate(0.75)
            .hex();
          const circleSvg = drawLanguageCircle(
            primaryColor,
            brightenedPrimaryColor,
            mapLanguageLevelToPercentage(language)
          );
          return {
            name: language.name,
            level:
              language.isOfficialLanguageLevel === true
                ? language.level
                : `${language.level}%`,
            circleSvg: circleSvg,
          };
        }),
        height: sectionHeight,
      }),
  };
};
