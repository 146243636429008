import { fontMap } from "./fontMap";

export const measureText = (
  text: string,
  fontFamily: keyof typeof fontMap,
  fontSize: number,
  isUpperCase = false,
  letterSpacing = 0
) => {
  const letterWidthMap = fontMap[fontFamily];
  if (letterWidthMap === undefined) {
    throw new Error(
      `FontFamily ${fontFamily} is not registered in the fontMap. Go to the themeparks font calculator and copy its outputs into the fontMap.`
    );
  }

  const textWithCorrectCase = isUpperCase === true ? text.toUpperCase() : text;

  const textWidth = textWithCorrectCase
    .split("")
    .reduce((totalWidth, letter) => {
      const letterWidthWithFontSize100 =
        (letterWidthMap as any)[letter] === undefined
          ? letterWidthMap["a"] // backup for unexpected chars
          : (letterWidthMap as any)[letter];

      const letterWidth = letterWidthWithFontSize100 * (fontSize / 100);
      return totalWidth + letterWidth + letterSpacing;
    }, 0);

  return textWidth;
};

export const getFontSizeToMakeTextFit = (
  text: string,
  fontFamily: keyof typeof fontMap,
  containerWidth: number,
  isUpperCase = false,
  letterSpacing = 0
): number => {
  const fixedFontSize = 16;
  const widthWith16px = measureText(
    text,
    fontFamily,
    fixedFontSize,
    isUpperCase,
    letterSpacing
  );

  const fullSizeToWidthWith16pxRatio = containerWidth / widthWith16px;
  const fontSize = fixedFontSize * fullSizeToWidthWith16pxRatio;

  return fontSize;
};
