import { CssFontStyles, convertFontStylesToCss } from "../../utility/index";

export const fontStyles = {
  coverName: {
    sizeInPx: 56,
    family: "Lato-Light",
  },
  coverHeadline: {
    sizeInPx: 24,
    family: "Lato-Light",
  },
  baseLight: {
    sizeInPx: 14,
    family: "Lato-Light",
  },
  baseRegular: {
    sizeInPx: 16,
    family: "Lato-Regular",
  },
  baseMedium: {
    sizeInPx: 14,
    family: "Lato-Medium",
  },
  headline: {
    sizeInPx: 22.5,
    family: "Lato-Bold",
  },
  subHeadline: {
    sizeInPx: 16,
    family: "Lato-Bold",
  },
};

export const cssFontStyles: CssFontStyles = convertFontStylesToCss(fontStyles);
