import {
  ThemeConfiguration,
  PageLayout,
  Container,
  Section,
} from "@apply-high/interfaces";

import { State, Page, Layout, ContainerSectionRelation } from "./interfaces";

const clone = <T>(state: T): T => JSON.parse(JSON.stringify(state));

enum SEARCH_INSTRUCTION {
  ABORT = "ABORT",
  STOP = "STOP",
  CONTINUE = "CONTINUE",
}

class ThemeLayoutService {
  private _theme: ThemeConfiguration;

  public constructor(theme: ThemeConfiguration) {
    this._theme = theme;
  }

  public generateLayout(): Layout {
    const initialState: State = {
      currentSectionIndex: 0,
      pages: [],
    };

    /*
      Returns a number of child states. How? Current section needs to
      be placed. Section tells you which containers are preferred. No
      preferred container on current page? Open up new page. Creates a
      new state for each entry in containerPreference.
    */
    const transition = (state: State): Array<State> => {
      const newStates: Array<State> = [];
      const currentSection = this._getCurrentSection(state);
      currentSection.containerPreference.forEach((containerId) => {
        // place content on current page
        const hasPage = state.pages.length !== 0;
        if (
          hasPage &&
          this._canPutCurrentSectionInContainerOnCurrentPage(state, containerId)
        ) {
          const newState = clone(state);
          const currentPage = this._getCurrentPage(newState);
          this._findRelationWithContainerId(
            currentPage,
            containerId
          ).sectionIndices.push(newState.currentSectionIndex);
          newState.currentSectionIndex += 1;
          newStates.push(newState);
        }

        if (
          hasPage &&
          !this._canPutCurrentSectionInContainerOnCurrentPage(
            state,
            containerId
          ) &&
          this._currentPageIsIncomplete(state)
        ) {
          // No way to fill the containers on current page? Dont add
          // another page, kill this searchtree (which this early return
          // does)
          return;
        }

        // open new page and place content there
        const pageLayoutIndex =
          this._findPageLayoutIndexIncludingContainer(containerId);

        if (pageLayoutIndex === -1) {
          throw new Error(
            `Could not find layout with container id "${containerId}"`
          );
        }

        const newState = clone(state);
        const pageLayout = this._theme.pageLayouts[pageLayoutIndex];
        const pageSectionRelations = pageLayout.containers.map((container) => ({
          containerId: container.id,
          sectionIndices:
            container.id === containerId ? [newState.currentSectionIndex] : [],
        }));
        newState.pages.push({
          pageLayoutIndex: pageLayoutIndex,
          containerSectionRelations: pageSectionRelations,
        });
        newState.currentSectionIndex += 1;
        newStates.push(newState);
      });

      return newStates;
    };

    const getSearchInstruction = (state: State) => {
      const sectionsAreSpread =
        state.currentSectionIndex >= this._theme.sections.length;
      if (sectionsAreSpread) {
        return this._anyPageIsIncomplete(state)
          ? SEARCH_INSTRUCTION.ABORT
          : SEARCH_INSTRUCTION.STOP;
      }

      return SEARCH_INSTRUCTION.CONTINUE;
    };

    const finalState = this._depthFirstSearch<State>(
      initialState,
      getSearchInstruction,
      transition
    );

    if (finalState === undefined) {
      throw new Error("No solution found.");
    }

    return {
      pages: finalState.pages,
    };
  }

  private _depthFirstSearch<Node>(
    node: Node,
    getSearchInstruction: (node: Node) => SEARCH_INSTRUCTION,
    discoverChildren: (node: Node) => Array<Node>
  ): Node | undefined {
    const searchState = getSearchInstruction(node);
    if (searchState === SEARCH_INSTRUCTION.ABORT) {
      return;
    } else if (searchState === SEARCH_INSTRUCTION.STOP) {
      return node;
    }
    const children = discoverChildren(node);
    for (const child of children) {
      const searchResult = this._depthFirstSearch(
        child,
        getSearchInstruction,
        discoverChildren
      );
      if (searchResult !== undefined) {
        return searchResult;
      }
    }
  }

  private _getCurrentSection(state: State): Section {
    return this._theme.sections[state.currentSectionIndex];
  }

  private _getCurrentPage(state: State): Page {
    return state.pages[state.pages.length - 1];
  }

  private _findContainerOnPageLayout(
    pageLayout: PageLayout,
    containerId: string
  ) {
    return pageLayout.containers.find(
      (container) => container.id === containerId
    ) as Container;
  }

  private _findContainerOnPage(page: Page, containerId: string): Container {
    const pageLayout = this._theme.pageLayouts[page.pageLayoutIndex];

    return this._findContainerOnPageLayout(pageLayout, containerId);
  }

  private _pageHasContainer(page: Page, containerId: string): boolean {
    return this._findContainerOnPage(page, containerId) !== undefined;
  }

  private _getSectionIndicesInContainer(
    page: Page,
    containerId: string
  ): Array<number> {
    const containerSectionRelations = page.containerSectionRelations.find(
      (relation) => relation.containerId === containerId
    ) as ContainerSectionRelation;

    return containerSectionRelations.sectionIndices;
  }

  private _getSectionsInContainer(page: Page, containerId: string) {
    const sectionIndices = this._getSectionIndicesInContainer(
      page,
      containerId
    );

    return sectionIndices.map(
      (sectionIndex) => this._theme.sections[sectionIndex]
    );
  }

  private _getSectionsInContainerHeight(page: Page, containerId: string) {
    const sectionsInContainer = this._getSectionsInContainer(page, containerId);

    return sectionsInContainer.reduce(
      (accumulatedHeight, currentSection) =>
        accumulatedHeight +
        currentSection.height +
        (currentSection.gapHeight === undefined ? 0 : currentSection.gapHeight),
      0
    );
  }

  private _sectionFitsInContainer(
    page: Page,
    section: Section,
    containerId: string
  ) {
    const container = this._findContainerOnPage(page, containerId);
    const sectionsInContainerHeight = this._getSectionsInContainerHeight(
      page,
      containerId
    );

    return container.height >= sectionsInContainerHeight + section.height;
  }

  private _canPutCurrentSectionInContainerOnCurrentPage(
    state: State,
    containerId: string
  ) {
    const currentSection = this._getCurrentSection(state);
    const page = this._getCurrentPage(state);

    return (
      this._pageHasContainer(page, containerId) &&
      this._sectionFitsInContainer(page, currentSection, containerId)
    );
  }

  private _pageLayoutHasContainer(pageLayout: PageLayout, containerId: string) {
    return (
      pageLayout.containers.find(
        (container) => container.id === containerId
      ) !== undefined
    );
  }

  private _findPageLayoutIndexIncludingContainer(containerId: string) {
    return this._theme.pageLayouts.findIndex((pageLayout) =>
      this._pageLayoutHasContainer(pageLayout, containerId)
    );
  }

  private _findRelationWithContainerId(page: Page, containerId: string) {
    return page.containerSectionRelations.find(
      (relation) => relation.containerId === containerId
    ) as ContainerSectionRelation;
  }

  private _pageIsIncomplete(page: Page): boolean {
    const relationHasIncompleteContainer = (
      relation: ContainerSectionRelation
    ) => {
      const { containerId } = relation;
      const { minSectionsHeight } = this._findContainerOnPage(
        page,
        containerId
      );
      if (minSectionsHeight === undefined) {
        return false;
      }
      const sectionsHeight = this._getSectionsInContainerHeight(
        page,
        containerId
      );

      return sectionsHeight < minSectionsHeight;
    };

    const pageHasIncompleteContainer =
      page.containerSectionRelations.find(relationHasIncompleteContainer) !==
      undefined;

    return pageHasIncompleteContainer;
  }

  private _currentPageIsIncomplete(state: State) {
    const page = this._getCurrentPage(state);

    return this._pageIsIncomplete(page);
  }

  private _anyPageIsIncomplete(state: State) {
    const stateHasIncompletePage =
      state.pages.find((page) => this._pageIsIncomplete(page)) !== undefined;

    return stateHasIncompletePage;
  }
}

export const generateLayout = (
  theme: ThemeConfiguration,
  tag?: string
): Layout => {
  theme.sections = theme.sections.filter(
    (section) => section.isVisible !== false
  );
  const layouter = new ThemeLayoutService(theme);
  const layout = layouter.generateLayout();
  if (tag === undefined) {
    return layout;
  }
  layout.pages = layout.pages.filter((page) => {
    const { containerSectionRelations } = page;
    const allSectionIndicesOnPage = containerSectionRelations.reduce(
      (allSectionIndices, previous) =>
        allSectionIndices.concat(previous.sectionIndices),
      [] as Array<number>
    );
    const allSectionsOnPage = allSectionIndicesOnPage.map(
      (index) => theme.sections[index]
    );
    const pageHasTaggedSection =
      allSectionsOnPage.find(
        (section) => section.tags !== undefined && section.tags.includes(tag)
      ) !== undefined;
    return pageHasTaggedSection;
  });
  return layout;
};
