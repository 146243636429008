import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";

import {
  DIN_A4_HEIGHT_IN_PX,
  DIN_A4_WIDTH_IN_PX,
  Fragment,
} from "../../../utility/index";

type LayoutParams = {
  letter: string;
};

const Layout = ({ letter }: LayoutParams) => (
  <Fragment>
    <svg
      class="letter__background"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewbox="0 0 595.28 841.89"
    >
      <path
        class="letter__background-curve"
        d="M595.3,645.9v196H0v-90.8C422.8,734.3,554.5,677.4,595.3,645.9z"
      />
    </svg>
    {letter}
  </Fragment>
);

export const letterLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "letter",
      height: DIN_A4_HEIGHT_IN_PX,
      width: DIN_A4_WIDTH_IN_PX,
    },
  ],
};
