import * as h from "vhtml";
import { RenderContent } from "@apply-high/interfaces";
import { format } from "date-fns";
import { de } from "date-fns/locale";

import {
  formatContactPerson,
  DIN_A4_HEIGHT_IN_PX,
  separateLetterIntoParts,
  Fragment,
} from "../../../utility/index";
import { fontStyles } from "../font_styles";

type FirstLetterSectionParameters = {
  subject?: string;
  text?: string;
  corporateName?: string;
  formattedContactPerson?: string;
  companyStreet?: string;
  companyStreetNumber?: string;
  companyPostcode?: string;
  companyCity?: string;
  currentDate: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  streetNumber?: string;
  postcode?: string;
  city?: string;
  phone?: string;
  email?: string;
};

const renderFirstLetterSection = ({
  corporateName,
  formattedContactPerson,
  companyStreet,
  companyStreetNumber,
  companyPostcode,
  companyCity,
  currentDate,
  firstName,
  lastName,
  street,
  streetNumber,
  postcode,
  city,
  phone,
  email,
  text,
  subject,
}: FirstLetterSectionParameters) => (
  <div data-section-name="letter" class="letter">
    <div class="letter__page-header">
      <div>Bewerbung</div>
      <div>Anschreiben</div>
    </div>
    <div class="letter__header">
      <div class="letter__header--left">
        <div class="letter__company-name">{corporateName}</div>
        {formattedContactPerson !== undefined ? (
          <div class="letter__company-contact-person">
            {formattedContactPerson}
          </div>
        ) : (
          ""
        )}
        <div class="letter__company-street">
          {companyStreet}{" "}
          {companyStreet !== undefined ? companyStreetNumber : ""}
        </div>
        <div class="letter__company-city">
          {companyPostcode} {companyCity}
        </div>
      </div>
      <div class="letter__header--right">
        <div class="letter__name">
          {firstName} {lastName}
        </div>
        <div class="letter__street">
          {street} {streetNumber}
        </div>
        <div class="letter__city">
          {postcode} {city}
        </div>
        <div class="letter__phone">{phone}</div>
        <div class="letter__email">{email}</div>
        <div class="letter__date">{currentDate}</div>
      </div>
    </div>
    <div class="letter__content">
      <div class="letter__subject">{subject}</div>
      {text}
    </div>
  </div>
);

const renderLetterFollowUp = (text: string) => (
  <Fragment>
    <div class="letter__follow-up letter">{text}</div>
    <div class="letter__footer"></div>
  </Fragment>
);

export const constructLetterSections = (content: RenderContent) =>
  separateLetterIntoParts({
    letter: content.letter,
    lineHeight: 21,
    font: fontStyles.baseLight,
    containerWidth: 622,
    firstPageHeight: 680,
    laterPagesHeight: 800,
    constructFirstSection: (text) => ({
      containerPreference: ["letter"],
      height: DIN_A4_HEIGHT_IN_PX,
      tags: ["letter"],
      render: () =>
        renderFirstLetterSection({
          subject: content.subject,
          text: text,
          currentDate: format(Date.now(), " dd. MMMM yyyy", {
            locale: de,
          }),
          firstName: content.personalData.firstName,
          lastName: content.personalData.lastName,
          street: content.personalData.street,
          streetNumber: content.personalData.streetNumber,
          postcode: content.personalData.postcode,
          city: content.personalData.city,
          phone: content.personalData.telephone,
          email: content.personalData.email,
          corporateName: content.companyData.corporateName,
          formattedContactPerson: formatContactPerson(
            content.companyData.contactPerson
          ),
          companyStreet: content.companyData.street,
          companyStreetNumber: content.companyData.streetNumber,
          companyPostcode: content.companyData.postcode,
          companyCity: content.companyData.city,
        }),
    }),
    constructLaterSection: (text) => ({
      containerPreference: ["letter"],
      height: DIN_A4_HEIGHT_IN_PX,
      tags: ["letter"],
      render: () => renderLetterFollowUp(text),
    }),
  });
