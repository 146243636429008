import * as h from "vhtml";

import { measureText, getFontSizeToMakeTextFit } from "../text_util";
import { fontMap } from "../fontMap";

type FitTextParameters = {
  text?: string;
  fontFamily: keyof typeof fontMap;
  className?: string;
  isUpperCase?: boolean;
  letterSpacing?: number;
  maxHeightInPx?: number;
  lineHeightFactor?: number;
  widthInPx: number;
};

export const AutoFittedText = (
  fitTextParameters: FitTextParameters
): string => {
  const {
    text,
    fontFamily,
    className,
    isUpperCase,
    letterSpacing,
    maxHeightInPx,
    widthInPx,
    lineHeightFactor,
  } = fitTextParameters;

  if (text === undefined) {
    return "";
  }

  const lineHeight = lineHeightFactor ?? 1.5;
  const fontSize = getFontSizeToMakeTextFit(
    text,
    fontFamily,
    widthInPx,
    isUpperCase,
    letterSpacing
  );

  if (maxHeightInPx === undefined || fontSize * lineHeight < maxHeightInPx) {
    return (
      <div
        class={className}
        style={`font-family: ${fontFamily}; font-size: ${fontSize}px; line-height: normal;`}
      >
        {text}
      </div>
    );
  }

  const maxFontSize = maxHeightInPx / lineHeight;
  const missingWidth =
    measureText(text, fontFamily, fontSize, isUpperCase) -
    measureText(text, fontFamily, maxFontSize, isUpperCase);
  const requiredLetterSpacing = missingWidth / (text.length - 1);

  // why weird substring logic? letter-spacing must not apply to last
  // letter. e.g. text-align: center should only regard the actual
  // text and not some letter-spacing to the right of the text
  return (
    <div
      class={className}
      style={`font-family: ${fontFamily}; font-size: ${maxFontSize}px; letter-spacing: ${requiredLetterSpacing}px; line-height: normal;`}
    >
      {text.substring(0, text.length - 1)}
      <span style="letter-spacing: 0">{text[text.length - 1]}</span>
    </div>
  );
};

export const fitText = (fitTextParameters: FitTextParameters) => (
  <AutoFittedText {...fitTextParameters} />
);
