import * as h from "vhtml";
import { SkillCategoryContent } from "@apply-high/interfaces";

import { constructTextWithLineBreaks } from "../../../utility/index";
import { fontStyles } from "../font_styles";

const renderSkills = (
  skillCategories: Array<{
    name: string;
    skills: Array<string>;
  }>,
  height: number,
  showHeadline: boolean,
  index: number
) => (
  <div data-section-name={`skills:${index}`} data-assumed-height={height}>
    {showHeadline === true ? (
      <div class="headline">
        <svg
          class="headline__icon"
          data-prefix="fas"
          data-icon="tools"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewbox="0 0 512 512"
        >
          <path d="M501.1 395.7L384 278.6c-23.1-23.1-57.6-27.6-85.4-13.9L192 158.1V96L64 0 0 64l96 128h62.1l106.6 106.6c-13.6 27.8-9.2 62.3 13.9 85.4l117.1 117.1c14.6 14.6 38.2 14.6 52.7 0l52.7-52.7c14.5-14.6 14.5-38.2 0-52.7zM331.7 225c28.3 0 54.9 11 74.9 31l19.4 19.4c15.8-6.9 30.8-16.5 43.8-29.5 37.1-37.1 49.7-89.3 37.9-136.7-2.2-9-13.5-12.1-20.1-5.5l-74.4 74.4-67.9-11.3L334 98.9l74.4-74.4c6.6-6.6 3.4-17.9-5.7-20.2-47.4-11.7-99.6.9-136.6 37.9-28.5 28.5-41.9 66.1-41.2 103.6l82.1 82.1c8.1-1.9 16.5-2.9 24.7-2.9zm-103.9 82l-56.7-56.7L18.7 402.8c-25 25-25 65.5 0 90.5s65.5 25 90.5 0l123.6-123.6c-7.6-19.9-9.9-41.6-5-62.7zM64 472c-13.2 0-24-10.8-24-24 0-13.3 10.7-24 24-24s24 10.7 24 24c0 13.2-10.7 24-24 24z"></path>
        </svg>
        Fähigkeiten
      </div>
    ) : (
      ""
    )}
    <div class="skills__container">
      {skillCategories.map(({ name, skills }) => (
        <div class="skills__category">
          <div class="sub-headline">{name}</div>
          {skills.map((skill) => (
            <div class="skills__list-entry">
              <div class="skills__bullet-point">&bull;</div>
              {skill}
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);

/*
   XX Fähigkeiten                                                  <-- headlineHeight

   Praktische Berufserfahrung     Sozialkompetenzen                <-- subHeadlineHeight

   - Einkommensteuer              - Hohe Kommunikationsfähigkeit   <-- autocalculated(may have linebreaks)
   - Körperschaftsteuer           - Eigeninitiative                <-- autocalculated(may have linebreaks)
   - Bilanzsteuerrecht            - Selbständige Arbeitsweise      <-- autocalculated(may have linebreaks)
   <-- rowMarginBottom
 */
export const constructSkillsSection = (
  skillCategories: Array<SkillCategoryContent>
) => {
  const headlineHeight = 61;
  const subHeadlineHeight = 19;
  const rowMarginBottom = 40;

  type PreformattedSkillCategory = {
    name: string;
    skills: Array<string>;
    height: number;
  };
  const preformattedSkillCategories: Array<PreformattedSkillCategory> =
    skillCategories
      .sort((a, b) => (a.skills.length <= b.skills.length ? 1 : -1))
      .map((category) => {
        const preformattedSkills = category.skills.map((skill) =>
          constructTextWithLineBreaks({
            text: skill,
            font: fontStyles.smallRegular,
            containerWidth: 200,
          })
        );
        return {
          name: category.name,
          skills: preformattedSkills.map((skill) => skill.htmlCode),
          height: preformattedSkills.reduce(
            (totalHeight, skill) => totalHeight + skill.height,
            0
          ),
        };
      });
  // tuple does not always have even numbers ... because total number of skills may be odd
  type SkillCategoryTuple =
    | [PreformattedSkillCategory, PreformattedSkillCategory]
    | [PreformattedSkillCategory];
  const skillCategoryTuples: Array<SkillCategoryTuple> = [];
  for (let i = 0; i < preformattedSkillCategories.length; i += 2) {
    if (preformattedSkillCategories[i + 1] === undefined) {
      skillCategoryTuples.push([preformattedSkillCategories[i]]);
    } else {
      skillCategoryTuples.push([
        preformattedSkillCategories[i],
        preformattedSkillCategories[i + 1],
      ]);
    }
  }
  return skillCategoryTuples.map((skillCategoryTuple, index) => {
    const contentHeight =
      skillCategoryTuple.reduce(
        (totalHeight, category) => Math.max(totalHeight, category.height),
        0
      ) +
      subHeadlineHeight +
      rowMarginBottom;

    const sectionHeight = contentHeight + (index === 0 ? headlineHeight : 0);

    return {
      containerPreference: ["split__right", "standard"],
      tags: ["cv"],
      height: sectionHeight,
      render: () =>
        renderSkills(skillCategoryTuple, sectionHeight, index === 0, index),
    };
  });
};
