import React, { useState } from "react";
import { JobApplication } from "@apply-high/interfaces";
import { Button, FadeIn } from "@apply-high/components";

import { Steps } from "./Steps";
import SkillCategoryEditor from "../Forms/SkillCategoryEditor";
import { ReactComponent as Left } from "../assets/svgs/arrow-left-light.svg";
import { ReactComponent as Right } from "../assets/svgs/arrow-right-light.svg";
import { Link } from "react-router-dom";

type SkillsStepProps = {
  jobApplication: JobApplication;
  updateJobApplication: (updatedJobApplication: JobApplication) => void;
  industry: string;
};

const SkillsStep: React.FC<SkillsStepProps> = ({
  jobApplication,
  updateJobApplication,
  industry,
}) => {
  const [isInteracting, updateIsInteracting] = useState<boolean>(false);

  return (
    <>
      <FadeIn isActive>
        <SkillCategoryEditor
          jobApplication={jobApplication}
          updateJobApplication={updateJobApplication}
          onUpdateMode={updateIsInteracting}
          industry={industry}
        />
      </FadeIn>
      <div
        className={`d-flex justify-content-between mt-5 ${
          isInteracting ? "invisible" : "visible"
        }`}
      >
        <Button
          variant="easy"
          render={(props) => <Link {...props} to={`../${Steps.EDUCATION}`} />}
        >
          <Left className="me-2" />
          Zurück
        </Button>
        <Button
          variant="strong"
          render={(props) => <Link {...props} to={`../${Steps.DOWNLOAD}`} />}
        >
          Weiter
          <Right className="ms-2" />
        </Button>
      </div>
    </>
  );
};

export default SkillsStep;
