import * as h from "vhtml";
import { EducationContent } from "@apply-high/interfaces";
import { format } from "date-fns";
import { staticResources } from "../static";

import { fontStyles } from "../font_styles";
import { constructTextWithLineBreaks, StaticSVG } from "../../../utility/index";

const headlineHeight = 61;
const degreeHeight = fontStyles.smallRegular.sizeInPx;
const entryMarginBottom = 15;
const sectionMarginBottom = 50;

export type PreformattedEducationContent = EducationContent & {
  descriptionHtml?: string;
  subHeadlineHtml: string;
  entryHeight: number;
};

type EducationParams = {
  index: number;
  height: number;
  education: Array<{
    start: string;
    end: string;
    institutionHtml: string;
    degree?: string;
    descriptionHtml?: string;
  }>;
  showHeadline: boolean;
};
const renderEducation = ({
  index,
  height,
  education,
  showHeadline,
}: EducationParams) => (
  <div
    class="timeline"
    data-section-name={`education:${index}`}
    data-assumed-height={height}
  >
    {showHeadline === true ? (
      <div class="headline">
        {/* class: headline__icon */}
        <StaticSVG
          resource={staticResources["icons/graduation-cap-solid.svg"]}
        />
        Bildungsweg
      </div>
    ) : (
      ""
    )}
    {education.map(
      ({ end, start, institutionHtml, descriptionHtml, degree }) => (
        <div class="timeline__container">
          <div class="timeline__time">
            <div class="timeline__end">{end}</div>
            <div class="timeline__start">{start}</div>
          </div>
          <div class="timeline__middle">
            <div class="timeline__line">
              <div class="timeline__dot"></div>
            </div>
          </div>
          <div class="timeline__content">
            <div class="timeline__headline">{institutionHtml}</div>
            {degree !== undefined ? (
              <div class="timeline__degree">{degree}</div>
            ) : (
              ""
            )}

            {descriptionHtml !== undefined ? (
              <div class="timeline__description">{descriptionHtml}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      )
    )}
  </div>
);

/*
   XX Bildungsweg                                                 <-- headlineHeight

   Heute       |       Hans-Böckler Berufskolleg, Oberhausen      <-- subHeadlineHeight
   08/2018     o       Fachhochschulreife                         <-- degreeHeight
   |       voraus NC: 1.6                             <-- descriptionHeight
   |                                                  <-- entryMarginBottom
   Heute       |       Hans-Böckler Berufskolleg, Oberhausen
   08/2018     o       Fachhochschulreife
   |       voraus NC: 1.6

   <-- sectionMarginBottom
   XX Berufserfahrung
   ..
 */
export const constructEducationSection = (
  preformattedEducation: Array<PreformattedEducationContent>,
  index: number
) => {
  const sectionHeight =
    (index === 0 ? headlineHeight : 0) +
    preformattedEducation.reduce(
      (totalHeight, entry) => totalHeight + entry.entryHeight,
      0
    ) +
    sectionMarginBottom -
    entryMarginBottom; // last entry has no entryMarginBottom, because that is used for gaps inbetween entries;

  return {
    isVisible: preformattedEducation.length !== 0,
    containerPreference: ["split__right", "standard"],
    tags: ["cv"],
    height: sectionHeight,
    render: () =>
      renderEducation({
        education: preformattedEducation.map((education) => ({
          start: format(education.start, "MM/yyyy"),
          end:
            education.end === undefined
              ? "Heute"
              : format(education.end, "MM/yyyy"),
          institutionHtml: education.subHeadlineHtml,
          degree: education.degree,
          descriptionHtml: education.descriptionHtml,
        })),
        index: index,
        showHeadline: index === 0,
        height: sectionHeight,
      }),
  };
};

export const preformatEducationEntries = (
  education: Array<EducationContent>
): Array<PreformattedEducationContent> => {
  const preformattedEducation: Array<PreformattedEducationContent> =
    education.map((educationEntry) => {
      const subHeadlineText = constructTextWithLineBreaks({
        text: `${educationEntry.institution}, ${educationEntry.location}`,
        font: fontStyles.subHeadline,
        containerWidth: 300,
      });

      const entryDegreeHeight =
        educationEntry.degree === undefined ? 0 : degreeHeight;

      if (educationEntry.description === undefined) {
        const entryHeight =
          subHeadlineText.height + entryDegreeHeight + entryMarginBottom;

        return {
          ...educationEntry,
          subHeadlineHtml: subHeadlineText.htmlCode,
          entryHeight: entryHeight,
        };
      }

      const descriptionText = constructTextWithLineBreaks({
        text: educationEntry.description,
        font: fontStyles.smallLight,
        containerWidth: 300,
      });

      const entryHeight =
        subHeadlineText.height +
        entryDegreeHeight +
        (descriptionText.height ?? 0) +
        entryMarginBottom;

      return {
        ...educationEntry,
        subHeadlineHtml: subHeadlineText.htmlCode,
        descriptionHtml: descriptionText?.htmlCode,
        entryHeight: entryHeight,
      };
    });

  return preformattedEducation;
};
