import * as yup from 'yup';

import { renderContentSchema } from '../index';

export const renderSerializedJobApplicationRequestSchema = yup
  .object()
  .shape({
    body: yup
      .object()
      .shape({
        color: yup.string().required().strict(true),
        themeSlug: yup.string().required().strict(true),
        serializedJobApplication: renderContentSchema,
      })
      .strict(true),
  })
  .strict(true);
