import * as h from "vhtml";
import { WorkExperienceContent, Section } from "@apply-high/interfaces";
import { format } from "date-fns";

import { fontStyles } from "../font_styles";
import {
  constructTextWithLineBreaks,
  getTranslatedWorkExperienceType,
} from "../../../utility";

type WorkExperienceEntry = {
  workplace: string;
  location: string;
  start: string;
  end: string;
  employment: string;
  type: string;
  description?: string;
};

type RenderWorkExperienceSectionParams = {
  index: number;
  isLastRow: boolean;
  height: number;
  isFirstRow: boolean;
  left: WorkExperienceEntry;
  right?: WorkExperienceEntry;
};

const renderWorkExperienceSection = ({
  index,
  isLastRow,
  height,
  isFirstRow,
  left,
  right,
}: RenderWorkExperienceSectionParams) => (
  <div
    class={`work-experience ${
      isLastRow === true ? "work-experience--last" : ""
    }`}
    data-section-name={`work-experience-row:${index}`}
    data-assumed-height={height}
  >
    {isFirstRow === true ? (
      <h1 class="work-experience__headline">
        <svg
          aria-hidden={true}
          focusable={false}
          data-prefix="fas"
          data-icon="briefcase"
          class="svg-inline--fa fa-briefcase fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewbox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M320 336c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h416c25.6 0 48-22.4 48-48V288H320v48zm144-208h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128v32z"
          ></path>
        </svg>
        <span>Berufserfahrung</span>
      </h1>
    ) : (
      ""
    )}
    <div class="work-experience__row">
      {[left, right]
        .filter((entry) => entry !== undefined)
        .map(
          ({
            location,
            workplace,
            start,
            end,
            employment,
            description,
            type,
          }: WorkExperienceEntry) => (
            <div class="work-experience__item">
              <div class="work-experience__sub-headline">
                {workplace}{" "}
                <span class="work-experience__city">{location}</span>
              </div>
              <div class="work-experience__date">
                {start} - {end}
              </div>
              <div class="work-experience__employment">
                {type}, {employment}
              </div>
              {description !== undefined ? (
                <div class="work-experience__description">{description}</div>
              ) : (
                ""
              )}
            </div>
          )
        )}
    </div>
  </div>
);

export const constructWorkExperiencesSections = (
  workExperience: Array<WorkExperienceContent>
): Array<Section> => {
  const sections: Array<Section> = [];

  type PreformattedWorkExperienceContent = WorkExperienceContent & {
    subHeadlineHeight: number;
    descriptionHtml?: string;
    descriptionHeight: number;
  };

  const preformattedWorkExperience: Array<PreformattedWorkExperienceContent> =
    workExperience.map((workExperienceEntry) => {
      const { height } = constructTextWithLineBreaks({
        text: `${workExperienceEntry.workplace} ${workExperienceEntry.location}`,
        font: fontStyles.subHeadline,
        containerWidth: 312,
      });
      const description =
        typeof workExperienceEntry.description === "string"
          ? constructTextWithLineBreaks({
              text: workExperienceEntry.description,
              font: fontStyles.baseLight,
              containerWidth: 312,
              includeExplicitNewLines: true,
            })
          : undefined;

      return {
        ...workExperienceEntry,
        subHeadlineHeight: height,
        descriptionHtml: description?.htmlCode,
        descriptionHeight: description?.height ?? 0,
      };
    });

  const headlineHeightInPx = 73;
  const timeSpanHeight = 24;
  const employmentHeight = 31;

  for (let i = 0; i < preformattedWorkExperience.length; i += 2) {
    const leftWorkExperience = preformattedWorkExperience[i];
    const rightWorkExperience = preformattedWorkExperience[i + 1];
    const isLastRow = i + 2 >= preformattedWorkExperience.length;

    const getEntryHeight = (entry: PreformattedWorkExperienceContent) => {
      const { subHeadlineHeight, descriptionHeight } = entry;
      const margin = isLastRow === true ? 75 : 50;

      return (
        subHeadlineHeight +
        timeSpanHeight +
        employmentHeight +
        descriptionHeight +
        margin
      );
    };

    const rowHeightInPx =
      rightWorkExperience === undefined
        ? getEntryHeight(leftWorkExperience)
        : Math.max(
            getEntryHeight(leftWorkExperience),
            getEntryHeight(rightWorkExperience)
          );

    const sectionHeight =
      i === 0 ? headlineHeightInPx + rowHeightInPx : rowHeightInPx;
    sections.push({
      isVisible: workExperience.length !== 0,
      containerPreference: ["default"],
      tags: ["cv"],
      height: sectionHeight,
      render: () =>
        renderWorkExperienceSection({
          isFirstRow: i === 0,
          isLastRow: isLastRow,
          left: {
            start: format(leftWorkExperience.start, "MM/yyyy"),
            end:
              leftWorkExperience.end === undefined
                ? "Heute"
                : format(leftWorkExperience.end, "MM/yyyy"),
            employment: leftWorkExperience.employment,
            workplace: leftWorkExperience.workplace,
            location: leftWorkExperience.location,
            description: leftWorkExperience.descriptionHtml,
            type: getTranslatedWorkExperienceType(leftWorkExperience.type),
          },
          right:
            rightWorkExperience === undefined
              ? undefined
              : {
                  start: format(rightWorkExperience.start, "MM/yyyy"),
                  end:
                    rightWorkExperience.end === undefined
                      ? "Heute"
                      : format(rightWorkExperience.end, "MM/yyyy"),
                  employment: rightWorkExperience.employment,
                  workplace: rightWorkExperience.workplace,
                  location: rightWorkExperience.location,
                  description: rightWorkExperience.descriptionHtml,
                  type: getTranslatedWorkExperienceType(
                    rightWorkExperience.type
                  ),
                },
          height: sectionHeight,
          index: i / 2,
        }),
    });
  }
  return sections;
};
