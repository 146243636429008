import * as h from "vhtml";
import { PersonalDataContent, PortraitContent } from "@apply-high/interfaces";

import {
  CroppedImage,
  DIN_A4_HEIGHT_IN_PX,
  DIN_A4_WIDTH_IN_PX,
} from "../../../utility/index";

type RenderCoverSectionParams = {
  coverImage: PortraitContent;
  firstName?: string;
  lastName?: string;
};

const renderCoverSection = ({
  coverImage,
  firstName,
  lastName,
}: RenderCoverSectionParams) => (
  <div class="cover">
    <div class="cover__image-container">
      <CroppedImage
        portraitContent={coverImage}
        containerWidth={DIN_A4_WIDTH_IN_PX}
      />
    </div>
    <div class="cover__bottom-bar">
      <div class="cover__name">
        {firstName} {lastName}
      </div>
      <div class="cover__headline">Bewerbung</div>
    </div>
  </div>
);

export const constructCoverSection = (personalData: PersonalDataContent) => ({
  containerPreference: ["cover"],
  height: DIN_A4_HEIGHT_IN_PX,
  tags: ["half_length", "cover"],
  render: () =>
    renderCoverSection({
      firstName: personalData.firstName,
      lastName: personalData.lastName,
      coverImage: personalData.coverImage!,
    }),
});
