import { LanguageContent } from "@apply-high/interfaces";

export const mapLanguageLevelToPercentage = (
  language: LanguageContent
): number => {
  const { level } = language;

  if (language.isOfficialLanguageLevel === false) {
    return parseInt(level, 10);
  }

  const percentage = {
    C2: 100,
    C1: 100,
    B2: 75,
    B1: 50,
    A2: 25,
    A1: 25,
  }[level];

  if (percentage === undefined) {
    throw new Error(`Unexpected level: ${level}`);
  }

  return percentage;
};
