import React from "react";
import {
  FieldArray,
  ArrayHelpers,
  Formik,
  getIn,
  FormikHelpers,
  FormikProps,
} from "formik";
import { SkillCategoryForm as SkillCategoryFormFields } from "@apply-high/interfaces";
import { skillCategorySchema } from "@apply-high/schemas";
import { Button, Input } from "@apply-high/components";

import { ReactComponent as Plus } from "../assets/svgs/plus-light.svg";
import { ReactComponent as Trash } from "../assets/svgs/trash-alt-light.svg";
import {
  getSingularLabelBySkillCategory,
  getDefaultSkillEntryByCategoryName,
  LANGUAGE_LEVEL_OPTIONS,
  getSkillSuggestionsBySkillCategory,
  isLanguageCategory,
} from "../Util/skills";

type SkillCategoryFormProps = {
  type: "create" | "edit";
  values: SkillCategoryFormFields;
  suggestions: Array<string>;
  onSubmit(
    values: SkillCategoryFormFields,
    actions: FormikHelpers<SkillCategoryFormFields>
  ): void;
  onAbort(): void;
};

type SkillCategoryFormState = {
  isSuggestionListOpen: boolean;
};

class SkillCategoryForm extends React.PureComponent<
  SkillCategoryFormProps,
  SkillCategoryFormState
> {
  private readonly categoryNameInputRef;

  constructor(props: SkillCategoryFormProps) {
    super(props);

    this.state = {
      isSuggestionListOpen: false,
    };

    this.categoryNameInputRef = React.createRef<HTMLInputElement>();
  }

  componentDidMount() {
    this.autoFocusSkillCategoryNameInput();
  }

  addSkillEntry = (
    arrayHelpers: ArrayHelpers,
    categoryName: string,
    index: number
  ) => {
    arrayHelpers.push(getDefaultSkillEntryByCategoryName(categoryName, index));
  };

  autoFocusSkillCategoryNameInput = () => {
    const { type, values } = this.props;

    if (
      this.categoryNameInputRef.current !== null &&
      type === "create" &&
      // Hint: When category was selected via one-click suggestion name !== ""
      values.name.trim() === ""
    ) {
      this.categoryNameInputRef.current.focus();
    }
  };

  renderForm = (formikProps: FormikProps<SkillCategoryFormFields>) => {
    const { type, suggestions, onAbort } = this.props;
    const { isSuggestionListOpen } = this.state;

    return (
      <form
        noValidate={true}
        onSubmit={formikProps.handleSubmit}
        className="card"
      >
        <FieldArray
          name="skills"
          render={(arrayHelpers) =>
            isSuggestionListOpen ? (
              <div className="list-group list-group-flush">
                {suggestions.map((suggestion) => (
                  <button
                    key={suggestion}
                    type="button"
                    className="list-group-item list-group-item-action p-3"
                    onClick={() => {
                      formikProps.setFieldValue("name", suggestion);

                      if (formikProps.values.skills.length === 0) {
                        this.addSkillEntry(arrayHelpers, suggestion, 0);
                      }

                      this.setState({ isSuggestionListOpen: false });
                    }}
                  >
                    {suggestion}
                  </button>
                ))}
              </div>
            ) : (
              <div className="card-body">
                <div className="mb-3 position-relative">
                  <Input
                    ref={this.categoryNameInputRef}
                    label="Kategorie"
                    id="name"
                    value={formikProps.values.name}
                    error={formikProps.errors.name}
                    touched={formikProps.touched.name}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    help="Beispiele: Sprachen, Fähigkeiten, Hobbys"
                    autoComplete="off"
                    padded={false}
                  />
                  <button
                    type="button"
                    // Offset of Input height
                    style={{ top: "60px" }}
                    className="btn btn-sm btn-link p-0 position-absolute end-0"
                    onClick={() =>
                      this.setState({ isSuggestionListOpen: true })
                    }
                    tabIndex={-1}
                  >
                    Vorschläge
                  </button>
                </div>
                <div className="h6 mb-2">Einträge</div>
                {formikProps.values.skills.length === 0 && (
                  <>
                    <p className="text-muted mt-1 mb-2">
                      Du hast noch keine Einträge hinzugefügt.
                    </p>
                    <Button
                      size="small"
                      onClick={() =>
                        this.addSkillEntry(
                          arrayHelpers,
                          formikProps.values.name,
                          formikProps.values.skills.length
                        )
                      }
                    >
                      <Plus height={16} />
                      <span className="ms-2">Eintrag hinzufügen</span>
                    </Button>
                  </>
                )}
                <div className="vstack gap-2 mb-2" role="list">
                  {formikProps.values.skills.map((skill, index) => (
                    <div
                      key={skill.id}
                      className="d-flex align-items-start"
                      role="listitem"
                    >
                      <Input
                        label={getSingularLabelBySkillCategory(
                          formikProps.values.name
                        )}
                        id={`skills[${index}].name`}
                        value={formikProps.values.skills[index].name}
                        error={getIn(
                          formikProps.errors,
                          `skills[${index}].name`
                        )}
                        touched={getIn(
                          formikProps.touched,
                          `skills[${index}].name`
                        )}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        padded={false}
                        // eslint-disable-next-line
                        autoFocus={
                          formikProps.values.skills.length - 1 === index
                        }
                        autoComplete="off"
                        suggestions={getSkillSuggestionsBySkillCategory(
                          formikProps.values.name
                        )}
                      />
                      {isLanguageCategory(formikProps.values.name) === true && (
                        <div className="form-floating w-100">
                          <select
                            name={`skills[${index}].level`}
                            id={`skills[${index}].level`}
                            className="ms-1 form-select"
                            value={formikProps.values.skills[index].level}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                          >
                            {LANGUAGE_LEVEL_OPTIONS.map((optionGroup) => (
                              <optgroup
                                key={optionGroup.label}
                                label={optionGroup.label}
                              >
                                {optionGroup.options.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </option>
                                ))}
                              </optgroup>
                            ))}
                          </select>
                          <label htmlFor={`skills[${index}].level`}>
                            Niveau
                          </label>
                        </div>
                      )}
                      <div style={{ marginTop: "11.5px" }}>
                        <Button
                          circled
                          variant="easy"
                          title="Entfernen"
                          onClick={() => arrayHelpers.remove(index)}
                          tabIndex={-1}
                        >
                          <Trash width={16} />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                {formikProps.values.skills.length > 0 && (
                  <Button
                    size="small"
                    variant="outline"
                    onClick={() =>
                      this.addSkillEntry(
                        arrayHelpers,
                        formikProps.values.name,
                        formikProps.values.skills.length
                      )
                    }
                  >
                    <Plus className="me-2" />
                    Eintrag hinzufügen
                  </Button>
                )}
              </div>
            )
          }
        />
        {isSuggestionListOpen ? (
          <div className="card-footer">
            <div className="ms-n2">
              <Button
                variant="easy"
                onClick={() => this.setState({ isSuggestionListOpen: false })}
              >
                Zurück
              </Button>
            </div>
          </div>
        ) : (
          <div className="card-footer d-flex justify-content-end">
            <Button
              variant="easy"
              disabled={formikProps.isSubmitting}
              onClick={onAbort}
            >
              Abbrechen
            </Button>
            <Button
              type="submit"
              variant="strong"
              disabled={formikProps.isSubmitting}
              loading={formikProps.isSubmitting}
            >
              {type === "create"
                ? "Kategorie speichern"
                : "Änderungen speichern"}
            </Button>
          </div>
        )}
      </form>
    );
  };

  render() {
    const { values, onSubmit } = this.props;

    return (
      <Formik<SkillCategoryFormFields>
        initialValues={values}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          // This prevents the following:
          // 1. User selects category "Sprachen"
          // 2. User creates skills with level != null (which is desired behaviour for category "Sprachen")
          // 3. User switches the category to something different from "Sprachen" and skills still have a level != null
          const skillCategory =
            isLanguageCategory(values.name) === true
              ? values
              : {
                  ...values,
                  skills: values.skills.map((skill) => ({
                    ...skill,
                    level: "",
                  })),
                };

          onSubmit(skillCategory, actions);
        }}
        validationSchema={skillCategorySchema("form")}
      >
        {(formikProps) => this.renderForm(formikProps)}
      </Formik>
    );
  }
}

export default SkillCategoryForm;
