import * as h from "vhtml";
import { SkillCategoryContent } from "@apply-high/interfaces";

import { constructTextWithLineBreaks } from "../../../utility/index";
import { fontStyles } from "../font_styles";

type RenderSkillsParams = {
  showHeadline: boolean;
  index: number;
  height: number;
  skillCategory: {
    skills: Array<string>;
    name: string;
  };
};

const renderSkills = ({
  showHeadline,
  index,
  height,
  skillCategory,
}: RenderSkillsParams) => (
  <div
    class="skills"
    data-section-name={`skills-row:${index}`}
    data-assumed-height={height}
  >
    {showHeadline === true ? (
      <div class="headline">
        <div class="headline__circle"></div>
        <div class="headline__text">
          <span class="headline__first-letter">F</span>ähigkeiten
        </div>
      </div>
    ) : (
      ""
    )}
    <div class="skills__row">
      <div class="skills__category-name">{skillCategory.name}</div>
      <div class="skills__category-entries">
        {skillCategory.skills.map((skill) => (
          <div class="skills__entry">{skill}</div>
        ))}
      </div>
    </div>
  </div>
);

export const constructSkillsSections = (
  skillCategories: Array<SkillCategoryContent>
) => {
  const sections = skillCategories.map((skillCategory, index) => {
    const headerHeightInPx = index === 0 ? 180 : 0;
    const numberOfEntries = skillCategory.skills.length;
    const entryHeightInPx = 30;
    const contentHeightInPx = numberOfEntries * entryHeightInPx;
    const categoryMarginBottom = 30;
    const rowHeight =
      headerHeightInPx + contentHeightInPx + categoryMarginBottom;

    const preformattedSkillCategory = {
      name: constructTextWithLineBreaks({
        font: fontStyles.subHeadline,
        text: skillCategory.name,
        containerWidth: 275,
        letterSpacing: 4,
      }).htmlCode,
      skills: skillCategory.skills,
    };

    return {
      containerPreference: ["curriculum_vitae"],
      tags: ["cv"],
      height: rowHeight,
      render: () =>
        renderSkills({
          showHeadline: index === 0,
          skillCategory: preformattedSkillCategory,
          height: rowHeight,
          index: index,
        }),
    };
  });

  return sections;
};
