import * as h from "vhtml";

type StaticSVGParams = {
  resource: {
    type: "svg";
    content: string;
  };
};

export const StaticSVG = ({ resource }: StaticSVGParams) => {
  return (h as any)(null, {
    dangerouslySetInnerHTML: {
      __html: resource.content,
    },
  });
};
