import { JobApplication } from "@apply-high/interfaces";
import { createRenderContent } from "./Util/render-content-factory";

export const getPdf = async (
  partner: string,
  hasOptIn: boolean,
  jobApplication: JobApplication
): Promise<Blob> => {
  const content = createRenderContent(jobApplication, true);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/whitelabel/pdf`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ partner, hasOptIn, jobApplication: content }),
    }
  );

  if (response.ok === false) {
    throw new Error();
  }

  return response.blob();
};

export const requestDemo = async (email: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/whitelabel/demo`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    }
  );

  if (response.ok === false) {
    throw new Error();
  }
};
