import { EducationForm as EducationFormFields } from "@apply-high/interfaces";
import { educationEntrySchema } from "@apply-high/schemas";
import { Button, DateRangeInput, Input } from "@apply-high/components";
import React from "react";
import { Formik, FormikHelpers } from "formik";

type EducationFormFieldsWithUntilToday = EducationFormFields & {
  untilToday: boolean;
};

type EducationFormProps = {
  type: "create" | "edit";
  values: EducationFormFieldsWithUntilToday;
  onSubmit(
    values: EducationFormFieldsWithUntilToday,
    actions: FormikHelpers<EducationFormFieldsWithUntilToday>
  ): void;
  onAbort(): void;
};

const EducationForm = ({
  type,
  values,
  onSubmit,
  onAbort,
}: EducationFormProps) => (
  <Formik<EducationFormFieldsWithUntilToday>
    initialValues={values}
    enableReinitialize={true}
    onSubmit={(values, actions) => onSubmit(values, actions)}
    validationSchema={educationEntrySchema("form")}
  >
    {(formikProps) => (
      <form
        noValidate={true}
        onSubmit={formikProps.handleSubmit}
        className="card"
      >
        <div className="card-body">
          <DateRangeInput
            startId="start"
            startValue={formikProps.values.start}
            startTouched={formikProps.touched.start}
            startError={formikProps.errors.start}
            onChangeStart={(startValue) =>
              formikProps.setFieldValue("start", startValue)
            }
            endId="end"
            endValue={formikProps.values.end}
            endTouched={formikProps.touched.end}
            endError={formikProps.errors.end}
            onChangeEnd={(endValue) =>
              formikProps.setFieldValue("end", endValue)
            }
            onBlur={formikProps.handleBlur}
            untilTodayValue={formikProps.values.untilToday}
            onChangeUntilToday={(untilToday) =>
              formikProps.setFieldValue("untilToday", untilToday)
            }
          />
          <Input
            label="Name der Bildungseinrichtung"
            id="institution"
            value={formikProps.values.institution}
            error={formikProps.errors.institution}
            touched={formikProps.touched.institution}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
          <Input
            label="Ort"
            id="location"
            value={formikProps.values.location}
            error={formikProps.errors.location}
            touched={formikProps.touched.location}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
          <Input
            label={
              formikProps.values.untilToday
                ? "Voraussichtlicher Abschluss"
                : "Abschluss"
            }
            id="degree"
            value={formikProps.values.degree}
            error={formikProps.errors.degree}
            touched={formikProps.touched.degree}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            required={false}
          />
          <Input
            label="Beschreibung"
            id="description"
            value={formikProps.values.description}
            error={formikProps.errors.description}
            touched={formikProps.touched.description}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            required={false}
          />
        </div>
        <div className="card-footer d-flex justify-content-end">
          <Button
            variant="easy"
            disabled={formikProps.isSubmitting}
            onClick={onAbort}
          >
            Abbrechen
          </Button>
          <Button
            type="submit"
            variant="strong"
            disabled={formikProps.isSubmitting}
            loading={formikProps.isSubmitting}
          >
            {type === "create" ? "Eintrag speichern" : "Änderungen speichern"}
          </Button>
        </div>
      </form>
    )}
  </Formik>
);

export default EducationForm;
