import * as yup from 'yup';

import {
  letterSchema,
  companySchema,
  personalDataSchema,
  skillCategoriesSchema,
  workExperienceSchema,
  educationSchema,
  feedbackSchema,
} from './forms';

export const getPreviewRequestSchema = yup
  .object()
  .shape({
    query: yup.object().shape({
      type: yup
        .string()
        .test(
          'is-cover-or-cv',
          'Parameter "type" should be one of "cover" or "cv".',
          (typeParam) => typeParam === 'cover' || typeParam === 'cv'
        ),
    }),
  })
  .strict(true);

export const putPortraitRequestSchema = yup
  .object()
  .shape({
    body: yup.object().shape({
      imageId: yup.string().required().strict(true),
      imageType: yup.string().required().strict(true),
      cropX: yup.number().required('Es fehlt die Information: cropX').strict(true),
      cropY: yup.number().required('Es fehlt die Information: cropY').strict(true),
      cropWidth: yup.number().required('Es fehlt die Information: cropWidth').strict(true),
      cropHeight: yup.number().required('Es fehlt die Information: cropHeight').strict(true),
      rotation: yup.number().required('Es fehlt die Information: rotation').strict(true),
    }),
  })
  .strict(true);

export const putCompanyRequestSchema = yup
  .object()
  .shape({
    body: companySchema,
  })
  .strict(true);

export const putLetterRequestSchema = yup
  .object()
  .shape({
    body: letterSchema,
  })
  .strict(true);

export const putPersonalDataRequestSchema = yup
  .object()
  .shape({
    body: personalDataSchema('form'),
  })
  .strict(true);

export const putEducationRequestSchema = yup
  .object()
  .shape({
    body: educationSchema,
  })
  .strict(true);

export const putWorkExperienceRequestSchema = yup
  .object()
  .shape({
    body: workExperienceSchema,
  })
  .strict(true);

export const putSkillCategoriesRequestSchema = yup
  .object()
  .shape({
    body: skillCategoriesSchema('form'),
  })
  .strict(true);

export const putFeedbackRequestSchema = yup
  .object()
  .shape({
    body: feedbackSchema,
  })
  .strict(true);
