export const previewJS = `
(() => {
  const pages = Array.from(document.getElementsByClassName('din-a4-portrait'));
  const PreviewControls = { currentSelection: 0 };

  PreviewControls.show = (index) => {
    const isValidIndex = pages[index] !== undefined;
    const validIndex = isValidIndex ? index : 0;

    PreviewControls.currentSelection = validIndex;
    pages.forEach((page) => (page.style.display = 'none'));
    pages[validIndex].style.display = 'block';
  };

  PreviewControls.pushPageIndicator = (type) => {
    parent.postMessage(
      {
        type: type,
        totalPages: pages.length,
        activePage: PreviewControls.currentSelection + 1,
      },
      document.body.dataset.appUrl
    );
  };

  PreviewControls.showInitialPage = () => {
    const previewTag = window.frameElement.getAttribute('data-preview-tag');

    if (previewTag === null) {
      PreviewControls.show(PreviewControls.currentSelection);
      return;
    }

    const sectionsWithPreviewTag = Array.from(document.body.querySelectorAll('[data-section-name]'));
    const firstSectionWithPreviewTag = sectionsWithPreviewTag.find(
      (section) => section.dataset.sectionName.indexOf(previewTag) !== -1
    );

    if (firstSectionWithPreviewTag === undefined) {
      PreviewControls.show(PreviewControls.currentSelection);
      return;
    }

    const pageIndex = pages.findIndex((page) => page.contains(firstSectionWithPreviewTag));
    PreviewControls.show(pageIndex);
  };

  window.addEventListener('message', (event) => {
    const { data, origin } = event;

    // Expect messages only from same origin
    if (origin !== window.origin) {
      return;
    }

    if (data === 'next') {
      PreviewControls.show(PreviewControls.currentSelection + 1);
    }

    if (data === 'previous') {
      PreviewControls.show(PreviewControls.currentSelection - 1);
    }

    if (data.type === 'goto') {
      PreviewControls.show(data.page - 1);
    }

    PreviewControls.pushPageIndicator('update');
  });

  PreviewControls.showInitialPage();
  PreviewControls.pushPageIndicator('init');
})();
`;
