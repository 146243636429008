import React from "react";
import {
  JobApplication,
  WorkExperienceForm as WorkExperienceFormFields,
} from "@apply-high/interfaces";
import { v4 as uuid } from "uuid";

import { ReactComponent as Plus } from "../assets/svgs/plus-light.svg";
import WorkExperienceForm, {
  WORK_EXPERIENCE_TYPES,
} from "./WorkExperienceForm";
import { formatHeader, sortDescByEndDate } from "../Util/helper";
import { Button, ListEntry } from "@apply-high/components";

type WorkExperienceProps = {
  jobApplication: JobApplication;
  updateJobApplication: (updatedJobApplication: JobApplication) => void;
  onUpdateMode: (isInteracting: boolean) => void;
};

type WorkExperienceState = {
  isCreateFormOpen: boolean;
  editId: string | null;
};

class WorkExperienceEditor extends React.PureComponent<
  WorkExperienceProps,
  WorkExperienceState
> {
  constructor(props: WorkExperienceProps) {
    super(props);

    this.state = {
      isCreateFormOpen: false,
      editId: null,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<WorkExperienceProps>,
    prevState: Readonly<WorkExperienceState>
  ) {
    const { onUpdateMode } = this.props;
    const { isCreateFormOpen, editId } = this.state;
    const isInteracting =
      (prevState.isCreateFormOpen === false && isCreateFormOpen === true) ||
      (prevState.editId === null && editId !== null);
    onUpdateMode(isInteracting);
  }

  renderWorkExperienceEntries = () => {
    const { isCreateFormOpen, editId } = this.state;
    const { jobApplication } = this.props;
    const { workExperience } = jobApplication;

    if (isCreateFormOpen === true || editId !== null) {
      return null;
    }

    if (workExperience.length === 0) {
      return (
        <>
          <div className="text-muted mb-2">
            Du hast noch keinen Eintrag für Berufserfahrung hinzugefügt.
          </div>
          <Button
            variant="outline"
            onClick={() => this.setState({ isCreateFormOpen: true })}
          >
            <Plus className="me-2" />
            Eintrag hinzufügen
          </Button>
        </>
      );
    }

    return workExperience.map((workExperienceEntry) => (
      <ListEntry
        key={workExperienceEntry.id}
        header={formatHeader(workExperienceEntry)}
        subHeader={workExperienceEntry.workplace}
        onEdit={() => this.setState({ editId: workExperienceEntry.id })}
        onRemove={() => this.deleteWorkExperience(workExperienceEntry)}
      />
    ));
  };

  renderCreateForm = () => {
    const { isCreateFormOpen } = this.state;

    if (isCreateFormOpen === false) {
      return null;
    }

    return (
      <WorkExperienceForm
        type="create"
        values={{
          id: uuid(),
          type: WORK_EXPERIENCE_TYPES.EMPLOYED,
          start: "",
          end: "",
          workplace: "",
          employment: "",
          location: "",
          description: ["", "", ""],
          untilToday: false,
        }}
        onSubmit={(workExperience) => this.createWorkExperience(workExperience)}
        onAbort={() => this.setState({ isCreateFormOpen: false })}
      />
    );
  };

  renderEditForm = () => {
    const { editId } = this.state;
    const { jobApplication } = this.props;
    const { workExperience } = jobApplication;
    const workExperienceEntry = workExperience.find(
      (workExperienceEntry) => workExperienceEntry.id === editId
    );

    if (workExperienceEntry === undefined) {
      return null;
    }

    return (
      <WorkExperienceForm
        type="edit"
        values={{
          ...workExperienceEntry,
          untilToday: workExperienceEntry.end === "",
        }}
        onSubmit={(education) => this.editWorkExperience(education)}
        onAbort={() => this.setState({ editId: null })}
      />
    );
  };

  render() {
    const { isCreateFormOpen, editId } = this.state;
    const { jobApplication } = this.props;
    const { workExperience } = jobApplication;
    const hasCreateButton =
      workExperience.length > 0 &&
      isCreateFormOpen === false &&
      editId === null;

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
          <h2 className="h4 m-0">
            Berufserfahrung{" "}
            {isCreateFormOpen
              ? " hinzufügen"
              : editId !== null
              ? " bearbeiten"
              : null}
          </h2>
          <div className={hasCreateButton === true ? "visible" : "invisible"}>
            <div className="d-none d-md-inline-flex">
              <Button
                variant="outline"
                onClick={() => this.setState({ isCreateFormOpen: true })}
                disabled={hasCreateButton === false}
              >
                <Plus className="me-2" />
                Eintrag hinzufügen
              </Button>
            </div>
            <div className="d-md-none d-sm-inline-flex">
              <Button
                circled
                variant="outline"
                onClick={() => this.setState({ isCreateFormOpen: true })}
                disabled={hasCreateButton === false}
              >
                <Plus />
              </Button>
            </div>
          </div>
        </div>
        {this.renderWorkExperienceEntries()}
        {this.renderCreateForm()}
        {this.renderEditForm()}
      </>
    );
  }

  private createWorkExperience = async (
    workExperienceEntry: WorkExperienceFormFields
  ) => {
    const { jobApplication } = this.props;
    const { workExperience } = jobApplication;

    const updatedEntries = [...workExperience, workExperienceEntry];
    const sortedEntries = sortDescByEndDate(updatedEntries);
    await this.saveWorkExperience(sortedEntries);

    this.setState({ isCreateFormOpen: false });
  };

  private editWorkExperience = async (
    updatedWorkExperienceEntry: WorkExperienceFormFields
  ) => {
    const { jobApplication } = this.props;
    const { workExperience } = jobApplication;

    const updatedEntries = workExperience.map((workExperienceEntry) => {
      if (workExperienceEntry.id === updatedWorkExperienceEntry.id) {
        return updatedWorkExperienceEntry;
      }

      return workExperienceEntry;
    });
    const sortedEntries = sortDescByEndDate(updatedEntries);
    await this.saveWorkExperience(sortedEntries);

    this.setState({ editId: null });
  };

  private deleteWorkExperience = async (
    deletedWorkExperienceEntry: WorkExperienceFormFields
  ) => {
    const { jobApplication } = this.props;
    const { workExperience } = jobApplication;

    await this.saveWorkExperience(
      workExperience.filter(
        (workExperienceEntry) =>
          workExperienceEntry.id !== deletedWorkExperienceEntry.id
      )
    );
  };

  private saveWorkExperience = async (
    workExperienceEntries: Array<WorkExperienceFormFields>
  ) => {
    const { jobApplication, updateJobApplication } = this.props;

    updateJobApplication({
      ...jobApplication,
      workExperience: workExperienceEntries,
    });
  };
}

export default WorkExperienceEditor;
