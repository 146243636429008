import * as h from "vhtml";
import { PersonalDataContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import { ConstrainedText } from "../../../utility/index";
import { fontStyles } from "../font_styles";

type RenderPersonalDataParams = Omit<PersonalDataContent, "birthday"> & {
  birthInfo: string;
};

const renderPersonalData = ({
  firstName,
  lastName,
  street,
  streetNumber,
  postcode,
  city,
  telephone,
  email,
  birthInfo,
}: RenderPersonalDataParams) => (
  <div data-section-name="personal-data" class="personal-data">
    <div class="personal-data__name">
      <ConstrainedText
        text={firstName}
        maxWidthInPx={250}
        fontSizeInPx={70}
        fontFamily={fontStyles.autoFittedName.family}
      />
      <ConstrainedText
        text={lastName}
        maxWidthInPx={250}
        fontSizeInPx={70}
        fontFamily={fontStyles.autoFittedName.family}
      />
    </div>
    <div class="personal-data__info">
      <div class="personal-data__birthday">{birthInfo}</div>
      <br />
      <div class="personal-data__street">
        {street} {streetNumber}
      </div>
      <div class="personal-data__city">
        {postcode} {city}
      </div>
      <div class="personal-data__phone">{telephone}</div>
      <div class="personal-data__email">{email}</div>
    </div>
  </div>
);

export const constructPersonalDataSection = (
  personalData: PersonalDataContent
) => ({
  containerPreference: ["personal-data"],
  tags: ["head", "cv"],
  height: 380,
  render: () => {
    const birthday =
      personalData.birthday === undefined
        ? undefined
        : format(personalData.birthday, "dd.MM.yyyy");

    const birthInfo = [birthday, personalData.birthplace]
      .filter((x) => x !== undefined)
      .join("/");
    return renderPersonalData({
      ...personalData,
      birthInfo,
    });
  },
});
