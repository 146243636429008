import * as h from "vhtml";
import { EducationContent } from "@apply-high/interfaces";
import { format, getYear, compareDesc } from "date-fns";

import { constructTextWithLineBreaks } from "../../../utility/index";
import { fontStyles } from "../font_styles";

type RenderEducationSectionParams = {
  index: number;
  height: number;
  position: string;
  showHeadline: boolean;
  yearHeadline?: string;
  isSingleEntry: boolean;
  timeSpan: string;
  institution: string;
  degree?: string;
  description?: string;
};

const renderEducationSection = ({
  index,
  height,
  position,
  showHeadline,
  yearHeadline,
  isSingleEntry,
  timeSpan,
  institution,
  degree,
  description,
}: RenderEducationSectionParams) => (
  <div
    class="timeline"
    data-section-name={`education__row:${index}`}
    data-assumed-height={height}
  >
    {showHeadline === true ? (
      <div class="education__headline">Bildungsweg</div>
    ) : (
      ""
    )}
    {yearHeadline !== undefined ? (
      <div class="timeline__year-headline">{yearHeadline}</div>
    ) : (
      ""
    )}
    <div
      class={`timeline__item timeline__item--${position} ${
        isSingleEntry === true ? "timeline__item--single-entry" : ""
      }`}
    >
      {isSingleEntry === false ? <div class="timeline__line" /> : ""}
      <div class="timeline__time-span">{timeSpan}</div>
      <div class="education__institution">{institution}</div>
      <div class="education__degree">{degree}</div>
      {description !== undefined ? <div>{description}</div> : ""}
    </div>
  </div>
);

export const constructEducationSections = (
  educationItems: Array<EducationContent>
) => {
  const isSingleEntry = educationItems.length === 1;
  const normaliseDate = (date?: Date) =>
    date === undefined ? new Date() : date;

  type Position = "left" | "right";
  type PreformattedEducationEntry = {
    yearHeadline?: string;
    position: Position;
    timeSpan: string;
    institution: string;
    degree?: string;
    description?: string;
    height: number;
  };

  let leftOrRightToggle: Position = "left";

  const alreadyHandledYearHeadlines: Array<string> = [];

  const preformattedEntries: Array<PreformattedEducationEntry> = educationItems
    .sort((a, b) => compareDesc(normaliseDate(a.end), normaliseDate(b.end)))
    .map((educationItem, index) => {
      const yearHeadline =
        educationItem.end === undefined
          ? "Heute"
          : getYear(educationItem.end).toString();

      const isTopEntryOfYear =
        alreadyHandledYearHeadlines.includes(yearHeadline) === false;
      if (isTopEntryOfYear === true) {
        alreadyHandledYearHeadlines.push(yearHeadline);
      }
      const currentPosition = leftOrRightToggle;
      leftOrRightToggle = leftOrRightToggle === "left" ? "right" : "left";

      const headlineHeight = index === 0 ? 104 : 0;
      const singleEntryExtraMargin = isSingleEntry === true ? 20 : 0;
      const yearHeadlineHeight =
        isTopEntryOfYear === true && isSingleEntry === false ? 52 : 0;
      const timeSpanHeight = 30;
      const degreeHeight = 17;

      let descriptionHeight = 0;
      let descriptionHtmlCode;
      if (educationItem.description !== undefined) {
        const descriptionText = constructTextWithLineBreaks({
          text: educationItem.description,
          font: fontStyles.baseRegular,
          containerWidth: 275,
        });
        descriptionHeight = descriptionText.height;
        descriptionHtmlCode = descriptionText.htmlCode;
      }

      const institutionText = constructTextWithLineBreaks({
        text: `${educationItem.institution}, ${educationItem.location}`,
        font: fontStyles.subHeadline,
        containerWidth: 275,
      });

      const rowHeight =
        headlineHeight +
        singleEntryExtraMargin +
        yearHeadlineHeight +
        timeSpanHeight +
        institutionText.height +
        degreeHeight +
        descriptionHeight;

      const endDate =
        educationItem.end === undefined
          ? "Heute"
          : format(educationItem.end, "MM/yyyy");

      return {
        yearHeadline: isTopEntryOfYear === true ? yearHeadline : undefined,
        position: currentPosition,
        timeSpan: `${format(educationItem.start, "MM/yyyy")} - ${endDate}`,
        institution: institutionText.htmlCode,
        degree: educationItem.degree,
        description: descriptionHtmlCode,
        height: rowHeight,
      };
    });

  const sections = preformattedEntries.map((education, index) => ({
    containerPreference: ["personal_data_rest", "curriculum_vitae"],
    tags: ["cv"],
    height: education.height,
    render: () =>
      renderEducationSection({
        showHeadline: index === 0,
        isSingleEntry: isSingleEntry,
        position: education.position,
        yearHeadline: isSingleEntry ? undefined : education.yearHeadline,
        timeSpan: education.timeSpan,
        institution: education.institution,
        degree: education.degree,
        description: education.description,
        index: index,
        height: education.height,
      }),
  }));

  return sections;
};
