import React from "react";
import { Formik, FormikHelpers } from "formik";
import { WorkExperienceForm as WorkExperienceFormFields } from "@apply-high/interfaces";
import { Button, DateRangeInput, Input } from "@apply-high/components";
import { workExperienceEntrySchema } from "@apply-high/schemas";
import DescriptionEditor from "./DescriptionEditor";

export enum WORK_EXPERIENCE_TYPES {
  EMPLOYED = "employed",
  INTERNSHIP = "internship",
  VOLUNTARY = "voluntary",
  SELF_EMPLOYED = "self_employed",
  WORKING_STUDENT = "working_student",
  APPRENTICESHIP = "apprenticeship",
}

// Ordered alphabetically
const WORK_EXPERIENCE_TYPES_MAP = {
  [WORK_EXPERIENCE_TYPES.EMPLOYED]: "Angestellt",
  [WORK_EXPERIENCE_TYPES.APPRENTICESHIP]: "Ausbildung",
  [WORK_EXPERIENCE_TYPES.VOLUNTARY]: "Ehrenamtlich",
  [WORK_EXPERIENCE_TYPES.INTERNSHIP]: "Praktikum",
  [WORK_EXPERIENCE_TYPES.SELF_EMPLOYED]: "Selbstständig",
  [WORK_EXPERIENCE_TYPES.WORKING_STUDENT]: "Werkstudent",
};

export type WorkExperienceFormFieldsWithUntilToday =
  WorkExperienceFormFields & {
    untilToday: boolean;
  };

type WorkExperienceFormProps = {
  type: "create" | "edit";
  values: WorkExperienceFormFieldsWithUntilToday;
  onSubmit(
    values: WorkExperienceFormFieldsWithUntilToday,
    actions: FormikHelpers<WorkExperienceFormFieldsWithUntilToday>
  ): void;
  onAbort(): void;
};

const WorkExperienceForm = ({
  type,
  values,
  onSubmit,
  onAbort,
}: WorkExperienceFormProps) => (
  <Formik<WorkExperienceFormFieldsWithUntilToday>
    initialValues={values}
    enableReinitialize={true}
    onSubmit={(values, actions) => onSubmit(values, actions)}
    validationSchema={workExperienceEntrySchema("form")}
  >
    {(formikProps) => (
      <form
        noValidate={true}
        onSubmit={formikProps.handleSubmit}
        className="card"
      >
        <div className="card-body">
          <DateRangeInput
            startId="start"
            startValue={formikProps.values.start}
            startTouched={formikProps.touched.start}
            startError={formikProps.errors.start}
            onChangeStart={(startValue) =>
              formikProps.setFieldValue("start", startValue)
            }
            endId="end"
            endValue={formikProps.values.end}
            endTouched={formikProps.touched.end}
            endError={formikProps.errors.end}
            onChangeEnd={(endValue) =>
              formikProps.setFieldValue("end", endValue)
            }
            onBlur={formikProps.handleBlur}
            untilTodayValue={formikProps.values.untilToday}
            onChangeUntilToday={(untilToday) =>
              formikProps.setFieldValue("untilToday", untilToday)
            }
          />
          <div className="form-floating mb-2">
            <select
              name="type"
              id="type"
              className="form-select"
              value={formikProps.values.type}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            >
              {Object.keys(WORK_EXPERIENCE_TYPES_MAP).map((type) => (
                <option key={type} value={type as string}>
                  {WORK_EXPERIENCE_TYPES_MAP[type]}
                </option>
              ))}
            </select>
            <label htmlFor="type">Beschäftigungsart</label>
          </div>
          <Input
            label="Unternehmen"
            id="workplace"
            value={formikProps.values.workplace}
            error={formikProps.errors.workplace}
            touched={formikProps.touched.workplace}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
          <Input
            label="Ort"
            id="location"
            value={formikProps.values.location}
            error={formikProps.errors.location}
            touched={formikProps.touched.location}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
          <Input
            label="Tätigkeit"
            id="employment"
            value={formikProps.values.employment}
            error={formikProps.errors.employment}
            touched={formikProps.touched.employment}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
          <div className="mt-3">
            <DescriptionEditor formikProps={formikProps} />
          </div>
        </div>
        <div className="card-footer d-flex justify-content-end">
          <Button
            variant="easy"
            disabled={formikProps.isSubmitting}
            onClick={onAbort}
          >
            Abbrechen
          </Button>
          <Button
            type="submit"
            variant="strong"
            disabled={formikProps.isSubmitting}
            loading={formikProps.isSubmitting}
          >
            {type === "create" ? "Eintrag speichern" : "Änderungen speichern"}
          </Button>
        </div>
      </form>
    )}
  </Formik>
);

export default WorkExperienceForm;
