import * as h from "vhtml";

import { PersonalDataContent, LanguageContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import { fontStyles } from "../font_styles";
import { mapLanguageLevelToPercentage } from "../../../utility/language";
import {
  CroppedImage,
  DIN_A4_HEIGHT_IN_PX,
  Fragment,
  MultilineAutoFittedText,
} from "../../../utility";

type RenderPersonalDataParams = Omit<PersonalDataContent, "birthday"> & {
  birthday?: string;
  languages: Array<{
    name: string;
    level: number;
  }>;
  coreCompetencies: Array<string>;
};

const renderPersonalData = ({
  cvImage,
  firstName,
  lastName,
  street,
  streetNumber,
  postcode,
  city,
  telephone,
  email,
  birthplace,
  birthday,
  languages,
  coreCompetencies,
}: RenderPersonalDataParams) => (
  <Fragment>
    {cvImage !== undefined ? (
      <div class="personal-data__image">
        <CroppedImage portraitContent={cvImage} containerWidth={228} />
      </div>
    ) : (
      ""
    )}
    <div class="personal-data__entry">
      <MultilineAutoFittedText
        lines={[firstName ?? "", lastName ?? ""]}
        fontFamily={fontStyles.autoFittedName.family}
        widthInPx={228}
        maxHeightInPx={300}
      />
    </div>
    {telephone !== undefined ? (
      <div class="personal-data__entry">
        <div class="personal-data__headline">Telefon</div>
        {telephone}
      </div>
    ) : (
      ""
    )}
    <div class="personal-data__entry">
      <div class="personal-data__headline">E-Mail</div>
      {email}
    </div>
    <div class="personal-data__entry">
      <div class="personal-data__headline">Adresse</div>
      {street} {streetNumber}
      <br />
      {postcode} {city}
    </div>
    <div class="personal-data__entry">
      <div class="personal-data__headline">Geburtsdatum & Ort</div>
      {birthday}
      <br />
      {birthplace}
    </div>
    {languages.length > 0 ? (
      <div className="personal-data__entry">
        <div className="personal-data__headline">Sprache</div>
        {languages.map((language) => (
          <div className="language">
            <div className="language__name">{language.name}</div>
            <div className="language__bar">
              <div
                className="language__level"
                style={`width: ${language.level}%`}
              />
            </div>
          </div>
        ))}
      </div>
    ) : (
      ""
    )}
    {coreCompetencies.length > 0 ? (
      <div>
        <div className="personal-data__headline">Kernkompetenzen</div>
        <ul className="personal-data__core-competencies">
          {coreCompetencies.map((coreCompetency) => (
            <li className="personal-data__core-competency-entry">
              {coreCompetency}
            </li>
          ))}
        </ul>
      </div>
    ) : (
      ""
    )}
  </Fragment>
);

export const constructPersonalDataSection = (
  personalData: PersonalDataContent,
  languages: Array<LanguageContent>,
  coreCompetencies: Array<string>
) => {
  return {
    containerPreference: ["split__left"],
    height: DIN_A4_HEIGHT_IN_PX,
    tags: ["head", "cv"],
    render: () =>
      renderPersonalData({
        ...personalData,
        birthday:
          personalData.birthday === undefined
            ? undefined
            : format(personalData.birthday, "dd.MM.yyyy"),
        languages: languages.map((language) => ({
          name: language.name,
          level: mapLanguageLevelToPercentage(language),
        })),
        coreCompetencies,
      }),
  };
};
