import React from "react";
import { ArrayHelpers, FieldArray, FormikProps } from "formik";

import { ReactComponent as Trash } from "../assets/svgs/trash-alt-light.svg";
import { ReactComponent as Plus } from "../assets/svgs/plus-light.svg";
import { WorkExperienceFormFieldsWithUntilToday } from "./WorkExperienceForm";
import { Button, Input, TextArea } from "@apply-high/components";

type DescriptionEditorProps = {
  formikProps: FormikProps<WorkExperienceFormFieldsWithUntilToday>;
};

const DescriptionEditor: React.FC<DescriptionEditorProps> = ({
  formikProps,
}) => {
  const addDescriptionEntry = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push("");
  };

  const removeDescriptionEntry = (
    arrayHelpers: ArrayHelpers,
    index: number
  ) => {
    arrayHelpers.remove(index);
  };

  if (Array.isArray(formikProps.values.description)) {
    return (
      <FieldArray
        name="description"
        render={(arrayHelpers) => (
          <>
            <h3 className="h6 mb-0">Tätigkeitsbeschreibung</h3>
            <p className="text-muted mb-2">
              Beschreibe Deine Tätigkeit in Stichpunkten.
            </p>
            <div className="vstack gap-2 mb-2" role="list">
              {(formikProps.values.description as string[]).map(
                (descriptionEntry, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center"
                    role="listitem"
                  >
                    <Input
                      id={`description[${index}]`}
                      value={formikProps.values.description[index]}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      padded={false}
                    />
                    <Button
                      circled
                      variant="easy"
                      title="Entfernen"
                      tabIndex={-1}
                      onClick={() =>
                        removeDescriptionEntry(arrayHelpers, index)
                      }
                      disabled={formikProps.values.description.length === 1}
                    >
                      <Trash width={16} />
                    </Button>
                  </div>
                )
              )}
            </div>
            <Button
              size="small"
              variant="outline"
              onClick={() => addDescriptionEntry(arrayHelpers)}
            >
              <Plus className="me-2" />
              Stichpunkt hinzufügen
            </Button>
          </>
        )}
      />
    );
  }

  return (
    <TextArea
      label="Beschreibung"
      id="description"
      heightInPx={200}
      value={formikProps.values.description}
      error={formikProps.errors.description}
      touched={formikProps.touched.description}
      onChange={formikProps.handleChange}
      onBlur={formikProps.handleBlur}
      required={false}
    />
  );
};

export default DescriptionEditor;
