import * as h from "vhtml";
import { WorkExperienceContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import { fontStyles } from "../font_styles";
import {
  constructTextWithLineBreaks,
  Fragment,
  getTranslatedWorkExperienceType,
} from "../../../utility";

const headlineHeight = 68;
const employmentHeight = fontStyles.baseRegular.sizeInPx;
const entryMarginBottom = 20;
const sectionMarginBottom = 20;

export type PreformattedWorkExperienceContent = WorkExperienceContent & {
  descriptionEntries: Array<string>;
  subHeadlineHtml: string;
  entryHeight: number;
};

type DescriptionParams = {
  descriptionEntries: Array<string>;
};

const Description = ({ descriptionEntries }: DescriptionParams): string => {
  if (descriptionEntries.length === 0) {
    return "";
  }

  if (descriptionEntries.length === 1) {
    return <div class="timeline__description">{descriptionEntries[0]}</div>;
  }

  return (
    <div class="timeline__description">
      <ul class="timeline__description-list">
        {descriptionEntries.map((entry) => (
          <li>{entry}</li>
        ))}
      </ul>
    </div>
  );
};

type RenderWorkExperienceParams = {
  index: number;
  showHeadline: boolean;
  height: number;
  workExperiences: Array<{
    start: string;
    end: string;
    workplace: string;
    location: string;
    employment: string;
    descriptionEntries: Array<string>;
    type: string;
  }>;
};
const renderWorkExperience = ({
  index,
  showHeadline,
  height,
  workExperiences,
}: RenderWorkExperienceParams) => (
  <div
    data-section-name={`work-experience:${index}`}
    data-assumed-height={height}
  >
    {showHeadline === true ? <div class="headline">Berufserfahrung</div> : ""}
    <div class="timeline">
      {workExperiences.map(
        ({
          start,
          end,
          workplace,
          location,
          type,
          employment,
          descriptionEntries,
        }) => (
          <Fragment>
            <div class="timeline__left">
              {start} - {end}
            </div>
            <div class="timeline__right">
              <div class="timeline__headline">
                {workplace}, {location}
              </div>
              <div class="timeline__subtitle">
                {type}, {employment}
              </div>
              <Description descriptionEntries={descriptionEntries} />
            </div>
          </Fragment>
        )
      )}
    </div>
  </div>
);

/*
   XX Berufserfahrung                                                           <-- headlineHeight

   Heute062019 |       Steuerberater van Heiden, Oberhausen                     <-- subHeadlineHeight
   08/2018     o       Praktikum Steuerfachangestellte                          <-- employmentHeight
   |       Beschäftigung mit Buchführung, betriebswirtschaftlichen  <-- descriptionHeight
   |       Auswertungen und Jahresabschlüßen
   |                                                                <-- entryMarginBottom

   <-- sectionMarginBottom
   XX Sprachen
   ..
 */
export const constructWorkExperienceSection = (
  workExperiences: Array<PreformattedWorkExperienceContent>,
  index: number
) => {
  const sectionHeight =
    (index === 0 ? headlineHeight : 0) +
    workExperiences.reduce(
      (totalHeight, entry) => totalHeight + entry.entryHeight,
      0
    ) +
    sectionMarginBottom;
  return {
    isVisible: workExperiences.length !== 0,
    containerPreference: ["split__right", "standard"],
    tags: ["cv"],
    height: sectionHeight,
    render: () =>
      renderWorkExperience({
        workExperiences: workExperiences.map((workExperience) => ({
          start: format(workExperience.start, "MM/yyyy"),
          end:
            workExperience.end === undefined
              ? "Heute"
              : format(workExperience.end, "MM/yyyy"),
          workplace: workExperience.workplace,
          location: workExperience.location,
          employment: workExperience.employment,
          descriptionEntries: workExperience.descriptionEntries,
          type: getTranslatedWorkExperienceType(workExperience.type),
        })),
        index: index,
        showHeadline: index === 0,
        height: sectionHeight,
      }),
  };
};

export const preformatWorkExperienceEntries = (
  workExperiences: Array<WorkExperienceContent>
): Array<PreformattedWorkExperienceContent> => {
  return workExperiences.map((workExperienceEntry, index) => {
    // last entry has no bottom margin in grid
    const actualEntryMarginBottom =
      index === workExperiences.length - 1 ? 0 : entryMarginBottom;
    const subHeadlineText = constructTextWithLineBreaks({
      text: `${workExperienceEntry.workplace}, ${workExperienceEntry.location}`,
      font: fontStyles.baseRegular,
      containerWidth: 300,
    });

    let descriptionEntries: Array<string> = [];
    if (Array.isArray(workExperienceEntry.description)) {
      descriptionEntries = workExperienceEntry.description;
    } else if (typeof workExperienceEntry.description === "string") {
      descriptionEntries = [workExperienceEntry.description];
    }

    const preformattedDescriptionEntries = descriptionEntries.map((entry) =>
      constructTextWithLineBreaks({
        text: entry,
        font: fontStyles.baseRegular,
        containerWidth: 300,
        includeExplicitNewLines: true,
      })
    );

    const descriptionHeight = preformattedDescriptionEntries.reduce(
      (totalHeight, entry) => totalHeight + entry.height,
      0
    );

    const entryHeight =
      employmentHeight +
      descriptionHeight +
      actualEntryMarginBottom +
      subHeadlineText.height;

    return {
      ...workExperienceEntry,
      subHeadlineHtml: subHeadlineText.htmlCode,
      descriptionEntries: preformattedDescriptionEntries.map(
        ({ htmlCode }) => htmlCode
      ),
      entryHeight: entryHeight,
    };
  });
};
