import { ReactComponent as Logo } from "./assets/logo.svg";
import React from "react";

export const SplashScreen = () => (
  <div
    style={{
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Logo height={80} />
  </div>
);
