import { winstonSettings } from "./winston";
import { ThemeConfigurationConstructor } from "@apply-high/interfaces";
import { ThemeSettings } from "../interfaces";
import { zuseSettings } from "./zuse";
import { turingSettings } from "./turing";
import { abelsonSettings } from "./abelson";
import { lovelaceSettings } from "./lovelace";

export const themeConfigurationConstructors: Record<string, ThemeSettings> = {
  abelson: abelsonSettings,
  lovelace: lovelaceSettings,
  turing: turingSettings,
  winston: winstonSettings,
  zuse: zuseSettings,
};

export const getThemeConstructor = (
  themeSlug: string
): ThemeConfigurationConstructor => {
  return themeConfigurationConstructors[themeSlug]
    .themeConfigurationConstructor;
};

export const getThemeSettings = (themeSlug: string) => {
  return themeConfigurationConstructors[themeSlug].staticSettings;
};
