import ImageCropper, { ImageCropData } from "./ImageCropper";
import ImageDragAndDrop from "./ImageDragAndDrop";
import React, { useEffect, useState } from "react";
import { JobApplication } from "@apply-high/interfaces";

const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject();
    reader.readAsDataURL(blob);
  });
};

const base64toFile = async (
  url: string,
  filename: string,
  mimeType: string
) => {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();

  return new File([buffer], filename, { type: mimeType });
};

type ImageEditorProps = {
  jobApplication: JobApplication;
  updateJobApplication: (updatedJobApplication: JobApplication) => void;
};

const ImageEditor: React.FC<ImageEditorProps> = ({
  jobApplication,
  updateJobApplication,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [isInitialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const initialize = async () => {
      if (jobApplication.image === null) {
        setInitialized(true);
        return;
      }

      const existingFile = await base64toFile(
        jobApplication.image.imagePath,
        "Bild.jpeg",
        "image/jpeg"
      );
      setFile(existingFile);
      setInitialized(true);
    };
    initialize();
  }, []);

  const updateImage = async (imageCropData: ImageCropData) => {
    const { data, ...rest } = imageCropData;
    const imageAsBase64 = await blobToBase64(data);

    updateJobApplication({
      ...jobApplication,
      image: {
        ...rest,
        imagePath: imageAsBase64,
      },
    });
  };

  const resetImage = () => {
    setFile(null);

    updateJobApplication({
      ...jobApplication,
      image: null,
    });
  };

  if (isInitialized === false) {
    return (
      <div className="placeholder-wave">
        <div className="placeholder w-100" style={{ height: 300 }}></div>
      </div>
    );
  }

  if (file !== null) {
    return (
      <ImageCropper
        file={file}
        cropData={
          jobApplication.image !== null
            ? { ...jobApplication.image, id: "unused", imageId: "unused" }
            : undefined
        }
        widthToLengthRatio={1}
        onChange={updateImage}
        onAbort={resetImage}
      />
    );
  }

  return <ImageDragAndDrop onFileDrop={setFile} />;
};

export default ImageEditor;
