import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Progress,
  Preview,
  PreviewSplitContainer,
} from "@apply-high/components";
import { ReactComponent as User } from "../assets/svgs/user-light.svg";
import { ReactComponent as GraduationCap } from "../assets/svgs/graduation-cap-light.svg";
import { ReactComponent as Briefcase } from "../assets/svgs/briefcase-light.svg";
import { ReactComponent as Chart } from "../assets/svgs/chart-network-light.svg";
import { Steps } from "../Steps/Steps";
import { SplashScreen } from "../SplashScreen";
import { INDUSTRIES } from "../Util/skills";

const usePreviewTag = () => {
  const location = useLocation();

  for (const previewTag of Object.values(Steps)) {
    if (
      location.pathname.includes(previewTag) &&
      previewTag !== Steps.DOWNLOAD
    ) {
      return previewTag;
    }
  }
};

const usePartnerStyles = (partner) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  React.useLayoutEffect(() => {
    const loadPartnerStylesheet = async () => {
      await import(`../styles/${partner}/styles.scss`);
      setIsLoaded(true);
    };
    loadPartnerStylesheet();
  }, [partner]);

  return isLoaded;
};

const useSteps = () => {
  const location = useLocation();

  return {
    personalData: location.pathname.includes(Steps.PERSONAL_DATA),
    workExperience: location.pathname.includes(Steps.WORK_EXPERIENCE),
    education: location.pathname.includes(Steps.EDUCATION),
    skills: location.pathname.includes(Steps.SKILLS),
    download: location.pathname.includes(Steps.DOWNLOAD),
  };
};

type LayoutProps = {
  partner: string;
  industry: string;
  onIndustryChange: (industry: string) => void;
  html: string;
};

const Layout: React.FC<LayoutProps> = ({
  partner,
  industry,
  onIndustryChange,
  html,
}) => {
  const navigate = useNavigate();
  const isLoaded = usePartnerStyles(partner);
  const steps = useSteps();
  const previewTag = usePreviewTag();

  if (isLoaded === false) {
    return <SplashScreen />;
  }

  return (
    <PreviewSplitContainer
      left={
        <>
          <div className="row align-items-center mb-3">
            <div className="col-12 col-md">
              <h1>
                {steps.download === true
                  ? "Lebenslauf herunterladen"
                  : "Lebenslauf erstellen"}
              </h1>
            </div>
            <div className="col-12 col-md-auto">
              <select
                name="industry"
                id="industry"
                className="form-select form-select-sm"
                value={industry}
                onChange={(event) => onIndustryChange(event.target.value)}
              >
                <option value="unknown">Wähle Deine Branche</option>
                {Object.keys(INDUSTRIES).map((industry) => (
                  <option key={industry} value={industry}>
                    {INDUSTRIES[industry]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {steps.download === false && (
            <div className="mb-5">
              <Progress>
                <Progress.Step
                  text="Persönliche Daten"
                  icon={<User />}
                  onClick={() => navigate(Steps.PERSONAL_DATA)}
                  isActive={steps.personalData}
                  isDone={!steps.personalData}
                />
                <Progress.Divider isActive={!steps.personalData} />
                <Progress.Step
                  text="Berufserfahrung"
                  icon={<Briefcase />}
                  onClick={() => navigate(Steps.WORK_EXPERIENCE)}
                  isActive={steps.workExperience}
                  isDone={steps.education || steps.skills}
                />
                <Progress.Divider isActive={steps.education || steps.skills} />
                <Progress.Step
                  text="Bildungsweg"
                  icon={<GraduationCap />}
                  onClick={() => navigate(Steps.EDUCATION)}
                  isActive={steps.education}
                  isDone={steps.skills}
                />
                <Progress.Divider isActive={steps.skills} />
                <Progress.Step
                  text="Fähigkeiten"
                  icon={<Chart />}
                  onClick={() => navigate(Steps.SKILLS)}
                  isActive={steps.skills}
                  isDone={false}
                />
              </Progress>
            </div>
          )}
          <Outlet />
          <small className="d-block mt-5 text-muted text-center">
            Ein Service von{" "}
            <a
              href={`https://www.apply-high.de?utm_source=whitelabel&utm_medium=footer&utm_campaign=apply-high&utm_content=${partner}`}
              rel="noopener noreferrer"
              target="_blank"
              // Forces consistent brand color and link styling
              style={{ color: "#5bbebf", textDecoration: "none" }}
            >
              ApplyHigh
            </a>
          </small>
        </>
      }
      right={<Preview html={html} previewTag={previewTag} />}
    />
  );
};

export default Layout;
