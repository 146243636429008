import { CssFontStyles, convertFontStylesToCss } from "../../utility/index";

export const fontStyles = {
  autoFittedName: {
    sizeInPx: 0, // auto calculated by container width
    family: "Lato-Regular",
  },
  baseLight: {
    sizeInPx: 16,
    family: "Lato-Light",
  },
  baseRegular: {
    sizeInPx: 16,
    family: "Lato-Regular",
  },
  baseBold: {
    sizeInPx: 16,
    family: "Lato-Bold",
  },
} as const;

export const cssFontStyles: CssFontStyles = convertFontStylesToCss(fontStyles);
