import { ThemeConfigurationConstructor } from "@apply-high/interfaces";
import * as chroma from "chroma-js";

import { optimizeForegroundColorContrast } from "../../utility/index";
import { cssFontStyles } from "./font_styles";
import { constructCoverSection } from "./sections/cover.section";
import { constructLetterSections } from "./sections/letter.section";
import { constructPersonalDataSection } from "./sections/personal_data.section";
import { constructEducationSections } from "./sections/education.section";
import { constructWorkExperiencesSections } from "./sections/work_experience.section";
import { constructSkillsSections } from "./sections/skills.section";
import {
  coverLayout,
  personalDataLayout,
  cvLayout,
  letterLayout,
} from "./layouts/index";
import { ThemeSettings } from "../../interfaces";
import { staticResources } from "./static";

export const Lovelace: ThemeConfigurationConstructor = (
  content,
  colorScheme
) => {
  const { primaryColor, secondaryColor } = colorScheme;

  const colorMap: {
    [color: string]: string;
  } = {
    blue: "#edf4f7",
    grey: "#e7eaef",
    orange: "#fffff5",
    pink: "#f8edef",
    beige: "#f9f7f1",
  };

  return {
    globalParameters: {
      primaryColor__extremelyBright__desaturated: chroma(primaryColor)
        .luminance(0.95)
        .desaturate(0.1)
        .hex(),
      primaryColor__brighter__desaturated: chroma(primaryColor)
        .luminance(0.85)
        .desaturate(0.1)
        .hex(),
      primaryColor: primaryColor,
      primaryColorTransparent: chroma(primaryColor).alpha(0.77).hex(),
      secondaryColorBrighten: chroma(secondaryColor).brighten().hex(),
      foregroundContrastColor: optimizeForegroundColorContrast(
        secondaryColor,
        primaryColor
      ),
      darkenedPrimaryColor: chroma(primaryColor).darken().hex(),
      primaryColorBrighten: colorMap[colorScheme.id],
      ...cssFontStyles,
    },
    css: ["index.css"],
    cssResources: [staticResources["index.css"].content],
    fontResources: [
      {
        fontFamily: "DancingScript-Regular",
        resource: staticResources["fonts/DancingScript-Regular.woff2"],
      },
      {
        fontFamily: "Lato-Regular",
        resource: staticResources["fonts/LatoLatin-Regular.woff2"],
      },
      {
        fontFamily: "Lato-Light",
        resource: staticResources["fonts/LatoLatin-Light.woff2"],
      },
      {
        fontFamily: "Lato-Bold",
        resource: staticResources["fonts/LatoLatin-Bold.woff2"],
      },
    ],
    pageLayouts: [coverLayout, letterLayout, personalDataLayout, cvLayout],
    sections: [
      constructCoverSection(content.personalData),
      ...constructLetterSections(content),
      constructPersonalDataSection(content.personalData),
      ...constructWorkExperiencesSections(content.workExperiences),
      ...constructEducationSections(content.education),
      ...constructSkillsSections(content.languages, content.skills),
    ],
  };
};

export const lovelaceSettings: ThemeSettings = {
  themeConfigurationConstructor: Lovelace,
  staticSettings: {
    portraits: [
      {
        id: "cover",
        widthToLengthRatio: 0.75,
      },
      {
        id: "cv",
        widthToLengthRatio: 0.77,
      },
    ],
    colors: [
      {
        id: "blue",
        primaryColor: "#42609b",
        secondaryColor: "#568288",
      },
      {
        id: "grey",
        primaryColor: "#758aa3",
        secondaryColor: "#928fa3",
      },
      {
        id: "orange",
        primaryColor: "#cc6b3e",
        secondaryColor: "#b28567",
      },
      {
        id: "pink",
        primaryColor: "#c97383",
        secondaryColor: "#998094",
      },
      {
        id: "beige",
        primaryColor: "#c1ac71",
        secondaryColor: "#a1a59c",
      },
    ],
  },
};
