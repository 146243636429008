import * as yup from 'yup';

export const billingAddressSchema = yup.object().shape({
  firstName: yup.string().required('Gib Deinen Vornamen an.').strict(true),
  lastName: yup.string().required('Gib Deinen Nachnamen an.').strict(true),
  postcode: yup
    .string()
    .min(4, 'Die Postleitzahl sollte aus mindestens vier Zeichen bestehen.')
    .max(5, 'Die Postleitzahl darf maximal aus fünf Zeichen bestehen.')
    .required('Gib eine gültige Postleitzahl an.')
    .strict(true),
  city: yup.string().required('Gib Deinen aktuellen Wohnort an.').strict(true),
  street: yup.string().required('Gib den Namen Deiner Straße an.').strict(true),
  streetNumber: yup.string().required('Gib Deine Hausnummer an.').strict(true),
  email: yup
    .string()
    .email('Gib eine gültige E-Mail Adresse an.')
    .required('Gib Deine E-Mail Adresse an.')
    .strict(true),
  phone: yup.string().nullable().strict(true),
  acceptedTermsOfBusiness: yup.boolean().oneOf([true], 'Bitte akzeptiere unsere Datenschutzerklärung.').strict(true),
  acceptedRightOfWithdrawal: yup
    .boolean()
    .oneOf([true], 'Bitte bestätige, dass du das Widerrufsrecht gelesen hast.')
    .strict(true),
});

export const paymentDataSchema = yup
  .object()
  .shape({
    couponCode: yup.string().nullable().strict(true),
    data: yup
      .object()
      .shape({
        orderID: yup.string().required('Property orderID is required when paying with PayPal.'),
      })
      .nullable()
      .strict(true),
  })
  .strict(true);

export const couponSchema = yup
  .object()
  .shape({
    code: yup.string().required('Bitte gib einen Gutschein-Code an.').strict(true),
  })
  .strict(true);
