import * as yup from 'yup';

import {
  registrationSchema,
  loginSchema,
  updateUserSchema,
  updatePasswordSchema,
  requestPasswordRecoverySchema,
  recoverPasswordSchema,
} from './forms';

export const postGoogleLoginRequestSchema = yup
  .object()
  .shape({
    body: yup
      .object()
      .shape({
        tokenId: yup.string().required('Gib eine Google OpenID tokenId an.').strict(true),
      })
      .strict(true),
  })
  .strict(true);

export const postLoginRequestSchema = yup
  .object()
  .shape({
    body: loginSchema,
  })
  .strict(true);

export const postRegistrationRequestSchema = yup
  .object()
  .shape({
    body: registrationSchema,
  })
  .strict(true);

export const putUpdateUserRequestSchema = yup
  .object()
  .shape({
    body: updateUserSchema,
  })
  .strict(true);

export const putUpdatePasswordRequestSchema = yup
  .object()
  .shape({
    body: updatePasswordSchema,
  })
  .strict(true);

export const postRequestPasswordRecoveryRequestSchema = yup
  .object()
  .shape({
    body: requestPasswordRecoverySchema,
  })
  .strict(true);

export const postRecoverPasswordRequestSchema = yup
  .object()
  .shape({
    body: recoverPasswordSchema,
  })
  .strict(true);

export const putImageRequestSchema = yup
  .object()
  .shape({
    body: yup.object().shape({
      widthInPx: yup.string().required().strict(true),
      heightInPx: yup.string().required().strict(true),
    }),
  })
  .strict(true);
