import React from "react";
import {
  EducationForm as EducationFormFields,
  JobApplication,
} from "@apply-high/interfaces";
import { v4 as uuid } from "uuid";

import { ReactComponent as Plus } from "../assets/svgs/plus-light.svg";
import EducationForm from "./EducationForm";
import { formatHeader, sortDescByEndDate } from "../Util/helper";
import { Button, ListEntry } from "@apply-high/components";

type EducationEditorProps = {
  jobApplication: JobApplication;
  updateJobApplication: (updatedJobApplication: JobApplication) => void;
  onUpdateMode: (isInteracting: boolean) => void;
};

type EducationEditorState = {
  isCreateFormOpen: boolean;
  editId: string | null;
};

class EducationEditor extends React.PureComponent<
  EducationEditorProps,
  EducationEditorState
> {
  constructor(props: EducationEditorProps) {
    super(props);

    this.state = {
      isCreateFormOpen: false,
      editId: null,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<EducationEditorProps>,
    prevState: Readonly<EducationEditorState>
  ) {
    const { onUpdateMode } = this.props;
    const { isCreateFormOpen, editId } = this.state;
    const isInteracting =
      (prevState.isCreateFormOpen === false && isCreateFormOpen === true) ||
      (prevState.editId === null && editId !== null);
    onUpdateMode(isInteracting);
  }

  renderEducationEntries = () => {
    const { isCreateFormOpen, editId } = this.state;
    const { jobApplication } = this.props;
    const { education } = jobApplication;

    if (isCreateFormOpen === true || editId !== null) {
      return null;
    }

    if (education.length === 0) {
      return (
        <>
          <div className="text-muted mb-2">
            Du hast noch keinen Bildungsweg hinzugefügt.
          </div>
          <Button
            variant="outline"
            onClick={() => this.setState({ isCreateFormOpen: true })}
          >
            <Plus className="me-2" />
            Eintrag hinzufügen
          </Button>
        </>
      );
    }

    return education.map((educationEntry) => (
      <ListEntry
        key={educationEntry.id}
        header={formatHeader(educationEntry)}
        subHeader={educationEntry.institution}
        onEdit={() => this.setState({ editId: educationEntry.id })}
        onRemove={() => this.deleteEducation(educationEntry)}
      />
    ));
  };

  renderCreateForm = () => {
    const { isCreateFormOpen } = this.state;

    if (isCreateFormOpen === false) {
      return null;
    }

    return (
      <EducationForm
        type="create"
        values={{
          id: uuid(),
          start: "",
          end: "",
          institution: "",
          degree: "",
          location: "",
          description: "",
          untilToday: false,
        }}
        onSubmit={(education) => this.createEducation(education)}
        onAbort={() => this.setState({ isCreateFormOpen: false })}
      />
    );
  };

  renderEditForm = () => {
    const { editId } = this.state;
    const { jobApplication } = this.props;
    const { education } = jobApplication;
    const educationEntry = education.find(
      (educationEntry) => educationEntry.id === editId
    );

    if (educationEntry === undefined) {
      return null;
    }

    return (
      <EducationForm
        type="edit"
        values={{
          ...educationEntry,
          untilToday: educationEntry.end === "",
        }}
        onSubmit={(education) => this.editEducation(education)}
        onAbort={() => this.setState({ editId: null })}
      />
    );
  };

  render() {
    const { isCreateFormOpen, editId } = this.state;
    const { jobApplication } = this.props;
    const { education } = jobApplication;
    const hasCreateButton =
      education.length > 0 && isCreateFormOpen === false && editId === null;

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
          <h2 className="h4 m-0">
            Bildungsweg{" "}
            {isCreateFormOpen
              ? " hinzufügen"
              : editId !== null
              ? " bearbeiten"
              : null}
          </h2>
          <div className={hasCreateButton === true ? "visible" : "invisible"}>
            <div className="d-none d-md-inline-flex">
              <Button
                variant="outline"
                onClick={() => this.setState({ isCreateFormOpen: true })}
                disabled={hasCreateButton === false}
              >
                <Plus className="me-2" />
                Eintrag hinzufügen
              </Button>
            </div>
            <div className="d-md-none d-sm-inline-flex">
              <Button
                circled
                variant="outline"
                onClick={() => this.setState({ isCreateFormOpen: true })}
                disabled={hasCreateButton === false}
              >
                <Plus />
              </Button>
            </div>
          </div>
        </div>
        {this.renderEducationEntries()}
        {this.renderCreateForm()}
        {this.renderEditForm()}
      </>
    );
  }

  private createEducation = async (educationEntry: EducationFormFields) => {
    const { jobApplication } = this.props;
    const { education } = jobApplication;

    const updatedEntries = [...education, educationEntry];
    const sortedEntries = sortDescByEndDate(updatedEntries);
    await this.saveEducation(sortedEntries);

    this.setState({ isCreateFormOpen: false });
  };

  private editEducation = async (
    updatedEducationEntry: EducationFormFields
  ) => {
    const { jobApplication } = this.props;
    const { education } = jobApplication;

    const updatedEntries = education.map((educationEntry) => {
      if (educationEntry.id === updatedEducationEntry.id) {
        return updatedEducationEntry;
      }

      return educationEntry;
    });
    const sortedEntries = sortDescByEndDate(updatedEntries);
    await this.saveEducation(sortedEntries);

    this.setState({ editId: null });
  };

  private deleteEducation = async (
    deletedEducationEntry: EducationFormFields
  ) => {
    const { jobApplication } = this.props;
    const { education } = jobApplication;

    await this.saveEducation(
      education.filter(
        (educationEntry) => educationEntry.id !== deletedEducationEntry.id
      )
    );
  };

  private saveEducation = async (
    educationEntries: Array<EducationFormFields>
  ) => {
    const { jobApplication, updateJobApplication } = this.props;

    updateJobApplication({
      ...jobApplication,
      education: educationEntries,
    });
  };
}

export default EducationEditor;
