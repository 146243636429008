import React, { useState } from "react";
import { Button, Input } from "@apply-high/components";
import { requestDemoSchema } from "@apply-high/schemas";
import { format } from "date-fns";
import { Formik, FormikHelpers } from "formik";
import { requestDemo } from "./Client";
import "./index.scss";

// Icons
import { ReactComponent as ArrowRight } from "./assets/svgs/arrow-right-light.svg";
import { ReactComponent as Stars } from "./assets/svgs/stars.svg";
import { ReactComponent as Magnet } from "./assets/svgs/magnet.svg";
import { ReactComponent as Crown } from "./assets/svgs/crown.svg";
import { ReactComponent as Chart } from "./assets/svgs/chart-line.svg";

// Images
import headerSrc from "./assets/images/CV-erstellt-mit-ApplyHigh.webp";
import slideOneSrc from "./assets/images/Stellen-Sie-den-CV-Generator-vor.jpg";
import slideTwoSrc from "./assets/images/Eingabe-der-persoenlichen-Daten.jpg";
import slideThreeSrc from "./assets/images/Eingabe-der-Berufserfahrung.jpg";
import slideFourSrc from "./assets/images/Erstellung-der-Faehigkeiten-Matrix.jpg";
import abelsonSrc from "./assets/images/Lebenslauf-Seite-1-Abelson.png";
import lovelaceSrc from "./assets/images/Lebenslauf-Seite-1-Lovelace.png";
import turingSrc from "./assets/images/Lebenslauf-Seite-1-Turing.png";
import winstonSrc from "./assets/images/Lebenslauf-Seite-1-Winston.png";

// Carousel
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const NumberCircle = ({ number }) => (
  <strong
    style={{ borderRadius: "50%", height: 40, width: 40 }}
    className="d-inline-flex justify-content-center align-items-center bg-primary bg-gradient text-white fs-4 font-monospace"
  >
    {number}
  </strong>
);

const Slide = ({ src, title, description, onClick }) => (
  <div className="container py-4">
    <div className="row g-0 d-flex align-items-center flex-md-row-reverse">
      <div className="col-lg-6 offset-lg-1 col-sm-12 d-flex justify-content-center mb-5 mb-md-0">
        <img src={src} className="img-fluid rounded shadow" alt="" title="" />
      </div>
      <div className="col-lg-5 col-sm-12">
        <NumberCircle number={2} />
        <h2 className="my-2">{title}</h2>
        <p className="lead text-muted lh-base">{description}</p>
        <Button onClick={onClick} size="big">
          Weiter
          <ArrowRight className="ms-2" />
        </Button>
      </div>
    </div>
  </div>
);

type DemoRequestFormValues = {
  email: string;
};

const useDemoRequest = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const onSubmit = async (
    values: DemoRequestFormValues,
    actions: FormikHelpers<DemoRequestFormValues>
  ) => {
    setIsLoading(true);
    setHasSuccess(false);
    setHasError(false);

    try {
      await requestDemo(values.email);
      setHasSuccess(true);
      actions.resetForm();
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    onSubmit,
    isLoading,
    hasSuccess,
    hasError,
  };
};

const LandingScreen = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { onSubmit, isLoading, hasSuccess, hasError } = useDemoRequest();

  return (
    <>
      <header className="py-2 border-bottom">
        <div className="container">
          <nav className="nav justify-content-center">
            <a className="nav-link text-reset" href="#how-it-works">
              Ablauf
            </a>
            <a className="nav-link text-reset" href="#product-advantages">
              Vorteile
            </a>
            <a className="nav-link text-reset" href="#request-demo">
              Demo anfragen
            </a>
          </nav>
        </div>
      </header>
      <main>
        <section className="py-5 bg-light" id="hero-background">
          <div className="container py-5">
            <div className="row d-flex align-items-center">
              <div className="col-md-6 d-flex justify-content-center mb-5 mb-md-0">
                <img
                  src={headerSrc}
                  className="img-fluid shadow-lg"
                  alt=""
                  title=""
                  style={{ maxHeight: 400 }}
                />
              </div>
              <div className="col-md-6">
                <div className="jumbotron mb-0 px-0">
                  <h1 className="mb-3">
                    Einzigartige CVs
                    <br className="d-none d-md-block" /> für deine
                    Kandidat:innen
                  </h1>
                  <p className="text-muted fw-light fs-2 lh-sm mb-4">
                    Hebe deine Recruiting-Strategie{" "}
                    <br className="d-none d-md-block" /> auf die nächste Ebene{" "}
                    <br className="d-none d-md-block" /> mit unserem
                    CV-Generator.
                  </p>
                  <Button
                    size="big"
                    variant="strong"
                    // eslint-disable-next-line
                    render={(props) => <a {...props} href="#how-it-works" />}
                  >
                    So funktioniert&apos;s
                    <ArrowRight
                      className="ms-2"
                      style={{ transform: "rotate(90deg)" }}
                      width={24}
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="how-it-works">
          <div className="container py-4">
            <div className="text-start text-md-center mt-5">
              <h2>
                Schnelle und effektive CV Erstellung für deine Kandidat:innen
              </h2>
              <p className="text-muted fw-light lead mb-0">
                So erstellen deine Kandidat:innen mit unserem CV-Generator
                <br className="d-none d-md-block" />
                den perfekten CV in wenigen Schritten.
              </p>
            </div>
          </div>
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-12 d-flex justify-content-center mb-5 mb-md-0">
                <img
                  src={slideOneSrc}
                  className="img-fluid shadow rounded"
                  alt=""
                  title=""
                />
              </div>
              <div className="col-lg-5 offset-lg-1 col-sm-12">
                <NumberCircle number={1} />
                <h2 className="my-2">Stelle den CV-Generator vor</h2>
                <p className="lead text-muted">
                  Biete deinen Kandidat:innen einen Service zur einfachen und
                  effizienten Erstellung von CVs. Platziere dazu einen
                  Einstiegspunkt auf Ihrer Website, der zum CV-Generator führt.
                </p>
              </div>
            </div>
          </div>
          <div className="py-5">
            <AliceCarousel
              mouseTracking
              activeIndex={activeIndex}
              disableButtonsControls={true}
              disableDotsControls={true}
              items={[
                <Slide
                  key="slide_1"
                  onClick={() => setActiveIndex(1)}
                  src={slideTwoSrc}
                  title="Erfassung der persönlicher Daten"
                  description="Der erste Schritt für einen erfolgreichen CV ist das Erfassen eines Bildes, der persönlichen Daten und der Kontaktdaten. Die geführte Eingabe hilft deinen Kandidat:innen dabei die wesentlichen Informationen zu erfassen, um in kürzen zu einem professionellem CV zu gelangen."
                />,
                <Slide
                  key="slide_2"
                  onClick={() => setActiveIndex(2)}
                  src={slideThreeSrc}
                  title="Erfassung der Berufserfahrungen"
                  description="Bei der Eingabe der Berufserfahrungen und des Bildungswegs können sich deine Kandidat:innen weiterhin auf den Inhalt konzentrieren. Um eine übersichtliche Darstellung und eine anti-chronologisch Sortierung der Einträge kümmert sich unser CV-Generator automatisch."
                />,
                <Slide
                  key="slide_3"
                  onClick={() => setActiveIndex(0)}
                  src={slideFourSrc}
                  title="Erstellung der Fähigkeitenmatrix"
                  description="Im dritten Schritt können deine Kandidat:innen in verschiedenen Kategorien unterschiedliche Fähigkeiten angeben. Es werden allgemeine aber auch branchenspezifische Kategorien vorgeschlagen, um deinen Kandidaten bestmöglich zu unterstützen und zu präsentieren."
                />,
              ]}
            />
          </div>
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 text-start text-md-center">
                <div className="flex-column flex-md-row d-flex justify-content-center align-items-start align-items-md-center">
                  <NumberCircle number={3} />
                  <h2 className="ms-0 ms-md-2 my-2">
                    Export und Distribution des CVs
                  </h2>
                </div>
                <p className="lead text-muted mb-0">
                  Nach der Erfassung der relevanten Daten erhält dein
                  Kandidat:innen einen CV als PDF und du eine E-Mail mit dem CV
                  im Anhang.
                </p>
              </div>
            </div>
            <div className="mt-4 mb-5">
              <AliceCarousel
                mouseTracking
                disableButtonsControls={true}
                items={[
                  <img
                    key="abelson"
                    src={abelsonSrc}
                    className="img-fluid shadow-sm rounded d-block mx-auto my-3"
                    alt=""
                    title=""
                    style={{ maxHeight: 300 }}
                  />,
                  <img
                    key="lovelace"
                    src={lovelaceSrc}
                    className="img-fluid shadow-sm rounded d-block mx-auto my-3"
                    alt=""
                    title=""
                    style={{ maxHeight: 300 }}
                  />,
                  <img
                    key="turing"
                    src={turingSrc}
                    className="img-fluid shadow-sm rounded d-block mx-auto my-3"
                    alt=""
                    title=""
                    style={{ maxHeight: 300 }}
                  />,
                  <img
                    key="winston"
                    src={winstonSrc}
                    className="img-fluid shadow-sm rounded d-block mx-auto my-3"
                    alt=""
                    title=""
                    style={{ maxHeight: 300 }}
                  />,
                ]}
                renderDotsItem={({ isActive }) => (
                  <button
                    style={{ height: 15, width: 15 }}
                    className={`btn p-0 mx-2 rounded-circle bg-primary ${
                      isActive ? "" : "bg-opacity-50"
                    }`}
                  />
                )}
                responsive={{
                  0: { items: 1 },
                  992: { items: 4 },
                }}
                controlsStrategy="responsive"
              />
            </div>
          </div>
        </section>
        <section className="py-5 bg-light" id="product-advantages">
          <div className="container mt-md-5">
            <div className="text-start text-md-center">
              <h2>Welche Vorteile bietet unser Produkt?</h2>
            </div>
            <div className="row py-5">
              <div className="col-md-6 col-sm-12 mb-5">
                <Stars className="mb-3" height={40} width={40} />
                <h3 className="mb-2">
                  Besondere Serviceleistung <br /> für deine Kandidat:innen
                </h3>
                <p className="fw-light lead mb-0">
                  Unsere Lösung ermöglicht eine schnelle und einfache Erstellung
                  professioneller CVs. Mit diesem Service steigerst du die
                  Zufriedenheit deiner Kandidat:innen und hebst dich als
                  Personalvermittlung positiv hervor.
                </p>
              </div>
              <div className="col-md-6 col-sm-12 mb-5">
                <Magnet className="mb-3" height={40} width={40} />
                <h3 className="mb-2">
                  Gewinnung neuer Kandidat:innen <br /> ohne aktive Aquise
                </h3>
                <p className="fw-light lead mb-0">
                  Durch die Möglichkeit, einen individuellen CV zu erstellen,
                  kommen potentielle Kandidat:innen von selbst auf deine
                  Webseite. Das führt zu qualitativ hochwertigen Kandidat:innen
                  und ist kosteneffizient.
                </p>
              </div>
              <div className="col-md-6 col-sm-12 mb-5">
                <Crown className="mb-3" height={40} width={40} />
                <h3 className="mb-2">
                  Einzigartiges Alleinstellungsmerkmal <br /> gegenüber deiner
                  Konkurenz
                </h3>
                <p className="fw-light lead mb-0">
                  Unsere Lösung bietet einen exklusiven Service in deinem
                  Corporate Design auf der eigenen Webseite. Für deine
                  Marketing-Strategie kann dies eine gute Ergänzung darstellen.
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <Chart className="mb-3" height={40} width={40} />
                <h3 className="mb-2">
                  Analysen auf Basis <br /> von anonymisierten Nutzerdaten
                </h3>
                <p className="fw-light lead mb-0">
                  Die Analyse von Nutzerdaten gibt wichtige Einblicke über deine
                  Kandidat:innen. So kannst du gezielt deine
                  Recruiting-Strategie anpassen und deine Zielgruppe besser
                  verstehen und ansprechen.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5" id="request-demo">
          <div className="container my-5">
            <div className="text-start text-md-center">
              <h2 className="mb-3">
                Setze neue Maßstäbe bei der CV Erstellung
              </h2>
              <p className="lead fw-light text-muted mb-4">
                Innovative Vorlagen, individuelle Anpassung und
                branchenspezifische Unterstützung.
                <br className="d-none d-md-block" />
                Erhalte den besten Kandidatenpool für dein Unternehmen.
              </p>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12 col-md-6 col-xl-4">
                <Formik
                  validationSchema={requestDemoSchema}
                  initialValues={{ email: "" }}
                  onSubmit={onSubmit}
                >
                  {(formikProps) => (
                    <form onSubmit={formikProps.handleSubmit} noValidate={true}>
                      <div className="d-grid gap-2">
                        {hasError && (
                          <div className="alert alert-danger mb-0">
                            Sorry, das hat nicht geklappt. Bitte kontaktiere uns
                            direkt über <strong>team@apply-high.de</strong>.
                          </div>
                        )}
                        {hasSuccess && (
                          <div className="alert alert-success mb-0">
                            Vielen Dank für deine Demo-Anfrage. Wir werden uns
                            in Kürze bei dir melden.
                          </div>
                        )}
                        <Input
                          id="email"
                          label="E-Mail"
                          value={formikProps.values.email}
                          touched={formikProps.touched.email}
                          error={formikProps.errors.email}
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          padded={false}
                        />
                        <Button
                          type="submit"
                          size="big"
                          variant="strong"
                          loading={isLoading}
                          disabled={isLoading}
                        >
                          Kostenlose Demo anfragen
                          <ArrowRight className="ms-2" width={24} />
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="bg-light fw-light border-top">
        <div className="container">
          <ul className="list-inline border-bottom py-3 m-0">
            <li className="list-inline-item">
              <a
                href="https://www.apply-high.de/imprint"
                className="text-reset text-decoration-none"
              >
                Impressum
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.apply-high.de/terms-and-conditions"
                className="text-reset text-decoration-none"
              >
                AGB
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.apply-high.de/data-privacy"
                className="text-reset text-decoration-none"
              >
                Datenschutzerklärung
              </a>
            </li>
          </ul>
          <p className="py-3 mb-0">
            Copyright {format(new Date(), "yyyy")} &copy; ApplyHigh
          </p>
        </div>
      </footer>
    </>
  );
};

export default LandingScreen;
