import * as h from "vhtml";
import { LanguageContent } from "@apply-high/interfaces";

type RenderLanguagesParams = {
  showHeadline: boolean;
  index: number;
  height: number;
  languageTuple: Array<{
    level: string;
    name: string;
  }>;
};
const renderLanguages = ({
  showHeadline,
  index,
  height,
  languageTuple,
}: RenderLanguagesParams) => (
  <div
    class="language"
    data-section-name={`language-row:${index}`}
    data-assumed-height={height}
  >
    {showHeadline === true ? (
      <div class="headline">
        <div class="headline__circle"></div>
        <div class="headline__text">
          <span class="headline__first-letter">S</span>prachen
        </div>
      </div>
    ) : (
      ""
    )}
    <div class="language__row">
      {languageTuple.map(({ name, level }) => (
        <div class="language__entry">
          <div class="language__level">{level}</div>
          <div class="language__name">{name}</div>
        </div>
      ))}
    </div>
  </div>
);

export const constructLanguageSections = (
  languages: Array<LanguageContent>
) => {
  const languageTuples = [];
  for (let index = 0; index < languages.length; index++) {
    const language = {
      ...languages[index],
      level: languages[index].isOfficialLanguageLevel
        ? languages[index].level
        : `${languages[index].level}%`,
    };

    if (index % 2 === 0) {
      languageTuples.push([language]);
    } else {
      languageTuples[languageTuples.length - 1].push(language);
    }
  }

  const sections = languageTuples.map((languageTuple, index) => {
    const headlineHeight = index === 0 ? 180 : 0;
    const contentHeight = 31;
    const entryMarginBottom = 30;
    const rowHeight = contentHeight + headlineHeight + entryMarginBottom;

    return {
      containerPreference: ["curriculum_vitae"],
      template: "sections/languages/languages.html",
      tags: ["cv"],
      height: rowHeight,
      render: () =>
        renderLanguages({
          showHeadline: index === 0,
          languageTuple: languageTuple,
          height: rowHeight,
          index: index,
        }),
    };
  });

  return sections;
};
