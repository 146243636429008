import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";

import {
  DIN_A4_HEIGHT_IN_PX,
  DIN_A4_WIDTH_IN_PX,
  Fragment,
} from "../../../utility/index";

type LayoutParams = {
  personal_data: string;
  personal_data_rest: string;
};

const Layout = ({ personal_data, personal_data_rest }: LayoutParams) => (
  <Fragment>
    <svg
      class="splash-background"
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewbox="0 0 595.28 841.89"
    >
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M385.35,0H0V270.25a90.82,90.82,0,0,1,42.14-35.84c19.13-9.32,50.44-14.07,85.22,10.93,10.56,7.59,20.19,17.86,31.81,22.44,26.37,10.37,52.18,6.65,74.91-12.07,20-16.44,30.37-39.18,33.62-65.33,3.65-29.44,2.12-59.68,11.27-88.26a330.51,330.51,0,0,1,14.18-36.63c6.88-15,16.71-28,29.43-37.83,8.7-6.69,18.05-12.95,27.94-17.2C360.9,6,372.27,4.2,383.2,1.19A11.72,11.72,0,0,0,385.42,0ZM234.22,169.19c-4.74-.1-7.64-3.09-7.8-8.76.35-5.26,3-8.35,8.27-8.12,4.95.21,7.47,3.63,7.45,8.52C242.12,166,239.07,169.29,234.22,169.19Z"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M196.06,409c-1.85,48-43.16,87.36-92.41,86.37-50.74-2.11-88.31-42.24-87.83-90.95.5-50.74,41.47-90.18,92.27-89.38C156.07,315.8,198,357.28,196.06,409Z"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M236.09,350.26c0-23.92,20.28-44.13,44-44.44,24.65-.31,45.57,21.86,45.09,45.51-.44,21.94-18.85,44.28-46.08,43.84C255.81,394.79,236.11,374.57,236.09,350.26Z"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M595.28,440.91v401H303c-39.14-44.37-62.8-102.14-62.8-165.29,0-140,116.21-253.42,259.56-253.42A264.34,264.34,0,0,1,595.28,440.91Z"
        />
      </g>
    </svg>
    <div class="personal-data__layout">
      {personal_data}
      {personal_data_rest}
    </div>
  </Fragment>
);

export const personalDataLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "personal_data",
      height: 400,
      width: DIN_A4_WIDTH_IN_PX,
      minSectionsHeight: 400,
    },
    {
      id: "personal_data_rest",
      height: 570,
      width: DIN_A4_WIDTH_IN_PX,
    },
  ],
};
