// this map describes how much px each char of a font is when rendered with 100px fontSize
export const fontMap = {
  ["Lato-Regular"]: {
    "0": 58,
    "1": 58,
    "2": 58,
    "3": 58,
    "4": 58,
    "5": 58,
    "6": 58,
    "7": 58,
    "8": 58,
    "9": 58,
    " ": 19.3,
    "!": 34.3,
    '"': 39.7,
    "#": 58,
    $: 58,
    "%": 78.6,
    "&": 70.3,
    "'": 23,
    "(": 30,
    ")": 30,
    "*": 40,
    "+": 58,
    ",": 21.2,
    "-": 34.7,
    ".": 21.2,
    "/": 37.3,
    ":": 25.2,
    ";": 25.2,
    "<": 58,
    "=": 58,
    ">": 58,
    "?": 39.8,
    "@": 82.2,
    A: 68,
    B: 64.7,
    C: 68.5,
    D: 75.3,
    E: 58.1,
    F: 56.6,
    G: 73.4,
    H: 75.6,
    I: 30.7,
    J: 44.4,
    K: 68.1,
    L: 51.4,
    M: 92,
    N: 75.6,
    O: 79.8,
    P: 61.1,
    Q: 79.8,
    R: 64.4,
    S: 53,
    T: 59,
    U: 73,
    V: 68,
    W: 101.9,
    X: 64.3,
    Y: 62.9,
    Z: 62.4,
    "[": 30,
    "\\": 37.5,
    "]": 30,
    "^": 58,
    _: 39.4,
    "`": 30.7,
    a: 50.7,
    b: 55.9,
    c: 46.7,
    d: 55.9,
    e: 52.4,
    f: 33.7,
    g: 51.1,
    h: 55.6,
    i: 25.6,
    j: 25.4,
    k: 52.4,
    l: 25.6,
    m: 82.1,
    n: 55.6,
    o: 55.6,
    p: 55.2,
    q: 55.9,
    r: 40.3,
    s: 43.4,
    t: 37.3,
    u: 55.6,
    v: 51.2,
    w: 76.6,
    x: 50.4,
    y: 51.2,
    z: 46.2,
    "{": 30,
    "|": 30,
    "}": 30,
    "~": 58,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 19.3,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    " ": 19.3,
    "¡": 34.3,
    "¢": 58,
    "£": 58,
    "¤": 58,
    "¥": 58,
    "¦": 30,
    "§": 50.3,
    "¨": 30.7,
    "©": 79.8,
    ª: 34.2,
    "«": 46.3,
    "¬": 58,
    "­": 0,
    "®": 79.8,
    "¯": 30.7,
    "°": 39.7,
    "±": 58,
    "²": 33.2,
    "³": 33.2,
    "´": 30.7,
    µ: 55.6,
    "¶": 66.9,
    "·": 27.3,
    "¸": 30.7,
    "¹": 33.2,
    º: 38.1,
    "»": 46.3,
    "¼": 71.2,
    "½": 71.2,
    "¾": 71.3,
    "¿": 39.8,
    À: 68,
    Á: 68,
    Â: 68,
    Ã: 68,
    Ä: 68,
    Å: 68,
    Æ: 92.9,
    Ç: 68.5,
    È: 58.1,
    É: 58.1,
    Ê: 58.1,
    Ë: 58.1,
    Ì: 30.7,
    Í: 30.7,
    Î: 30.7,
    Ï: 30.7,
    Ð: 78.9,
    Ñ: 75.6,
    Ò: 79.8,
    Ó: 79.8,
    Ô: 79.8,
    Õ: 79.8,
    Ö: 79.8,
    "×": 58,
    Ø: 79.8,
    Ù: 73,
    Ú: 73,
    Û: 73,
    Ü: 73,
    Ý: 62.9,
    Þ: 61.1,
    ß: 60.9,
    à: 50.7,
    á: 50.7,
    â: 50.7,
    ã: 50.7,
    ä: 50.7,
    å: 50.7,
    æ: 81.6,
    ç: 46.7,
    è: 52.4,
    é: 52.4,
    ê: 52.4,
    ë: 52.4,
    ì: 25.6,
    í: 25.6,
    î: 25.6,
    ï: 25.6,
    ð: 55.3,
    ñ: 55.6,
    ò: 55.6,
    ó: 55.6,
    ô: 55.6,
    õ: 55.6,
    ö: 55.6,
    "÷": 58,
    ø: 55.6,
    ù: 55.6,
    ú: 55.6,
    û: 55.6,
    ü: 55.6,
    ý: 51.2,
    þ: 55.2,
  },
  ["Lato-Light"]: {
    "0": 58,
    "1": 58,
    "2": 58,
    "3": 58,
    "4": 58,
    "5": 58,
    "6": 58,
    "7": 58,
    "8": 58,
    "9": 58,
    " ": 27.15,
    "!": 25.45,
    '"': 34.05,
    "#": 58,
    $: 58,
    "%": 77.95,
    "&": 69.85,
    "'": 19.55,
    "(": 25.8,
    ")": 25.8,
    "*": 42.5,
    "+": 58,
    ",": 21.9,
    "-": 37,
    ".": 23.35,
    "/": 43.45,
    ":": 24,
    ";": 25.1,
    "<": 58,
    "=": 58,
    ">": 58,
    "?": 43.2,
    "@": 82.85,
    A: 65.55,
    B: 63.2,
    C: 67.6,
    D: 76,
    E: 58,
    F: 56.45,
    G: 73.5,
    H: 75.5,
    I: 26.05,
    J: 41.45,
    K: 63.5,
    L: 50.6,
    M: 90.8,
    N: 75.5,
    O: 79.15,
    P: 57.8,
    Q: 79.15,
    R: 60.7,
    S: 53.5,
    T: 57.9,
    U: 72.7,
    V: 65.55,
    W: 101.7,
    X: 62.3,
    Y: 59.5,
    Z: 59.75,
    "[": 29.7,
    "\\": 43.45,
    "]": 29.7,
    "^": 58,
    _: 45.05,
    "`": 40,
    a: 48.4,
    b: 55.1,
    c: 47.15,
    d: 55.1,
    e: 52.15,
    f: 34.05,
    g: 51.05,
    h: 55,
    i: 22.25,
    j: 22.25,
    k: 47.6,
    l: 22.1,
    m: 80.5,
    n: 55,
    o: 55.8,
    p: 55.15,
    q: 55.05,
    r: 35.35,
    s: 42.5,
    t: 34.25,
    u: 54.95,
    v: 50.15,
    w: 76.45,
    x: 46.9,
    y: 50.15,
    z: 44.35,
    "{": 29.5,
    "|": 23.85,
    "}": 29.5,
    "~": 58,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 27.15,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    " ": 27.15,
    "¡": 23,
    "¢": 58,
    "£": 58,
    "¤": 58,
    "¥": 58,
    "¦": 24.15,
    "§": 48.5,
    "¨": 40,
    "©": 84.1,
    ª: 36.75,
    "«": 39.7,
    "¬": 58,
    "­": 0,
    "®": 84.1,
    "¯": 40,
    "°": 41.35,
    "±": 58,
    "²": 33.4,
    "³": 33.4,
    "´": 40,
    µ: 63.6,
    "¶": 65.65,
    "·": 24.2,
    "¸": 40,
    "¹": 33.4,
    º: 40.4,
    "»": 39.75,
    "¼": 72.25,
    "½": 71.35,
    "¾": 73.25,
    "¿": 42.2,
    À: 65.55,
    Á: 65.55,
    Â: 65.55,
    Ã: 65.55,
    Ä: 65.55,
    Å: 65.55,
    Æ: 92.75,
    Ç: 67.6,
    È: 58,
    É: 58,
    Ê: 58,
    Ë: 58,
    Ì: 26.05,
    Í: 26.05,
    Î: 26.05,
    Ï: 26.05,
    Ð: 76.65,
    Ñ: 75.5,
    Ò: 79.15,
    Ó: 79.15,
    Ô: 79.15,
    Õ: 79.15,
    Ö: 79.15,
    "×": 58,
    Ø: 79.15,
    Ù: 72.7,
    Ú: 72.7,
    Û: 72.7,
    Ü: 72.7,
    Ý: 59.5,
    Þ: 57.75,
    ß: 55.3,
    à: 48.4,
    á: 48.4,
    â: 48.4,
    ã: 48.4,
    ä: 48.4,
    å: 48.4,
    æ: 79.95,
    ç: 47.15,
    è: 52.15,
    é: 52.15,
    ê: 52.15,
    ë: 52.15,
    ì: 22.25,
    í: 22.3,
    î: 22.25,
    ï: 22.25,
    ð: 56.05,
    ñ: 55,
    ò: 55.8,
    ó: 55.8,
    ô: 55.8,
    õ: 55.8,
    ö: 55.8,
    "÷": 58,
    ø: 55.7,
    ù: 54.95,
    ú: 54.95,
    û: 54.95,
    ü: 54.95,
    ý: 50.15,
    þ: 55.15,
  },
  ["Lato-Bold"]: {
    "0": 58,
    "1": 58,
    "2": 58,
    "3": 58,
    "4": 58,
    "5": 58,
    "6": 58,
    "7": 58,
    "8": 58,
    "9": 58,
    " ": 24.3,
    "!": 28.1,
    '"': 39.55,
    "#": 58,
    $: 58,
    "%": 81.95,
    "&": 72.35,
    "'": 21.15,
    "(": 27.45,
    ")": 27.45,
    "*": 42.5,
    "+": 58,
    ",": 23.4,
    "-": 37.3,
    ".": 23.75,
    "/": 46.6,
    ":": 25.75,
    ";": 27.05,
    "<": 58,
    "=": 58,
    ">": 58,
    "?": 46,
    "@": 84.35,
    A: 69.45,
    B: 65.85,
    C: 66.15,
    D: 76.05,
    E: 57.5,
    F: 56.6,
    G: 72.6,
    H: 77.05,
    I: 29.65,
    J: 42.95,
    K: 68.55,
    L: 51.95,
    M: 94.5,
    N: 77.05,
    O: 80.8,
    P: 61.9,
    Q: 80.8,
    R: 64.3,
    S: 54.85,
    T: 60,
    U: 74.25,
    V: 69.45,
    W: 105.1,
    X: 67.05,
    Y: 64.8,
    Z: 60.55,
    "[": 31.35,
    "\\": 46.6,
    "]": 31.35,
    "^": 58,
    _: 46.65,
    "`": 40,
    a: 50.75,
    b: 56.75,
    c: 48.2,
    d: 56.75,
    e: 53.4,
    f: 35.85,
    g: 52.8,
    h: 56.4,
    i: 25.4,
    j: 25.4,
    k: 53.45,
    l: 24.85,
    m: 83.75,
    n: 56.4,
    o: 57.45,
    p: 56.8,
    q: 56.75,
    r: 37.3,
    s: 43.95,
    t: 37.2,
    u: 56.4,
    v: 52.8,
    w: 80.25,
    x: 52.2,
    y: 52.75,
    z: 45.95,
    "{": 30.6,
    "|": 26.1,
    "}": 30.6,
    "~": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 24.3,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    "": 58,
    " ": 24.3,
    "¡": 27,
    "¢": 58,
    "£": 58,
    "¤": 58,
    "¥": 58,
    "¦": 26.4,
    "§": 50.35,
    "¨": 40,
    "©": 82.4,
    ª: 36.7,
    "«": 45.55,
    "¬": 58,
    "­": 0,
    "®": 82.4,
    "¯": 40,
    "°": 41.55,
    "±": 58,
    "²": 33.4,
    "³": 33.4,
    "´": 40,
    µ: 66,
    "¶": 73.7,
    "·": 27.45,
    "¸": 40,
    "¹": 33.4,
    º: 40.05,
    "»": 45.55,
    "¼": 74.3,
    "½": 73.55,
    "¾": 74.95,
    "¿": 45.5,
    À: 69.45,
    Á: 69.45,
    Â: 69.45,
    Ã: 69.45,
    Ä: 69.45,
    Å: 69.45,
    Æ: 93.3,
    Ç: 66.15,
    È: 57.5,
    É: 57.5,
    Ê: 57.5,
    Ë: 57.5,
    Ì: 29.65,
    Í: 29.65,
    Î: 29.65,
    Ï: 29.65,
    Ð: 77,
    Ñ: 77.05,
    Ò: 80.8,
    Ó: 80.8,
    Ô: 80.8,
    Õ: 80.8,
    Ö: 80.8,
    "×": 58,
    Ø: 80.8,
    Ù: 74.25,
    Ú: 74.25,
    Û: 74.25,
    Ü: 74.25,
    Ý: 64.8,
    Þ: 61.55,
    ß: 60.25,
    à: 50.75,
    á: 50.75,
    â: 50.75,
    ã: 50.75,
    ä: 50.75,
    å: 50.75,
    æ: 80.95,
    ç: 48.3,
    è: 53.4,
    é: 53.4,
    ê: 53.4,
    ë: 53.4,
    ì: 25.4,
    í: 25.45,
    î: 25.4,
    ï: 25.4,
    ð: 57.05,
    ñ: 56.4,
    ò: 57.45,
    ó: 57.45,
    ô: 57.45,
    õ: 57.45,
    ö: 57.45,
    "÷": 58,
    ø: 57.4,
    ù: 56.4,
    ú: 56.4,
    û: 56.4,
    ü: 56.4,
    ý: 52.75,
    þ: 56.8,
  },
  ["72-Light"]: {
    "0": 53.52,
    "1": 53.52,
    "2": 53.52,
    "3": 53.52,
    "4": 53.52,
    "5": 53.52,
    "6": 53.52,
    "7": 53.52,
    "8": 53.52,
    "9": 53.52,
    " ": 27.4,
    "!": 28.67,
    '"': 34.87,
    "#": 55.62,
    $: 53.52,
    "%": 87.88,
    "&": 67.33,
    "'": 19.28,
    "(": 32.72,
    ")": 32.72,
    "*": 38.72,
    "+": 54.68,
    ",": 23.05,
    "-": 32.62,
    ".": 23.05,
    "/": 33.2,
    ":": 23.05,
    ";": 23.05,
    "<": 54.68,
    "=": 54.68,
    ">": 54.68,
    "?": 54.05,
    "@": 95.02,
    A: 60.93,
    B: 63.82,
    C: 68.5,
    D: 69.63,
    E: 57.72,
    F: 53.77,
    G: 72.57,
    H: 70.7,
    I: 25.15,
    J: 47.9,
    K: 60.2,
    L: 50.73,
    M: 83.88,
    N: 71.68,
    O: 75.3,
    P: 62.5,
    Q: 75.3,
    R: 63.92,
    S: 62.02,
    T: 54.63,
    U: 69.92,
    V: 59.92,
    W: 88.57,
    X: 58.88,
    Y: 58.15,
    Z: 55.57,
    "[": 27.93,
    "\\": 33.2,
    "]": 27.93,
    "^": 49.85,
    _: 52.35,
    "`": 30.52,
    a: 53.47,
    b: 56.88,
    c: 49.12,
    d: 56.88,
    e: 53.72,
    f: 27.48,
    g: 51.95,
    h: 55.08,
    i: 21.48,
    j: 21.48,
    k: 48.48,
    l: 23.05,
    m: 84.23,
    n: 55.08,
    o: 55.67,
    p: 56.88,
    q: 56.88,
    r: 31.4,
    s: 45.85,
    t: 28.57,
    u: 55.08,
    v: 47.07,
    w: 73.58,
    x: 46.97,
    y: 47.32,
    z: 46.63,
    "{": 32.18,
    "|": 23.05,
    "}": 32.18,
    "~": 54.68,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 27.4,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    "": 55,
    " ": 27.4,
    "¡": 30.95,
    "¢": 53.52,
    "£": 53.52,
    "¤": 53.37,
    "¥": 53.52,
    "¦": 23.05,
    "§": 51.37,
    "¨": 30.52,
    "©": 75.35,
    ª: 41.12,
    "«": 51.8,
    "¬": 54.68,
    "­": 0,
    "®": 75.35,
    "¯": 52.2,
    "°": 38.53,
    "±": 53.22,
    "²": 33,
    "³": 32.92,
    "´": 30.52,
    µ: 57.57,
    "¶": 51.95,
    "·": 23.05,
    "¸": 30.52,
    "¹": 32.97,
    º: 43.8,
    "»": 51.8,
    "¼": 76.12,
    "½": 81.35,
    "¾": 74.65,
    "¿": 57.72,
    À: 60.93,
    Á: 60.93,
    Â: 60.93,
    Ã: 60.93,
    Ä: 60.93,
    Å: 60.93,
    Æ: 91.55,
    Ç: 68.5,
    È: 57.72,
    É: 57.72,
    Ê: 57.72,
    Ë: 57.72,
    Ì: 25.15,
    Í: 25.15,
    Î: 25.15,
    Ï: 25.15,
    Ð: 69.63,
    Ñ: 71.68,
    Ò: 75.3,
    Ó: 75.3,
    Ô: 75.3,
    Õ: 75.3,
    Ö: 75.3,
    "×": 54.53,
    Ø: 75.3,
    Ù: 69.92,
    Ú: 69.92,
    Û: 69.92,
    Ü: 69.92,
    Ý: 58.15,
    Þ: 62.5,
    ß: 58.05,
    à: 53.47,
    á: 53.47,
    â: 53.47,
    ã: 53.47,
    ä: 53.47,
    å: 53.47,
    æ: 88.62,
    ç: 49.12,
    è: 53.72,
    é: 53.72,
    ê: 53.72,
    ë: 53.72,
    ì: 21.48,
    í: 21.48,
    î: 23.83,
    ï: 23.83,
    ð: 55.67,
    ñ: 55.08,
    ò: 55.67,
    ó: 55.67,
    ô: 55.67,
    õ: 55.67,
    ö: 55.67,
    "÷": 53.22,
    ø: 57.52,
    ù: 55.08,
    ú: 55.08,
    û: 55.08,
    ü: 55.08,
    ý: 47.32,
    þ: 56.88,
  },
  ["Lato-Medium"]: {
    "0": 58,
    "1": 58,
    "2": 58,
    "3": 58,
    "4": 58,
    "5": 58,
    "6": 58,
    "7": 58,
    "8": 58,
    "9": 58,
    " ": 25.25,
    "!": 27.2,
    '"': 37.7,
    "#": 58,
    $: 58,
    "%": 80.6,
    "&": 71.5,
    "'": 20.6,
    "(": 26.9,
    ")": 26.9,
    "*": 42.5,
    "+": 58,
    ",": 22.9,
    "-": 37.2,
    ".": 23.6,
    "/": 45.55,
    ":": 25.2,
    ";": 26.4,
    "<": 58,
    "=": 58,
    ">": 58,
    "?": 45.05,
    "@": 83.85,
    A: 68.15,
    B: 64.95,
    C: 66.65,
    D: 76.05,
    E: 57.7,
    F: 56.55,
    G: 72.95,
    H: 76.55,
    I: 28.4,
    J: 42.45,
    K: 66.85,
    L: 51.5,
    M: 93.25,
    N: 76.55,
    O: 80.25,
    P: 60.5,
    Q: 80.25,
    R: 63.05,
    S: 54.4,
    T: 59.3,
    U: 73.75,
    V: 68.15,
    W: 103.95,
    X: 65.4,
    Y: 63,
    Z: 60.3,
    "[": 30.8,
    "\\": 45.55,
    "]": 30.8,
    "^": 58,
    _: 46.1,
    "`": 40,
    a: 49.95,
    b: 56.2,
    c: 47.85,
    d: 56.2,
    e: 52.95,
    f: 35.25,
    g: 52.2,
    h: 55.95,
    i: 24.35,
    j: 24.35,
    k: 51.45,
    l: 23.9,
    m: 82.65,
    n: 55.95,
    o: 56.9,
    p: 56.25,
    q: 56.15,
    r: 36.65,
    s: 43.45,
    t: 36.2,
    u: 55.9,
    v: 51.9,
    w: 78.95,
    x: 50.4,
    y: 51.85,
    z: 45.4,
    "{": 30.25,
    "|": 25.3,
    "}": 30.25,
    "~": 58,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 25.25,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    "": 57.98,
    " ": 25.25,
    "¡": 25.65,
    "¢": 58,
    "£": 58,
    "¤": 58,
    "¥": 58,
    "¦": 25.6,
    "§": 49.75,
    "¨": 40,
    "©": 83,
    ª: 36.75,
    "«": 43.55,
    "¬": 58,
    "­": 0,
    "®": 83,
    "¯": 40,
    "°": 41.5,
    "±": 58,
    "²": 33.4,
    "³": 33.4,
    "´": 40,
    µ: 65.2,
    "¶": 70.95,
    "·": 26.35,
    "¸": 40,
    "¹": 33.4,
    º: 40.2,
    "»": 43.6,
    "¼": 73.6,
    "½": 72.8,
    "¾": 74.35,
    "¿": 44.4,
    À: 68.15,
    Á: 68.15,
    Â: 68.15,
    Ã: 68.15,
    Ä: 68.15,
    Å: 68.15,
    Æ: 93.1,
    Ç: 66.65,
    È: 57.7,
    É: 57.7,
    Ê: 57.7,
    Ë: 57.7,
    Ì: 28.4,
    Í: 28.4,
    Î: 28.4,
    Ï: 28.4,
    Ð: 76.9,
    Ñ: 76.55,
    Ò: 80.25,
    Ó: 80.25,
    Ô: 80.25,
    Õ: 80.25,
    Ö: 80.25,
    "×": 58,
    Ø: 80.25,
    Ù: 73.75,
    Ú: 73.75,
    Û: 73.75,
    Ü: 73.75,
    Ý: 63,
    Þ: 60.25,
    ß: 58.6,
    à: 49.95,
    á: 49.95,
    â: 49.95,
    ã: 49.95,
    ä: 49.95,
    å: 49.95,
    æ: 80.6,
    ç: 47.9,
    è: 52.95,
    é: 52.95,
    ê: 52.95,
    ë: 52.95,
    ì: 24.35,
    í: 24.35,
    î: 24.35,
    ï: 24.35,
    ð: 56.7,
    ñ: 55.95,
    ò: 56.9,
    ó: 56.9,
    ô: 56.9,
    õ: 56.9,
    ö: 56.9,
    "÷": 58,
    ø: 56.8,
    ù: 55.9,
    ú: 55.9,
    û: 55.9,
    ü: 55.9,
    ý: 51.85,
    þ: 56.25,
  },
  ["Merriweather-Regular"]: {
    "0": 64.3,
    "1": 44,
    "2": 59.8,
    "3": 56,
    "4": 64.4,
    "5": 59.4,
    "6": 66.4,
    "7": 58.8,
    "8": 63.3,
    "9": 66,
    " ": 23.7,
    "!": 35.4,
    '"': 61.2,
    "#": 74.4,
    $: 65.9,
    "%": 103.7,
    "&": 75.6,
    "'": 35.3,
    "(": 43,
    ")": 43,
    "*": 64.3,
    "+": 66.1,
    ",": 33.5,
    "-": 62.8,
    ".": 26.5,
    "/": 43.1,
    ":": 34.4,
    ";": 34.4,
    "<": 66.9,
    "=": 65.3,
    ">": 66.9,
    "?": 48.7,
    "@": 107.8,
    A: 69.2,
    B: 68.5,
    C: 65.3,
    D: 75.8,
    E: 65.1,
    F: 61.9,
    G: 74,
    H: 82.8,
    I: 40.3,
    J: 38.1,
    K: 72.6,
    L: 63.4,
    M: 99,
    N: 79.9,
    O: 72.2,
    P: 64.9,
    Q: 72.7,
    R: 70.4,
    S: 59.4,
    T: 69.2,
    U: 74,
    V: 67,
    W: 100,
    X: 71.2,
    Y: 64.4,
    Z: 65.4,
    "[": 42.8,
    "\\": 43.1,
    "]": 42.8,
    "^": 66.6,
    _: 83.5,
    "`": 33.3,
    a: 56.1,
    b: 60.7,
    c: 51.3,
    d: 62.1,
    e: 54.4,
    f: 40,
    g: 60.7,
    h: 65.9,
    i: 33.3,
    j: 31.7,
    k: 60,
    l: 33.3,
    m: 99.5,
    n: 66.4,
    o: 60.8,
    p: 62.8,
    q: 60.9,
    r: 45.8,
    s: 50.5,
    t: 40.7,
    u: 63.2,
    v: 56.8,
    w: 83.9,
    x: 59.5,
    y: 57.2,
    z: 55.4,
    "{": 50.8,
    "|": 42.2,
    "}": 50.8,
    "~": 69.2,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 23.7,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    "": 66,
    " ": 23.7,
    "¡": 35.4,
    "¢": 58.2,
    "£": 74.3,
    "¤": 75.5,
    "¥": 76.9,
    "¦": 42.2,
    "§": 67.2,
    "¨": 50,
    "©": 104,
    ª: 51.8,
    "«": 67,
    "¬": 68.8,
    "­": 0,
    "®": 104,
    "¯": 33.3,
    "°": 46.3,
    "±": 66.1,
    "²": 49.8,
    "³": 48.8,
    "´": 33.3,
    µ: 68.3,
    "¶": 73.1,
    "·": 17.5,
    "¸": 30,
    "¹": 48.4,
    º: 51.5,
    "»": 67,
    "¼": 117.7,
    "½": 117,
    "¾": 118.1,
    "¿": 48.7,
    À: 69.2,
    Á: 69.2,
    Â: 69.2,
    Ã: 69.2,
    Ä: 69.2,
    Å: 69.2,
    Æ: 92.1,
    Ç: 65.3,
    È: 65.1,
    É: 65.1,
    Ê: 65.1,
    Ë: 65.1,
    Ì: 40.3,
    Í: 40.3,
    Î: 40.3,
    Ï: 40.3,
    Ð: 75.8,
    Ñ: 79.9,
    Ò: 72.2,
    Ó: 72.2,
    Ô: 72.2,
    Õ: 72.2,
    Ö: 72.2,
    "×": 60.9,
    Ø: 72.3,
    Ù: 74,
    Ú: 74,
    Û: 74,
    Ü: 74,
    Ý: 64.4,
    Þ: 64.5,
    ß: 63.5,
    à: 56.1,
    á: 56.1,
    â: 56.1,
    ã: 56.1,
    ä: 56.1,
    å: 56.1,
    æ: 86.9,
    ç: 51.3,
    è: 54.4,
    é: 54.4,
    ê: 54.4,
    ë: 54.4,
    ì: 33.3,
    í: 33.3,
    î: 33.3,
    ï: 33.3,
    ð: 58.1,
    ñ: 66.4,
    ò: 60.8,
    ó: 60.8,
    ô: 60.8,
    õ: 60.8,
    ö: 60.8,
    "÷": 66.5,
    ø: 60,
    ù: 63.2,
    ú: 63.2,
    û: 63.2,
    ü: 63.2,
    ý: 57.2,
    þ: 60.8,
  },
  ["Merriweather-Black"]: {
    "0": 66.6,
    "1": 46.9,
    "2": 60.9,
    "3": 57,
    "4": 67.1,
    "5": 62.3,
    "6": 67.6,
    "7": 59.8,
    "8": 64.7,
    "9": 66.2,
    " ": 23.7,
    "!": 35.1,
    '"': 64.5,
    "#": 74.4,
    $: 66.7,
    "%": 107.9,
    "&": 76.5,
    "'": 36.7,
    "(": 44.8,
    ")": 44.8,
    "*": 66.8,
    "+": 66.1,
    ",": 33.4,
    "-": 63.2,
    ".": 31.1,
    "/": 43.1,
    ":": 34,
    ";": 34,
    "<": 66.9,
    "=": 65.3,
    ">": 66.9,
    "?": 51.4,
    "@": 108.7,
    A: 70.7,
    B: 71.7,
    C: 66.2,
    D: 76.3,
    E: 65.7,
    F: 62.4,
    G: 74.7,
    H: 83.9,
    I: 41.7,
    J: 39.7,
    K: 75.8,
    L: 64.1,
    M: 103.4,
    N: 80.5,
    O: 73.1,
    P: 66.8,
    Q: 73.2,
    R: 73.1,
    S: 60.9,
    T: 72.5,
    U: 74.5,
    V: 69.7,
    W: 102.7,
    X: 72.3,
    Y: 65.6,
    Z: 65.5,
    "[": 43.2,
    "\\": 43.1,
    "]": 43.2,
    "^": 66.6,
    _: 83.5,
    "`": 33.3,
    a: 57,
    b: 62.5,
    c: 52.3,
    d: 62.9,
    e: 55.4,
    f: 41.3,
    g: 63.1,
    h: 67.7,
    i: 35.6,
    j: 33.8,
    k: 64.7,
    l: 34.4,
    m: 102.4,
    n: 69.1,
    o: 64.2,
    p: 64,
    q: 61.8,
    r: 48,
    s: 52.8,
    t: 40.8,
    u: 64.4,
    v: 57.3,
    w: 84.1,
    x: 61,
    y: 57.3,
    z: 56.1,
    "{": 52.5,
    "|": 42.2,
    "}": 52.5,
    "~": 69.9,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 23.7,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    "": 68,
    " ": 23.7,
    "¡": 35.1,
    "¢": 58.2,
    "£": 78.1,
    "¤": 75.5,
    "¥": 81.1,
    "¦": 42.2,
    "§": 68.7,
    "¨": 50,
    "©": 104,
    ª: 55.5,
    "«": 69.7,
    "¬": 68.8,
    "­": 0,
    "®": 104,
    "¯": 33.3,
    "°": 50.5,
    "±": 66.1,
    "²": 51.1,
    "³": 49.2,
    "´": 33.3,
    µ: 68.3,
    "¶": 81.6,
    "·": 24.1,
    "¸": 30,
    "¹": 49.4,
    º: 53.6,
    "»": 69.7,
    "¼": 120.9,
    "½": 119.9,
    "¾": 120.7,
    "¿": 51.4,
    À: 70.7,
    Á: 70.7,
    Â: 70.7,
    Ã: 70.7,
    Ä: 70.7,
    Å: 70.7,
    Æ: 94.6,
    Ç: 66.2,
    È: 65.7,
    É: 65.7,
    Ê: 65.7,
    Ë: 65.7,
    Ì: 41.7,
    Í: 41.7,
    Î: 41.7,
    Ï: 41.7,
    Ð: 76.3,
    Ñ: 80.5,
    Ò: 73.1,
    Ó: 73.1,
    Ô: 73.1,
    Õ: 73.1,
    Ö: 73.1,
    "×": 61.8,
    Ø: 73.1,
    Ù: 74.5,
    Ú: 74.5,
    Û: 74.5,
    Ü: 74.5,
    Ý: 65.6,
    Þ: 67,
    ß: 66.5,
    à: 57,
    á: 57,
    â: 57,
    ã: 57,
    ä: 57,
    å: 57,
    æ: 86.8,
    ç: 52.3,
    è: 55.4,
    é: 55.4,
    ê: 55.4,
    ë: 55.4,
    ì: 35.6,
    í: 35.6,
    î: 35.6,
    ï: 35.6,
    ð: 60.4,
    ñ: 69.1,
    ò: 64.2,
    ó: 64.2,
    ô: 64.2,
    õ: 64.2,
    ö: 64.2,
    "÷": 66.2,
    ø: 61.3,
    ù: 64.4,
    ú: 64.4,
    û: 64.4,
    ü: 64.4,
    ý: 57.3,
    þ: 63,
  },
};
