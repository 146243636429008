import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";

import {
  DIN_A4_HEIGHT_IN_PX,
  DIN_A4_WIDTH_IN_PX,
} from "../../../utility/index";

type LayoutParams = {
  cover: string;
};

const Layout = ({ cover }: LayoutParams) => <div class="cover">{cover}</div>;

export const coverLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "cover",
      height: DIN_A4_HEIGHT_IN_PX,
      width: DIN_A4_WIDTH_IN_PX,
    },
  ],
};
