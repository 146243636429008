import React from "react";
import { Formik } from "formik";
import { ReactComponent as Right } from "../assets/svgs/arrow-right-light.svg";
import { useNavigate } from "react-router-dom";
import { JobApplication, PersonalDataForm } from "@apply-high/interfaces";
import { personalDataSchema } from "@apply-high/schemas";
import { Button, Input, DateInput, FadeIn } from "@apply-high/components";

import { Steps } from "./Steps";
import ImageEditor from "../Components/ImageEditor";

type PersonalDataStepProps = {
  jobApplication: JobApplication;
  updateJobApplication: (updatedJobApplication: JobApplication) => void;
};

const PersonalDataStep: React.FC<PersonalDataStepProps> = ({
  jobApplication,
  updateJobApplication,
}) => {
  const navigate = useNavigate();

  const savePersonalData = async (personalData: PersonalDataForm) => {
    updateJobApplication({
      ...jobApplication,
      ...personalData,
    });

    navigate(`../${Steps.WORK_EXPERIENCE}`);
  };

  return (
    <Formik<PersonalDataForm>
      initialValues={{
        firstName: jobApplication.firstName,
        lastName: jobApplication.lastName,
        birthday: jobApplication.birthday,
        birthplace: jobApplication.birthplace,
        postcode: jobApplication.postcode,
        city: jobApplication.city,
        street: jobApplication.street,
        streetNumber: jobApplication.streetNumber,
        telephone: jobApplication.telephone,
        email: jobApplication.email,
      }}
      onSubmit={savePersonalData}
      validationSchema={personalDataSchema("form")}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit} noValidate={true}>
          <FadeIn isActive>
            <h2 className="mt-4 mb-3 h4">Bild</h2>
            <div className="position-relative">
              <ImageEditor
                jobApplication={jobApplication}
                updateJobApplication={updateJobApplication}
              />
            </div>
            <h2 className="mt-4 mb-3 h4">Persönliche Daten</h2>
            <Input
              label="Vorname"
              id="firstName"
              type="text"
              error={formikProps.errors.firstName}
              value={formikProps.values.firstName}
              touched={formikProps.touched.firstName}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            />
            <Input
              label="Nachname"
              id="lastName"
              type="text"
              error={formikProps.errors.lastName}
              value={formikProps.values.lastName}
              touched={formikProps.touched.lastName}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            />
            <DateInput
              id="birthday"
              label="Geburtsdatum (TT.MM.JJJJ)"
              error={formikProps.errors.birthday}
              value={formikProps.values.birthday}
              touched={formikProps.touched.birthday}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            />
            <Input
              label="Geburtsort"
              id="birthplace"
              type="text"
              error={formikProps.errors.birthplace}
              value={formikProps.values.birthplace}
              touched={formikProps.touched.birthplace}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            />
            <h2 className="mt-4 mb-3 h4">Kontaktdaten</h2>
            <div className="row g-2">
              <div className="col-sm-8">
                <Input
                  label="Straße"
                  id="street"
                  type="text"
                  error={formikProps.errors.street}
                  value={formikProps.values.street}
                  touched={formikProps.touched.street}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  label="Nr."
                  id="streetNumber"
                  type="text"
                  error={formikProps.errors.streetNumber}
                  value={formikProps.values.streetNumber}
                  touched={formikProps.touched.streetNumber}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                />
              </div>
            </div>
            <div className="row g-2">
              <div className="col-sm-4">
                <Input
                  label="PLZ"
                  id="postcode"
                  type="text"
                  error={formikProps.errors.postcode}
                  value={formikProps.values.postcode}
                  touched={formikProps.touched.postcode}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                />
              </div>
              <div className="col-sm-8">
                <Input
                  label="Ort"
                  id="city"
                  type="text"
                  error={formikProps.errors.city}
                  value={formikProps.values.city}
                  touched={formikProps.touched.city}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                />
              </div>
            </div>
            <Input
              label="E-Mail"
              id="email"
              type="email"
              error={formikProps.errors.email}
              value={formikProps.values.email}
              touched={formikProps.touched.email}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            />
            <Input
              label="Telefonnummer"
              id="telephone"
              type="tel"
              help="Wir empfehlen Dir die Telefonnummer leserlich zu formatieren z.B. +49 178 111 111 11"
              error={formikProps.errors.telephone}
              value={formikProps.values.telephone}
              touched={formikProps.touched.telephone}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              required={false}
            />
          </FadeIn>
          <div className="d-flex justify-content-end mt-5">
            <Button
              type="submit"
              variant="strong"
              disabled={formikProps.isSubmitting}
              loading={formikProps.isSubmitting}
            >
              Weiter
              <Right className="ms-2" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default PersonalDataStep;
