import { ThemeConfigurationConstructor } from "@apply-high/interfaces";
import * as chroma from "chroma-js";

import { cssFontStyles } from "./font_styles";
import { constructLetterSections } from "./sections/letter.section";
import { constructCoverSection } from "./sections/cover.section";
import { constructPersonalDataSection } from "./sections/personal_data.section";
import { constructLanguagesSection } from "./sections/languages.section";
import { constructSkillsSection } from "./sections/skills.section";
import { constructTimelines } from "./sections/construct_timelines";
import {
  coverLayout,
  letterLayout,
  splitLayout,
  standardLayout,
} from "./layouts/index";
import { ThemeSettings } from "../../interfaces";
import { staticResources } from "./static";

export const Winston: ThemeConfigurationConstructor = (
  content,
  colorScheme
) => ({
  globalParameters: {
    ...cssFontStyles,
    primaryColor: colorScheme.primaryColor,
    primaryBackgroundColor: chroma(colorScheme.primaryColor)
      .brighten(0.69)
      .desaturate(0.25)
      .hex(),
    darkenedPrimaryColor: chroma(colorScheme.primaryColor).darken(2).hex(),
    brightenedPrimaryColor: chroma(colorScheme.primaryColor)
      .brighten(1.5)
      .desaturate(0.75)
      .hex(),
  },
  css: ["index.css"],
  cssResources: [staticResources["index.css"].content],
  fontResources: [
    {
      fontFamily: "Lato-Regular",
      resource: staticResources["fonts/LatoLatin-Regular.woff2"],
    },
    {
      fontFamily: "Lato-Medium",
      resource: staticResources["fonts/LatoLatin-Medium.woff2"],
    },
    {
      fontFamily: "Lato-Light",
      resource: staticResources["fonts/LatoLatin-Light.woff2"],
    },
    {
      fontFamily: "Lato-Bold",
      resource: staticResources["fonts/LatoLatin-Bold.woff2"],
    },
  ],
  pageLayouts: [coverLayout, letterLayout, splitLayout, standardLayout],
  sections: [
    constructCoverSection(content.personalData),
    ...constructLetterSections(content),
    constructPersonalDataSection(content.personalData),
    ...constructTimelines(content.education, content.workExperiences),
    constructLanguagesSection(content, colorScheme),
    ...constructSkillsSection(content.skills),
  ],
});

export const winstonSettings: ThemeSettings = {
  themeConfigurationConstructor: Winston,
  staticSettings: {
    portraits: [
      {
        id: "cover",
        widthToLengthRatio: 2,
      },
      {
        id: "cv",
        widthToLengthRatio: 1,
      },
    ],
    colors: [
      {
        id: "blue",
        primaryColor: "#42609b",
        secondaryColor: "#568288",
      },
      {
        id: "red",
        primaryColor: "#793B3C",
        secondaryColor: "#6e4444",
      },
      {
        id: "turquoise",
        primaryColor: "#428594",
        secondaryColor: "#445a9a",
      },
      {
        id: "green",
        primaryColor: "#526b40",
        secondaryColor: "#5f7356",
      },
      {
        id: "yellow",
        primaryColor: "#d2a012",
        secondaryColor: "#c2a461",
      },
    ],
  },
};
