import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";

import {
  DIN_A4_HEIGHT_IN_PX,
  DIN_A4_WIDTH_IN_PX,
} from "../../../utility/index";

type LayoutParams = {
  cover: string;
};

const Layout = ({ cover }: LayoutParams) => (
  <div>
    <svg
      class="splash-background"
      id="deckblatt"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewbox="0 0 595.28 841.89"
    >
      <path
        class="primary-color--darker"
        xmlns="http://www.w3.org/2000/svg"
        d="M350.82,72.23c-6.63-1.07-10.65-5.28-13.52-11-4.11-8.2-8.22-16.41-13-24.27A27.68,27.68,0,0,0,317.14,29C310.09,23.73,302,21,293.49,19c-6.33-1.45-12.57-3-18-6.91a11.13,11.13,0,0,1-4.48-6c-.73-2.43-1.3-4.95-2.68-7.14a1.66,1.66,0,0,0-1.45-.72q-132.37,0-264.76,0a6.79,6.79,0,0,0-3.07.22V380.35c6.07.2,12.12-.19,18.17-.54,1.81-.11,3.64-.36,5.45-.57,3.23-.38,6.46-.84,9.68-1.3q6-.85,12-1.75c5.51-.81,10.76-2.76,16.26-3.75A181.82,181.82,0,0,0,79.45,368a97.79,97.79,0,0,1,20.66-3.43c2.7-.19,5.39-.49,8.09-.68,3.72-.27,7.43-.83,11.17-.84A19.36,19.36,0,0,0,122,363c4-.6,8.09-.52,12.13-.86,2.38-.19,4.76-.41,7.12-.68a44.13,44.13,0,0,0,5.21-.57c7.22-1.67,13.49-5.27,19.41-9.52,5.11-3.67,10-7.63,15.07-11.36,6.79-5,14.13-9.27,20.51-14.85,2.34-2.05,4.94-3.84,6.88-6.27a68.26,68.26,0,0,1,12.34-12.27,102.43,102.43,0,0,1,21.22-12.33c5.5-2.34,11.18-4.29,16.45-7.16,2.31-1.26,4.57-2.61,6.87-3.89,8.25-4.59,15.09-10.9,21.51-17.73a6.23,6.23,0,0,0,.89-1.41c3.25-6.32,6.77-12.49,10.19-18.71,4.14-7.52,6-15.57,6.68-23.95a143.61,143.61,0,0,0,.57-16.73,27.65,27.65,0,0,1,.73-6.15c.83-4.37,1.9-8.7,3.07-13,1.88-6.91,3.08-14,4.74-20.94,1.76-7.43,3.71-14.81,5.18-22.31,1.57-8.05,3.57-16,5.78-23.89A56,56,0,0,1,335.86,97a118.56,118.56,0,0,0,16.2-24.16A1.74,1.74,0,0,0,350.82,72.23Z"
      />
      <path
        class="shifted-primary-color--lighter"
        xmlns="http://www.w3.org/2000/svg"
        d="M529.48,512.16a27.62,27.62,0,0,0-6.69-2.2h0a103.07,103.07,0,0,0-10.55-2.38h0l-.86-.08h0a1.64,1.64,0,0,0-1.51-.42h0a1,1,0,0,0-.45,0c-.71-.65-1.6-.41-2.43-.46a.81.81,0,0,0-.41,0c-.73-.64-1.62-.4-2.46-.46-.7-.66-1.58-.4-2.4-.44h0c-5.79-1.09-11.66-1.71-17.51-2.33-6.26-.65-12.55-1.13-18.83-1.42-5.42-.24-10.85-.43-16.27-.48-7.82-.09-15.65-.33-23.47.27-4.53.34-9.09.21-13.63.45q-11.47.6-22.91,1.49c-5.33.4-10.64.94-16,1.43-7.38.68-14.73,1.55-22.09,2.4-7.75.9-15.48,2-23.21,3.08-8.66,1.24-17.3,2.67-25.94,4.11-10.37,1.73-20.71,3.66-31,5.56-12.14,2.23-24.18,5-36.33,7.2-7.28,1.32-14.58,2.58-21.92,3.53-7.89,1-15.77,2.18-23.68,3.11-7.12.83-14.25,1.5-21.38,2.18-5.48.52-11,.75-16.47,1s-11.17.09-16.76.43a102.7,102.7,0,0,1-10.52-.2c-6.46-.3-12.93-.13-19.38-.62-3.26-.25-6.55-.23-9.79-.62-2.07-.25-4.14-.24-6.2-.47C83.3,535.14,78.17,534.49,73,534c-6.19-.63-12.32-1.64-18.44-2.64Q46.21,530,37.9,528.23c-9.69-2-19.27-4.35-28.83-6.86-3-.79-5.88-2-9-2.32v4.32C12,527,23.87,530.56,36,533.38q14.43,3.36,29,5.93c12.71,2.25,25.51,3.83,38.35,5.06,7.38.71,14.79,1.17,22.19,1.45s15,.37,22.43.25c7-.12,14-.06,21-.51s14.15-.94,21.22-1.49c7.24-.55,14.44-1.39,21.57-2.61,9.26-1.58,18.5-3.32,27.68-5.33,12.76-2.78,25.44-6,38.19-8.81,8.62-1.93,17.3-3.62,26-5.42,9.67-2,19.39-3.76,29.12-5.43,5.26-.91,10.52-1.84,15.79-2.65,6.06-.93,12.13-1.78,18.21-2.61,5.84-.79,11.68-1.52,17.53-2.23,3.56-.43,7.13-.77,10.69-1.13,4.36-.44,8.72-.92,13.09-1.25,2.94-.22,5.9-.35,8.82-.65,6.75-.69,13.53-.95,20.29-1.17,8-.26,15.93-.44,23.9-.25,7.42.18,14.85.11,22.26.71,2.54.2,5.09.29,7.63.56a83.63,83.63,0,0,0,8.8.8c.72.66,1.6.4,2.44.42h0l1.39.07c.72.66,1.61.39,2.45.43h0l.22,0h.22c.89.69,1.93.4,2.92.45h0l.22,0h.22a2,2,0,0,0,.61.35c3.84.46,7.6,1.38,11.4,2.05a1.28,1.28,0,0,0,.6.19A39.28,39.28,0,0,0,529.48,512.16Z"
      />
      <path
        class="primary-color--very-dark"
        xmlns="http://www.w3.org/2000/svg"
        d="M594.69,618c-5.76,1.89-11.36,4.17-17,6.41-8.14,3.25-16.28,6.56-24.7,9-8.11,2.34-16.26,4.58-24.3,7.16-7.59,2.44-15.4,4.1-23.13,6-12.38,3.06-24.6,6.73-37,9.56a131.58,131.58,0,0,1-16.46,3c-6.83.67-13.67,1.22-20.45,2.34a104.49,104.49,0,0,0-27.21,8c-2.45,1.14-5,2.15-7.5,3.13a143.52,143.52,0,0,0-34.71,19.55,71.34,71.34,0,0,0-15.73,16c-2.83,4.06-5.63,8.14-8.47,12.2A133.48,133.48,0,0,0,328,737c-3.47,6.92-7,13.84-11,20.44-4.34,7.08-8.51,14.26-12.72,21.41-2.79,4.74-5.26,9.67-7.74,14.58-4.25,8.44-7.17,17.39-10.17,26.31-2.51,7.48-5.05,14.95-8.15,22.21H386.61a4,4,0,0,0,1-2.15c1.33-7.27,4-14.12,6.44-21.07a167.48,167.48,0,0,1,12.89-29.36c6.09-10.51,12.11-21.05,16.9-32.26,7.18-16.8,19.7-28.1,36.78-34.28A118,118,0,0,1,509,715.72c10.1.67,20.25,1.18,30.38,1.13,6.72,0,13.42.37,20.11.68,5.17.23,10.36.32,15.54.62,5.72.33,11.47.72,17.22.62,1,0,2.36.37,2.7-1.24q0-48.39,0-96.79C595,619.82,595.28,618.83,594.69,618Z"
      />
      <g class="primary-color--very-dark" xmlns="http://www.w3.org/2000/svg">
        <path d="M488.1,727.47a6,6,0,1,0,.15,12.08,6,6,0,0,0-.15-12.08Z" />
        <path d="M477,831.8a4.79,4.79,0,0,0-4.84,3.5,4.74,4.74,0,0,0,2.15,5.36c.47.3,1,.51,1.48.76v.48h1.92a2.26,2.26,0,0,0,0-.45c.5-.23,1-.42,1.51-.7a4.79,4.79,0,0,0-2.23-9Z" />
        <path d="M461.37,804a3.72,3.72,0,0,0-3.63,3.7,3.64,3.64,0,0,0,3.54,3.51,3.54,3.54,0,0,0,3.72-3.53A3.67,3.67,0,0,0,461.37,804Z" />
        <path d="M441.9,816a3,3,0,1,0,3.15,2.89A3.08,3.08,0,0,0,441.9,816Z" />
        <path d="M555.44,720.36a3,3,0,1,0,3,2.81A2.94,2.94,0,0,0,555.44,720.36Z" />
        <path d="M427,831.59a1.88,1.88,0,0,0-1.93,1.73,1.85,1.85,0,0,0,3.7.17A1.94,1.94,0,0,0,427,831.59Z" />
        <path d="M507.25,769.05a2,2,0,0,0-1.94,1.74,1.92,1.92,0,0,0,1.71,2,1.88,1.88,0,0,0,1.92-1.73A1.91,1.91,0,0,0,507.25,769.05Z" />
        <path d="M500.41,791.74a2,2,0,0,0-2,1.74,1.88,1.88,0,0,0,1.74,1.91,1.91,1.91,0,0,0,1.95-1.71A1.93,1.93,0,0,0,500.41,791.74Z" />
        <path d="M460.51,831.78a1.86,1.86,0,0,0-1.91,1.73,1.83,1.83,0,0,0,1.79,1.85,1.86,1.86,0,0,0,1.91-1.73A1.85,1.85,0,0,0,460.51,831.78Z" />
        <path d="M470.64,775.36a1.93,1.93,0,0,0-1.75,1.9,1.8,1.8,0,1,0,1.75-1.9Z" />
        <path d="M526.61,830.22a1.8,1.8,0,0,0-1.87,1.75,1.82,1.82,0,0,0,1.79,1.82,1.79,1.79,0,1,0,.08-3.57Z" />
        <path d="M516.39,821.24a1.8,1.8,0,1,0,1.74,1.85A1.78,1.78,0,0,0,516.39,821.24Z" />
        <path d="M490.13,812.09a1.78,1.78,0,1,0,.19,3.56,1.81,1.81,0,0,0,1.75-1.87A1.84,1.84,0,0,0,490.13,812.09Z" />
        <path d="M460.08,799.74a1.73,1.73,0,0,0,1.88-1.68,1.71,1.71,0,0,0-1.7-1.86,1.77,1.77,0,1,0-.18,3.54Z" />
        <path d="M586,756.49a1.79,1.79,0,0,0-1.87,1.75,1.82,1.82,0,1,0,1.87-1.75Z" />
        <path d="M550.87,782.44a1.84,1.84,0,0,0-1.76,1.86,1.79,1.79,0,1,0,3.58-.06A1.83,1.83,0,0,0,550.87,782.44Z" />
        <path d="M532.25,736.45a1.86,1.86,0,0,0-1.91,1.72,1.9,1.9,0,0,0,1.69,2,1.86,1.86,0,0,0,1.9-1.73A1.84,1.84,0,0,0,532.25,736.45Z" />
      </g>
    </svg>
    {cover}
  </div>
);

export const coverLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "cover",
      height: DIN_A4_HEIGHT_IN_PX,
      width: DIN_A4_WIDTH_IN_PX,
    },
  ],
};
