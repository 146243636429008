import React from "react";
import { SkillCategoryForm, SkillForm } from "@apply-high/interfaces";
import { Button, MoveButtons } from "@apply-high/components";

import { ReactComponent as Check } from "../assets/svgs/check-light.svg";
import { ReactComponent as Cross } from "../assets/svgs/times-light.svg";
import { ReactComponent as Sort } from "../assets/svgs/sort-light.svg";
import { ReactComponent as Edit } from "../assets/svgs/edit-light.svg";
import { ReactComponent as Trash } from "../assets/svgs/trash-alt-light.svg";
import { getDescriptionByLevel, isLanguageCategory } from "../Util/skills";

type SortableListProps = {
  skillCategory: SkillCategoryForm;
  onEdit: () => void;
  onRemove: () => void;
  onSave: (skillCategory: SkillCategoryForm) => void;
};

type SortableListState = {
  isSortable: boolean;
  skills: Array<SkillForm>;
};

class SortableList extends React.Component<
  SortableListProps,
  SortableListState
> {
  constructor(props: SortableListProps) {
    super(props);

    this.state = {
      isSortable: false,
      skills: props.skillCategory.skills,
    };
  }

  moveSkill = (fromIndex: number, toIndex: number) => {
    this.setState((prevState) => {
      const skillsCopy = [...prevState.skills];

      const skill = skillsCopy.splice(fromIndex, 1)[0];
      skillsCopy.splice(toIndex, 0, skill);

      return {
        skills: skillsCopy,
      };
    });
  };

  render() {
    const { isSortable, skills } = this.state;
    const { skillCategory, onEdit, onRemove, onSave } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center justify-content-between py-1">
          <div>
            <span>{skillCategory.name}</span>
            <small className="text-muted fw-light ms-1">
              {skillCategory.skills.length === 1
                ? "(1 Eintrag)"
                : `(${skillCategory.skills.length} Einträge)`}
            </small>
          </div>
          <div className="d-flex">
            {isSortable ? (
              <>
                <Button
                  circled
                  variant="easy"
                  title="Speichern"
                  onClick={() => {
                    this.setState({ isSortable: false });
                    const updatedSkillCategory: SkillCategoryForm = {
                      ...skillCategory,
                      skills: skills.map((skill, index) => ({
                        ...skill,
                        sort: index,
                      })),
                    };
                    onSave(updatedSkillCategory);
                  }}
                >
                  <Check width={20} />
                </Button>
                <Button
                  circled
                  variant="easy"
                  title="Abbrechen"
                  onClick={() => {
                    this.setState({
                      isSortable: false,
                      skills: skillCategory.skills,
                    });
                  }}
                >
                  <Cross width={16} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  circled
                  variant="easy"
                  title="Sortieren"
                  onClick={() => this.setState({ isSortable: true })}
                >
                  <Sort width={20} />
                </Button>
                <Button
                  circled
                  variant="easy"
                  title="Bearbeiten"
                  onClick={onEdit}
                >
                  <Edit width={20} />
                </Button>
                <Button
                  circled
                  variant="easy"
                  title="Entfernen"
                  onClick={onRemove}
                >
                  <Trash width={16} />
                </Button>
              </>
            )}
          </div>
        </div>
        <ul className="list-unstyled">
          {skills.map((skill, index) => (
            <li key={skill.id} className="d-flex align-items-center small py-1">
              {isSortable && (
                <MoveButtons
                  isUpEnabled={index !== 0}
                  onUp={() => this.moveSkill(index, index - 1)}
                  isDownEnabled={index !== skills.length - 1}
                  onDown={() => this.moveSkill(index, index + 1)}
                />
              )}
              <div>
                <div>{skill.name}</div>
                {skill.level && (
                  <div className="text-muted fw-light">
                    {isLanguageCategory(skillCategory.name) === true &&
                      getDescriptionByLevel(skill.level)}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default SortableList;
