import * as h from "vhtml";
import { PortraitContent } from "@apply-high/interfaces";
import { PersonalDataContent } from "@apply-high/interfaces";

import {
  AutoFittedText,
  CroppedImage,
  DIN_A4_WIDTH_IN_PX,
  DIN_A4_HEIGHT_IN_PX,
} from "../../../utility";
import { fontStyles } from "../font_styles";

type CoverSectionTemplating = {
  coverImageContent: PortraitContent;
  name: string;
};

export const renderCoverSection = ({
  coverImageContent,
  name,
}: CoverSectionTemplating): string => (
  <div class="cover">
    <div class="cover__middle">
      <CroppedImage
        containerWidth={DIN_A4_WIDTH_IN_PX}
        portraitContent={coverImageContent}
      />
    </div>
    <div class="cover__content">
      <AutoFittedText
        className="cover__headline"
        text="BEWERBUNG"
        widthInPx={456}
        isUpperCase={true}
        fontFamily={fontStyles.coverHeadline.family}
      />
      <AutoFittedText
        className="cover__name"
        text={name}
        widthInPx={456}
        maxHeightInPx={180}
        fontFamily={fontStyles.autoFittedName.family}
      />
    </div>
    <div class="cover__bottom-line"></div>
  </div>
);

export const constructCoverSection = (personalData: PersonalDataContent) => ({
  containerPreference: ["cover"],
  height: DIN_A4_HEIGHT_IN_PX,
  tags: ["half_length", "cover"],
  render: () =>
    renderCoverSection({
      coverImageContent: personalData.coverImage!,
      name: `${personalData.firstName ?? ""} ${personalData.lastName ?? ""}`,
    }),
});
