import { EducationForm, WorkExperienceForm } from "@apply-high/interfaces";
import parse from "date-fns/parse";
import compareDesc from "date-fns/compareDesc";

export const formatHeader = (entry: WorkExperienceForm | EducationForm) => {
  if (entry.end === "") {
    return `${entry.start} - Heute`;
  }

  return `${entry.start} - ${entry.end}`;
};

export const download = (file, name) => {
  // https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
  const url = window.URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const sortDescByEndDate = <
  T extends {
    end: string;
  }
>(
  list: Array<T>
): Array<T> => {
  return list.sort((a, b) => {
    const aEndDate =
      a.end.trim() === "" ? new Date() : parse(a.end, "MM.yyyy", new Date());
    const bEndDate =
      b.end.trim() === "" ? new Date() : parse(b.end, "MM.yyyy", new Date());

    return compareDesc(aEndDate, bEndDate);
  });
};
