import { ReactComponent as Left } from "../assets/svgs/arrow-left-light.svg";
import { ReactComponent as Right } from "../assets/svgs/arrow-right-light.svg";
import React, { useState } from "react";
import EducationEditor from "../Forms/EducationEditor";
import { Steps } from "./Steps";
import { JobApplication } from "@apply-high/interfaces";
import { Button, FadeIn } from "@apply-high/components";
import { Link } from "react-router-dom";

type EducationStepProps = {
  jobApplication: JobApplication;
  updateJobApplication: (updatedJobApplication: JobApplication) => void;
};

const EducationStep: React.FC<EducationStepProps> = ({
  jobApplication,
  updateJobApplication,
}) => {
  const [isInteracting, updateIsInteracting] = useState<boolean>(false);

  return (
    <>
      <FadeIn isActive>
        <EducationEditor
          jobApplication={jobApplication}
          updateJobApplication={updateJobApplication}
          onUpdateMode={updateIsInteracting}
        />
      </FadeIn>
      <div
        className={`d-flex justify-content-between mt-5 ${
          isInteracting ? "invisible" : "visible"
        }`}
      >
        <Button
          variant="easy"
          render={(props) => (
            <Link {...props} to={`../${Steps.WORK_EXPERIENCE}`} />
          )}
        >
          <Left className="me-2" />
          Zurück
        </Button>
        <Button
          variant="strong"
          render={(props) => <Link {...props} to={`../${Steps.SKILLS}`} />}
        >
          Weiter
          <Right className="ms-2" />
        </Button>
      </div>
    </>
  );
};

export default EducationStep;
