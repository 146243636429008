import * as h from "vhtml";
import { Section, WorkExperienceType } from "@apply-high/interfaces";
import { hyphenateSync } from "hyphen/de";

import { fontMap } from "./fontMap";

export type FontStyle = {
  sizeInPx: number;
  family: string;
};

export type FontStyles = {
  [name: string]: FontStyle;
};

export type CssFontStyles = {
  [name: string]: string;
};
export const convertFontStylesToCss = (
  fontStyles: FontStyles
): CssFontStyles => {
  const cssStyles: CssFontStyles = {};
  Object.keys(fontStyles).forEach((key) => {
    const { sizeInPx, family } = fontStyles[key];
    cssStyles[key] = `${sizeInPx}px ${family};`;
  });
  return cssStyles;
};

const measureText = (
  text: string,
  fontFamily: keyof typeof fontMap,
  fontSize: number,
  isUpperCase = false,
  letterSpacing = 0
) => {
  const letterWidthMap = fontMap[fontFamily];
  if (letterWidthMap === undefined) {
    throw new Error(
      `FontFamily ${fontFamily} is not registered in the fontMap. Go to the themeparks font calculator and copy its outputs into the fontMap.`
    );
  }

  const textWithCorrectCase = isUpperCase === true ? text.toUpperCase() : text;

  const textWidth = textWithCorrectCase
    .split("")
    .reduce((totalWidth, letter) => {
      const letterWidthWithFontSize100 =
        (letterWidthMap as any)[letter] === undefined
          ? letterWidthMap["a"] // backup for unexpected chars
          : (letterWidthMap as any)[letter];

      const letterWidth = letterWidthWithFontSize100 * (fontSize / 100);
      return totalWidth + letterWidth + letterSpacing;
    }, 0);

  return textWidth;
};

type Line = {
  content: string;
  justify: boolean;
};

const turnLinesIntoHtml = (
  lines: Array<Line>,
  fontFamily: string,
  fontSize: number,
  lineHeight: number
) => {
  const lineHtml = lines
    .map((line) =>
      line.justify === true
        ? `<div style="text-align: justify; text-align-last: justify">${line.content}</div>`
        : line.content + "<br />"
    )
    .join("");

  return `
<div style="font-family: '${fontFamily}'; font-size: ${fontSize}px; line-height: ${lineHeight}px; white-space: nowrap">
  ${lineHtml}
</div>
`;
};

const hyphenate = (word: string): Array<string> => {
  const hyphenChar = "­"; // <- this is no ordinary '-'
  const originalHyphens = hyphenateSync(word).split(hyphenChar)!;
  // when the first or last hyphen only has two letters, spreading
  // them across lines looks odd. Therefore I merge them with their neighbour hyphens
  let hyphens: Array<string> = originalHyphens;
  if (originalHyphens[0].length <= 2) {
    hyphens = originalHyphens.slice(1)!;
    hyphens[0] = originalHyphens[0] + originalHyphens[1];
  }
  if (
    originalHyphens[originalHyphens.length - 1].replace(/;|,|\?|\./g, "")
      .length <= 2
  ) {
    hyphens = originalHyphens.slice(0, -1)!;
    hyphens[hyphens.length - 1] =
      originalHyphens[originalHyphens.length - 2] +
      originalHyphens[originalHyphens.length - 1];
  }
  return hyphens;
};

export const processLineBreaks = (
  text: string,
  enterLine: (line: string, justify: boolean) => void,
  fontFamily: keyof typeof fontMap,
  fontSize: number,
  containerWidth: number,
  justifyText: boolean,
  letterSpacing = 0
): void => {
  const words = text.split(" ");
  let currentLineText = "";

  type WordHyphenation = { start: string; end: string };
  const findFittingWordHyphenation = (
    word: string
  ): WordHyphenation | undefined => {
    const hyphens = hyphenate(word);

    for (let i = 1; i < hyphens.length; i++) {
      const wordMinusTrailingSyllables = hyphens.slice(0, -i).join("");
      const lineWidthWithNewSyllable = measureText(
        currentLineText + " " + wordMinusTrailingSyllables + "-",
        fontFamily,
        fontSize,
        false,
        letterSpacing
      );

      if (lineWidthWithNewSyllable < containerWidth) {
        return {
          start: wordMinusTrailingSyllables,
          end: hyphens.slice(hyphens.length - i).join(""),
        };
      }
    }
  };

  while (words.length !== 0) {
    const nextWord = words.shift()!;
    const lineWidthWithNewWord = measureText(
      currentLineText + " " + nextWord,
      fontFamily,
      fontSize,
      false,
      letterSpacing
    );

    // if word does not fit into line
    if (lineWidthWithNewWord > containerWidth) {
      const hyphenatedWord = findFittingWordHyphenation(nextWord);
      // if we can hyphenate the word, add one part with -, add other part on next line
      if (hyphenatedWord !== undefined) {
        currentLineText = currentLineText + hyphenatedWord.start + "-";
        enterLine(currentLineText, justifyText);
        currentLineText = hyphenatedWord.end + " ";
        continue;
      }
      // if we cannot hyphenate the word, enter line, start next line with the word
      enterLine(currentLineText, justifyText);
      currentLineText = nextWord + " ";
    } else {
      currentLineText += nextWord + " ";
    }
  }
  enterLine(currentLineText, false);
};

type SeperateLetterIntoPartsParameters = {
  tsx?: boolean;
  letter?: string;
  lineHeight?: number;
  letterSpacing?: number;
  font: FontStyle;
  containerWidth: number;
  firstPageHeight: number;
  laterPagesHeight: number;
  constructFirstSection(text: string): Section;
  constructLaterSection(text: string): Section;
};

export const separateLetterIntoParts = (
  parameters: SeperateLetterIntoPartsParameters
): Array<Section> => {
  const {
    letter,
    lineHeight,
    containerWidth,
    firstPageHeight,
    laterPagesHeight,
    constructFirstSection,
    constructLaterSection,
    letterSpacing,
  } = parameters;

  if (letter === undefined) {
    // in case of preview we might want to render empty letter section
    return [constructFirstSection("")];
  }
  const fontFamily = parameters.font.family as keyof typeof fontMap;
  const fontSize = parameters.font.sizeInPx;

  const lineHeightInPx = lineHeight === undefined ? fontSize * 1.5 : lineHeight;

  const explicitLines = letter.split("\n");

  type Page = Array<Line>;
  const pages: Array<Page> = [[]];
  const enterLine = (line: string, justify: boolean): void => {
    const numberOfLines = pages[pages.length - 1].length + 1;
    const pageHeight = numberOfLines * lineHeightInPx;
    const fits =
      pages.length === 1
        ? pageHeight < firstPageHeight
        : pageHeight < laterPagesHeight;

    if (fits === false) {
      pages.push([]);
    }
    pages[pages.length - 1].push({
      content: line,
      justify: justify,
    });
  };

  explicitLines.forEach((text) =>
    processLineBreaks(
      text,
      enterLine,
      fontFamily,
      fontSize,
      containerWidth,
      true,
      letterSpacing
    )
  );

  const sections = pages
    .map((page) =>
      turnLinesIntoHtml(page, fontFamily, fontSize, lineHeightInPx)
    )
    .map((text, index) => {
      // TODO: escape html of input for this
      text = (h as any)(null, {
        dangerouslySetInnerHTML: {
          __html: text,
        },
      });
      return index === 0
        ? constructFirstSection(text)
        : constructLaterSection(text);
    });
  return sections;
};

type WorkExperienceTypeMap = {
  [propertyName in WorkExperienceType]: string;
};

const workExperienceTypeTranslationMap: WorkExperienceTypeMap = {
  employed: "Angestellt",
  internship: "Praktikum",
  self_employed: "Selbstständig",
  voluntary: "Ehrenamtlich",
  working_student: "Werkstudent",
  apprenticeship: "Ausbildung",
};

export const getTranslatedWorkExperienceType = (
  jobType: WorkExperienceType
): string => workExperienceTypeTranslationMap[jobType];

export const formatContactPerson = (
  contactPerson?: string
): string | undefined => {
  if (contactPerson === undefined) {
    return undefined;
  }

  // remove leading z.hd.; make leading 'Herr' dativ;
  const preformattedContactPerson = contactPerson
    .replace(/^z\.\s?hd\.\s?/i, "")
    .replace(/^Herr(?!n)/, "Herrn");

  return `z. Hd. ${preformattedContactPerson}`;
};
