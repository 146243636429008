import { ThemeConfigurationConstructor } from "@apply-high/interfaces";
import * as chroma from "chroma-js";

import { cssFontStyles } from "./font_styles";
import { constructCoverSection } from "./sections/cover.section";
import { constructLetterSections } from "./sections/letter.section";
import { constructPersonalDataSection } from "./sections/personal_data.section";
import { constructWorkExperiencesSections } from "./sections/work_experience.section";
import { constructEducationSections } from "./sections/education.section";
import { constructLanguageSections } from "./sections/languages.section";
import { constructSkillsSections } from "./sections/skills.section";
import {
  coverLayout,
  letterLayout,
  letterFollowUpLayout,
  personalDataLayout,
  cvLayout,
} from "./layouts/index";
import { ThemeSettings } from "../../interfaces";
import { staticResources } from "./static";

export const Abelson: ThemeConfigurationConstructor = (
  content,
  colorScheme
) => {
  const { primaryColor } = colorScheme;
  // generates a secondary color with shifted hue value.
  const shiftedPrimaryColor = chroma(primaryColor).set(
    "hsl.h",
    chroma(primaryColor).get("hsl.h") + 340
  );

  return {
    globalParameters: {
      ...cssFontStyles,
      primaryColor__darker: chroma(primaryColor).darken(0.2).hex(),
      primaryColor__darker__desaturated: chroma(primaryColor)
        .darken(0.1)
        .desaturate(0.2)
        .hex(),
      primaryColor__veryDark: chroma(primaryColor).darken(4.2).hex(),
      shiftedPrimaryColor: shiftedPrimaryColor.darken(1.5).saturate(1.5).hex(),
      shiftedPrimaryColor__lighter: shiftedPrimaryColor
        .darken(0.8)
        .saturate(1)
        .hex(),
    },
    css: ["index.css"],
    cssResources: [staticResources["index.css"].content],
    fontResources: [
      {
        fontFamily: "Merriweather-Regular",
        resource: staticResources["fonts/Merriweather-Regular.woff2"],
      },
      {
        fontFamily: "Merriweather-Black",
        resource: staticResources["fonts/Merriweather-Black.woff2"],
      },
      {
        fontFamily: "Merriweather-Light",
        resource: staticResources["fonts/Merriweather-Light.woff2"],
      },
      {
        fontFamily: "Merriweather-Bold",
        resource: staticResources["fonts/Merriweather-Bold.woff2"],
      },
    ],
    pageLayouts: [
      coverLayout,
      letterLayout,
      letterFollowUpLayout,
      personalDataLayout,
      cvLayout,
    ],
    sections: [
      constructCoverSection(content.personalData),
      ...constructLetterSections(content),
      constructPersonalDataSection(content.personalData),
      ...constructWorkExperiencesSections(content.workExperiences),
      ...constructEducationSections(content.education),
      ...constructLanguageSections(content.languages),
      ...constructSkillsSections(content.skills),
    ],
  };
};

export const abelsonSettings: ThemeSettings = {
  themeConfigurationConstructor: Abelson,
  staticSettings: {
    portraits: [],
    colors: [
      {
        id: "brown",
        primaryColor: "#f3e8d6",
        secondaryColor: "#000000",
      },
      {
        id: "blue",
        primaryColor: "#d6ebf3",
        secondaryColor: "#000000",
      },
      {
        id: "red",
        primaryColor: "#f3d6d6",
        secondaryColor: "#000000",
      },
      {
        id: "green",
        primaryColor: "#dbf3d6",
        secondaryColor: "#81bb8b",
      },
      {
        id: "purple",
        primaryColor: "#f3d6f3",
        secondaryColor: "#aabccb",
      },
    ],
  },
};
