import React from "react";
import { v4 as uuid } from "uuid";
import {
  JobApplication,
  SkillCategoryForm as SkillCategoryFormFields,
} from "@apply-high/interfaces";
import { Button, Divider } from "@apply-high/components";

import SortableList from "../Components/SortableList";
import SkillCategoryForm from "./SkillCategoryForm";
import { ReactComponent as Plus } from "../assets/svgs/plus-light.svg";
import {
  getDefaultSkillEntryByCategoryName,
  getSkillCategorySuggestions,
} from "../Util/skills";
import CoreCompetenciesEditor from "./CoreCompetenciesEditor";

type SkillCategoryEditorProps = {
  jobApplication: JobApplication;
  updateJobApplication: (updatedJobApplication: JobApplication) => void;
  onUpdateMode: (isInteracting: boolean) => void;
  industry: string;
};

type SkillCategoryEditorState = {
  isCreateFormOpen: boolean;
  editId: string | null;
  values: SkillCategoryFormFields | null;
};

class SkillCategoryEditor extends React.Component<
  SkillCategoryEditorProps,
  SkillCategoryEditorState
> {
  constructor(props: SkillCategoryEditorProps) {
    super(props);

    this.state = {
      isCreateFormOpen: false,
      editId: null,
      values: null,
    };
  }

  public render() {
    const { isCreateFormOpen, editId } = this.state;
    const { jobApplication, industry } = this.props;
    const { skillCategories } = jobApplication;
    const suggestions = getSkillCategorySuggestions(skillCategories, industry);
    const hasCreateButton =
      skillCategories.length > 0 &&
      isCreateFormOpen === false &&
      editId === null;

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2 className="h4 m-0">
            Fähigkeiten{" "}
            {isCreateFormOpen
              ? " hinzufügen"
              : editId !== null
              ? " bearbeiten"
              : null}
          </h2>
          <div className={hasCreateButton === true ? "visible" : "invisible"}>
            <div className="d-none d-md-inline-flex">
              <Button
                variant="outline"
                onClick={this.enableCreateMode}
                disabled={hasCreateButton === false}
              >
                <Plus className="me-2" />
                Kategorie hinzufügen
              </Button>
            </div>
            <div className="d-md-none d-sm-inline-flex">
              <Button
                circled
                variant="outline"
                onClick={this.enableCreateMode}
                disabled={hasCreateButton === false}
              >
                <Plus />
              </Button>
            </div>
          </div>
        </div>
        {this.renderSkillCategories(suggestions)}
        {this.renderCreateForm(suggestions)}
        {this.renderEditForm(suggestions)}
      </>
    );
  }

  renderSkillCategories = (suggestions: Array<string>) => {
    const { isCreateFormOpen, editId } = this.state;
    const { jobApplication } = this.props;
    const { skillCategories } = jobApplication;

    if (isCreateFormOpen === true || editId !== null) {
      return null;
    }

    if (skillCategories.length === 0) {
      return (
        <>
          <div className="text-muted mb-2">Wähle aus unseren Vorschlägen.</div>
          {this.renderSuggestions(suggestions)}
          <Divider text="oder" />
          <div className="text-muted mb-2">
            Füge Deine eigenen Kategorien hinzu.
          </div>
          <Button variant="outline" onClick={this.enableCreateMode}>
            <Plus className="me-2" />
            Kategorie hinzufügen
          </Button>
        </>
      );
    }

    return (
      <>
        <ul className="list-unstyled mb-5">
          {jobApplication.skillCategories.map((skillCategory) => (
            <li key={skillCategory.id} className="mb-4">
              <SortableList
                skillCategory={skillCategory}
                onEdit={() => this.enableEditMode(skillCategory.id)}
                onRemove={() => this.deleteSkillCategory(skillCategory)}
                onSave={(skillCategory) =>
                  this.editSkillCategory(skillCategory)
                }
              />
            </li>
          ))}
        </ul>
        {skillCategories.length > 0 && (
          <div className="mb-5">
            <CoreCompetenciesEditor
              skillCategories={skillCategories}
              onChangeCoreCompetency={this.editSkillCategory}
            />
          </div>
        )}
      </>
    );
  };

  renderSuggestions = (suggestions: Array<string>) => {
    return (
      <div className="d-flex flex-wrap gap-2">
        {suggestions.map((suggestion) => (
          <Button
            key={suggestion}
            size="small"
            variant="outline"
            title="Vorschlag wählen"
            onClick={() => this.onSuggestionClick(suggestion)}
          >
            <span className="me-2">{suggestion}</span>
            <Plus />
          </Button>
        ))}
      </div>
    );
  };

  renderCreateForm = (suggestions: Array<string>) => {
    const { isCreateFormOpen, values } = this.state;

    if (isCreateFormOpen === false) {
      return null;
    }

    return (
      <SkillCategoryForm
        type="create"
        values={
          values ?? {
            id: uuid(),
            name: "",
            skills: [],
          }
        }
        suggestions={suggestions}
        onSubmit={(skillCategory) => this.createSkillCategory(skillCategory)}
        onAbort={this.disableCreateMode}
      />
    );
  };

  renderEditForm = (suggestions: Array<string>) => {
    const { editId } = this.state;
    const { jobApplication } = this.props;
    const skillCategory = jobApplication.skillCategories.find(
      (skillCategory) => skillCategory.id === editId
    );

    if (skillCategory === undefined) {
      return null;
    }

    return (
      <SkillCategoryForm
        type="edit"
        values={skillCategory}
        suggestions={suggestions}
        onSubmit={(skillCategory) => this.editSkillCategory(skillCategory)}
        onAbort={this.disableEditMode}
      />
    );
  };

  private createSkillCategory = async (
    skillCategory: SkillCategoryFormFields
  ) => {
    const { jobApplication } = this.props;

    await this.saveSkillCategories([
      ...jobApplication.skillCategories,
      skillCategory,
    ]);

    this.disableCreateMode();
  };

  private editSkillCategory = async (
    updatedSkillCategory: SkillCategoryFormFields
  ) => {
    const { jobApplication } = this.props;

    await this.saveSkillCategories(
      jobApplication.skillCategories.map((skillCategory) => {
        if (skillCategory.id === updatedSkillCategory.id) {
          return updatedSkillCategory;
        }

        return skillCategory;
      })
    );

    this.disableEditMode();
  };

  private deleteSkillCategory = async (
    deletedSkillCategory: SkillCategoryFormFields
  ) => {
    const { jobApplication } = this.props;

    await this.saveSkillCategories(
      jobApplication.skillCategories.filter(
        (skillCategory) => skillCategory.id !== deletedSkillCategory.id
      )
    );
  };

  private saveSkillCategories = async (
    skillCategories: Array<SkillCategoryFormFields>
  ) => {
    const { jobApplication, updateJobApplication } = this.props;

    updateJobApplication({
      ...jobApplication,
      skillCategories: skillCategories,
    });
  };

  private onSuggestionClick = (suggestion: string) => {
    this.setState({
      isCreateFormOpen: true,
      values: {
        id: uuid(),
        name: suggestion,
        skills: [getDefaultSkillEntryByCategoryName(suggestion, 0)],
      },
    });
  };

  private enableCreateMode = () => {
    this.setState({ isCreateFormOpen: true });
    this.props.onUpdateMode(true);
  };

  private disableCreateMode = () => {
    this.setState({ isCreateFormOpen: false, values: null });
    this.props.onUpdateMode(false);
  };

  private enableEditMode = (editId: string) => {
    this.setState({ editId });
    this.props.onUpdateMode(true);
  };

  private disableEditMode = () => {
    this.setState({ editId: null });
    this.props.onUpdateMode(false);
  };
}

export default SkillCategoryEditor;
