export const jobApplicationCSS = `body {
  background-color: transparent !important;
}

.watermark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0.7;
}

.din-a4-portrait {
  position: relative;
  width: 210mm;
  height: 297.12mm; /* determined by testing: 0.01mm more leads to an additional A4-Page */
  background-color: white;
  overflow: hidden;
  margin: 0 auto;
}
`;
