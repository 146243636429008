import { ThemeConfigurationConstructor } from "@apply-high/interfaces";

import { cssFontStyles } from "./font_styles";
import { constructPersonalDataSection } from "./sections/personal_data.section";
import { constructSkillsSection } from "./sections/skills.section";
import { constructTimelines } from "./sections/construct_timelines";
import { splitLayout, standardLayout } from "./layouts";
import { ThemeSettings } from "../../interfaces";
import { staticResources } from "./static";

export const Zuse: ThemeConfigurationConstructor = (content, colorScheme) => ({
  globalParameters: {
    primaryColor: colorScheme.primaryColor,
    ["background-first-site"]: `url("${staticResources["background-first-site.jpg"].content}")`,
    ["background-sidebar"]: `url("${staticResources["background-sidebar.png"].content}")`,
    ["background-second-site"]: `url("${staticResources["background-second-site.jpg"].content}")`,
    ...cssFontStyles,
  },
  css: ["index.css"],
  cssResources: [staticResources["index.css"].content],
  fontResources: [
    {
      fontFamily: "Lato-Regular",
      resource: staticResources["fonts/Lato-Regular.woff2"],
    },
    {
      fontFamily: "Lato-Bold",
      resource: staticResources["fonts/Lato-Bold.woff2"],
    },
    {
      fontFamily: "Lato-Light",
      resource: staticResources["fonts/Lato-Light.woff2"],
    },
  ],
  pageLayouts: [splitLayout, standardLayout],
  sections: [
    constructPersonalDataSection(
      content.personalData,
      content.languages,
      content.coreCompetencies
    ),
    ...constructTimelines(content.education, content.workExperiences),
    constructSkillsSection(content.skills),
  ],
});

export const zuseSettings: ThemeSettings = {
  themeConfigurationConstructor: Zuse,
  staticSettings: {
    portraits: [],
    colors: [
      {
        id: "gold",
        primaryColor: "#c8b56e",
        // TODO: Unused? Can be undefined?
        secondaryColor: "pink",
      },
      {
        id: "petrol",
        primaryColor: "#0f475a",
        // TODO: Unused? Can be undefined?
        secondaryColor: "pink",
      },
    ],
  },
};
