import * as h from "vhtml";
import { LanguageContent, SkillCategoryContent } from "@apply-high/interfaces";

type Skill = {
  name: string;
  showDots: boolean;
  dotList: Array<null>;
};

type RenderSkillsParams = {
  index: number;
  height: number;
  skillCategories: Array<{
    name: string;
    skills: Array<Skill>;
  }>;
  showHeadline: boolean;
};
const renderSkillsSection = ({
  index,
  height,
  showHeadline,
  skillCategories,
}: RenderSkillsParams) => (
  <div
    class="skills"
    data-section-name={`skills-row:${index}`}
    data-assumed-height={height}
  >
    {showHeadline === true ? (
      <h1 class="skills__headline">
        <span>Fähigkeiten</span>
      </h1>
    ) : (
      ""
    )}
    <div class="skills__container">
      {skillCategories.map(({ name, skills }) => (
        <div class="skills__category">
          <div class="skills__category-title">{name}</div>
          <div class="skill">
            <table>
              {skills.map(({ name, showDots, dotList }) => (
                <tr>
                  <td>• {name}</td>
                  {showDots === true ? (
                    <td class="skill__name-level">
                      {dotList.map(() => (
                        <div class="skill__dot"></div>
                      ))}
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
            </table>
          </div>
        </div>
      ))}
    </div>
  </div>
);
export const constructSkillsSections = (
  languages: Array<LanguageContent>,
  skillCategories: Array<SkillCategoryContent>
) => {
  const allSkillCategories: Array<{
    name: string;
    skills: Array<Skill>;
  }> = skillCategories.map((category) => ({
    name: category.name,
    skills: category.skills.map((skill) => ({
      showDots: false,
      dotList: [],
      name: skill,
    })),
  }));

  if (languages.length !== 0) {
    allSkillCategories.unshift({
      name: "Sprachen",
      skills: languages.map((language) => ({
        name:
          language.isOfficialLanguageLevel === true
            ? `${language.name} (${language.level})`
            : language.name,
        showDots: language.isOfficialLanguageLevel === true,
        dotList:
          language.isOfficialLanguageLevel === false
            ? Array(Math.ceil((language.level as unknown as number) / 25)).fill(
                null
              )
            : [],
      })),
    });
  }

  const sortedSkillCategories = allSkillCategories.sort((a, b) =>
    a.skills.length <= b.skills.length ? 1 : -1
  );

  const sections = [];

  for (let index = 0; sortedSkillCategories.length !== 0; index++) {
    const skillTriple = sortedSkillCategories.splice(0, 3);

    const headerHeightInPx = index === 0 ? 104 : 0;
    const subHeadlineHeightInPx = 29;
    const entryHeightInPx = 21;
    const maxNumberOfEntriesPerColumn = Math.max(
      ...skillTriple.map((category) => category.skills.length)
    );
    const contentHeightInPx = maxNumberOfEntriesPerColumn * entryHeightInPx;
    const marginBottomInPx = 25;
    const rowHeight =
      headerHeightInPx +
      subHeadlineHeightInPx +
      contentHeightInPx +
      marginBottomInPx;

    sections.push({
      containerPreference: ["personal_data", "curriculum_vitae"],
      tags: ["cv"],
      height: rowHeight,
      render: () =>
        renderSkillsSection({
          showHeadline: index === 0,
          skillCategories: skillTriple,
          index: index,
          height: rowHeight,
        }),
    });
  }
  return sections;
};
