import { ThemeConfigurationConstructor } from "@apply-high/interfaces";

import { cssFontStyles } from "./font_styles";
import {
  constructCoverSection,
  constructPersonalDataSection,
  constructEducationSections,
  constructSkillsSection,
  constructWorkExperiencesSections,
  constructLetterSections,
} from "./sections/index";
import { coverLayout, defaultLayout, letterLayout } from "./layouts/index";
import { ThemeSettings } from "../../interfaces";
import { staticResources } from "./static";

export const Turing: ThemeConfigurationConstructor = (content) => ({
  globalParameters: {
    ["bg-cv-right"]: `url("${staticResources["bg_cv_right.png"].content}")`,
    ...cssFontStyles,
  },
  css: ["index.css"],
  cssResources: [staticResources["index.css"].content],
  fontResources: [
    {
      fontFamily: "AllisonScript",
      resource: staticResources["fonts/AllisonScript.woff2"],
    },
    {
      fontFamily: "Lato-Medium",
      resource: staticResources["fonts/LatoLatin-Medium.woff2"],
    },
    {
      fontFamily: "Lato-Light",
      resource: staticResources["fonts/LatoLatin-Light.woff2"],
    },
    {
      fontFamily: "Lato-Bold",
      resource: staticResources["fonts/LatoLatin-Bold.woff2"],
    },
    {
      fontFamily: "Lato-Regular",
      resource: staticResources["fonts/LatoLatin-Regular.woff2"],
    },
  ],
  pageLayouts: [coverLayout, defaultLayout, letterLayout],
  sections: [
    constructCoverSection(content.personalData),
    ...constructLetterSections(content),
    constructPersonalDataSection(content.personalData),
    ...constructWorkExperiencesSections(content.workExperiences),
    ...constructEducationSections(content.education),
    constructSkillsSection(content.languages, content.skills),
  ],
});

export const turingSettings: ThemeSettings = {
  themeConfigurationConstructor: Turing,
  staticSettings: {
    portraits: [
      {
        id: "cover",
        widthToLengthRatio: 0.7142857143,
      },
      {
        id: "cv",
        widthToLengthRatio: 1,
      },
    ],
    colors: [
      {
        id: "red",
        primaryColor: "#6e4444",
        secondaryColor: "#6e4444",
      },
      {
        id: "yellow",
        primaryColor: "#d4c682",
        secondaryColor: "#c2a461",
      },
      {
        id: "green",
        primaryColor: "#5f7356",
        secondaryColor: "#5f7356",
      },
      {
        id: "blue",
        primaryColor: "#445a9a",
        secondaryColor: "#445a9a",
      },
      {
        id: "turquoise",
        primaryColor: "#568288",
        secondaryColor: "#568288",
      },
    ],
  },
};
