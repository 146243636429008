import * as yup from 'yup';
import { billingAddressSchema, paymentDataSchema } from './forms';

export const postBillingAddressRequestSchema = yup
  .object()
  .shape({
    body: billingAddressSchema,
  })
  .strict(true);

export const postPayPalDataRequestSchema = yup
  .object()
  .shape({
    body: paymentDataSchema,
  })
  .strict(true);
