import * as h from "vhtml";
import { WorkExperienceContent } from "@apply-high/interfaces";
import { format, getYear, compareDesc } from "date-fns";

import {
  constructTextWithLineBreaks,
  getTranslatedWorkExperienceType,
} from "../../../utility/index";
import { fontStyles } from "../font_styles";

type RenderWorkExperienceSectionParams = {
  index: number;
  height: number;
  showHeadline: boolean;
  yearHeadline?: string;
  position: string;
  isSingleEntry: boolean;
  timeSpan: string;
  workplace: string;
  employment: string;
  description?: string;
};

const renderWorkExperienceSection = ({
  index,
  height,
  showHeadline,
  yearHeadline,
  position,
  isSingleEntry,
  timeSpan,
  workplace,
  employment,
  description,
}: RenderWorkExperienceSectionParams) => (
  <div
    class="timeline"
    data-section-name={`work-experience__row:${index}`}
    data-assumed-height={height}
  >
    {showHeadline === true ? (
      <div class="work-experience__headline">Berufserfahrung</div>
    ) : (
      ""
    )}
    {yearHeadline !== undefined ? (
      <div class="timeline__year-headline">{yearHeadline}</div>
    ) : (
      ""
    )}
    <div
      class={`timeline__item timeline__item--${position} ${
        isSingleEntry === true ? "timeline__item--single-entry" : ""
      }`}
    >
      {isSingleEntry === false ? <div class="timeline__line"></div> : ""}
      <div class="timeline__time-span">{timeSpan}</div>
      <div class="work-experience__title">{workplace}</div>
      <div class="work-experience__employment">{employment}</div>
      <div class="work-experience__description">{description}</div>
    </div>
  </div>
);

export const constructWorkExperiencesSections = (
  workExperienceItems: Array<WorkExperienceContent>
) => {
  const isSingleEntry = workExperienceItems.length === 1;
  const normaliseDate = (date?: Date) =>
    date === undefined ? new Date() : date;

  type Position = "left" | "right";
  type PreformattedWorkExperienceEntry = {
    yearHeadline?: string;
    position: Position;
    timeSpan: string;
    workplace: string;
    employment: string;
    description?: string;
    height: number;
  };

  let leftOrRightToggle: Position = "left";

  const alreadyHandledYearHeadlines: Array<string> = [];

  const preformattedEntries: Array<PreformattedWorkExperienceEntry> =
    workExperienceItems
      .sort((a, b) => compareDesc(normaliseDate(a.end), normaliseDate(b.end)))
      .map((workExperienceItem, index) => {
        const yearHeadline =
          workExperienceItem.end === undefined
            ? "Heute"
            : getYear(workExperienceItem.end).toString();

        const isTopEntryOfYear =
          alreadyHandledYearHeadlines.includes(yearHeadline) === false;
        if (isTopEntryOfYear === true) {
          alreadyHandledYearHeadlines.push(yearHeadline);
        }
        const currentPosition = leftOrRightToggle;
        leftOrRightToggle = leftOrRightToggle === "left" ? "right" : "left";

        const headlineHeight = index === 0 ? 104 : 0;
        const singleEntryExtraMargin = isSingleEntry === true ? 20 : 0;
        const yearHeadlineHeight =
          isTopEntryOfYear === true && isSingleEntry === false ? 52 : 0;
        const timeSpanHeight = 30;

        let descriptionHeight = 0;
        let descriptionHtmlCode;
        if (typeof workExperienceItem.description === "string") {
          const descriptionText = constructTextWithLineBreaks({
            text: workExperienceItem.description,
            font: fontStyles.baseRegular,
            containerWidth: 275,
            includeExplicitNewLines: true,
          });
          descriptionHeight = descriptionText.height;
          descriptionHtmlCode = descriptionText.htmlCode;
        }

        const workplaceText = constructTextWithLineBreaks({
          text: `${workExperienceItem.workplace}, ${workExperienceItem.location}`,
          font: fontStyles.subHeadline,
          containerWidth: 275,
        });
        const employmentText = constructTextWithLineBreaks({
          text: `${getTranslatedWorkExperienceType(workExperienceItem.type)}, ${
            workExperienceItem.employment
          }`,
          font: fontStyles.baseBold,
          containerWidth: 275,
        });
        const rowHeight =
          headlineHeight +
          singleEntryExtraMargin +
          yearHeadlineHeight +
          timeSpanHeight +
          workplaceText.height +
          employmentText.height +
          descriptionHeight;

        const endDate =
          workExperienceItem.end === undefined
            ? "Heute"
            : format(workExperienceItem.end, "MM/yyyy");

        return {
          yearHeadline: isTopEntryOfYear === true ? yearHeadline : undefined,
          position: currentPosition,
          timeSpan: `${format(
            workExperienceItem.start,
            "MM/yyyy"
          )} - ${endDate}`,
          workplace: workplaceText.htmlCode,
          employment: employmentText.htmlCode,
          description: descriptionHtmlCode,
          height: rowHeight,
        };
      });

  const sections = preformattedEntries.map((workExperience, index) => ({
    containerPreference: ["personal_data_rest", "curriculum_vitae"],
    tags: ["cv"],
    height: workExperience.height,
    render: () =>
      renderWorkExperienceSection({
        showHeadline: index === 0,
        isSingleEntry: isSingleEntry,
        position: workExperience.position,
        yearHeadline: isSingleEntry ? undefined : workExperience.yearHeadline,
        timeSpan: workExperience.timeSpan,
        workplace: workExperience.workplace,
        description: workExperience.description,
        employment: workExperience.employment,
        index: index,
        height: workExperience.height,
      }),
  }));

  return sections;
};
