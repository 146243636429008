import * as yup from 'yup';

import {
  subject,
  letter,
  firstName,
  lastName,
  city,
  postcode,
  street,
  streetNumber,
  telephone,
  email,
  birthday,
  birthplace,
  educationList,
  workExperienceList,
  skillCategoryList,
} from './forms';

export type ValidationContext = 'serialize' | 'form' | 'entity';

// used to check entity before rendering
export const jobApplicationSchema = yup
  .object()
  .shape({
    subject: subject,
    letter: letter,
    firstName: firstName,
    lastName: lastName,
    telephone: telephone,
    email: email,
    birthday: birthday('entity'),
    birthplace: birthplace,
    education: educationList('entity'),
    workExperience: workExperienceList('entity'),
    skillCategories: skillCategoryList('entity'),
  })
  .strict(true);

// used to check demo data
export const renderContentSchema = yup
  .object()
  .shape({
    subject: subject,
    letter: letter,
    personalData: yup.object().shape({
      firstName: firstName,
      lastName: lastName,
      city: city,
      postcode: postcode,
      street: street,
      streetNumber: streetNumber,
      telephone: telephone,
      email: email,
      birthday: birthday('serialize'),
      birthplace: birthplace,
    }),
    education: educationList('serialize'),
    workExperiences: workExperienceList('serialize'),
    skills: skillCategoryList('serialize'),
  })
  .strict(true);
