import * as yup from 'yup';

export const whitelabelOptInSchema = yup
  .object()
  .shape({
    hasOptIn: yup.boolean().oneOf([true], 'Bitte akzeptiere, um Deinen Lebenslauf herunterzuladen.').strict(true),
  })
  .strict(true);

export const partnerOptInSchema = yup
  .object()
  .shape({
    hasOptIn: yup.boolean().oneOf([true], 'Bitte akzeptiere, um Deinen Lebenslauf zu übermitteln.').strict(true),
  })
  .strict(true);
