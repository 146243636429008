import React, { ReactNode } from "react";
import Dropzone, { FileRejection } from "react-dropzone";

interface ImageDragAndDropProps {
  onFileDrop: (file: File) => void;
}

const MAX_FILE_SIZE_IN_BYTES = 12582912;

class ImageDragAndDrop extends React.PureComponent<ImageDragAndDropProps> {
  state = {
    isRejected: false,
  };

  onDrop = (
    acceptedFiles: Array<File>,
    fileRejections: Array<FileRejection>
  ) => {
    const { onFileDrop } = this.props;

    if (fileRejections.length > 0) {
      this.setState({ isRejected: true });
    }

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      onFileDrop(file);
    }
  };

  renderContent = (isDragActive: boolean) => {
    const { isRejected } = this.state;
    let text: ReactNode | string = (
      <span className="text-muted">
        Ziehe ein Bild in diesen Bereich oder klicke, um eins auszuwählen.
      </span>
    );

    if (isRejected) {
      text = (
        <span className="text-danger">
          Das Bild darf eine Größe von 12MB nicht überschreiten.
        </span>
      );
    }

    if (isDragActive) {
      text = (
        <span className="text-muted">
          Lass Dein Bild los, um es hier abzulegen.
        </span>
      );
    }

    return (
      <div className="h-100 d-flex justify-content-center align-items-center p-2">
        <p className="text-center my-3">{text}</p>
      </div>
    );
  };

  render() {
    const { isRejected } = this.state;

    return (
      <Dropzone onDrop={this.onDrop} maxSize={MAX_FILE_SIZE_IN_BYTES}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()}
            className={`border rounded cursor-pointer ${
              isDragActive ? "bg-light" : ""
            } ${isRejected ? "border-danger" : ""}`}
            style={{ height: 300 }}
          >
            <input
              {...getInputProps()}
              type="file"
              multiple={false}
              accept="image/jpeg, image/png"
            />
            {this.renderContent(isDragActive)}
          </div>
        )}
      </Dropzone>
    );
  }
}

export default ImageDragAndDrop;
