import * as yup from 'yup';

export const contactSchema = yup
  .object()
  .shape({
    name: yup.string().required('Bitte gib Deinen Namen an, damit wir wissen wer Du bist.').strict(true),
    email: yup
      .string()
      .email('Bitte gib eine gültige E-Mail Adresse an.')
      .required('Bitte gib Deine E-Mail an, damit wir uns bei Dir zurückmelden können.')
      .lowercase()
      .strict(true),
    topic: yup.string().required('Bitte gib ein Bezug zur Anfrage an.').strict(true),
    message: yup.string().required('Bitte schreibe eine Nachricht, damit wir wissen worum es geht.').strict(true),
    accepted: yup
      .boolean()
      .oneOf([true], 'Bitte akzeptiere, dass wir diese Daten zu Bearbeitungszwecken kurzweilig speichern.')
      .strict(true),
  })
  .strict(true);
