import * as h from "vhtml";
import { PersonalDataContent } from "@apply-high/interfaces";

import { ConstrainedText, DIN_A4_HEIGHT_IN_PX } from "../../../utility/index";
import { fontStyles } from "../font_styles";

type CoverSectionTemplating = {
  firstName: string;
  lastName: string;
};

export const renderCoverSection = ({
  lastName,
  firstName,
}: CoverSectionTemplating): string => (
  <div class="cover">
    <div class="cover__content">
      <div class="cover__headline">Bewerbung</div>
      <div class="cover__name">
        <ConstrainedText
          text={firstName}
          maxWidthInPx={450}
          fontSizeInPx={fontStyles.autoFittedName.sizeInPx}
          fontFamily={fontStyles.autoFittedName.family}
        />
        <ConstrainedText
          text={lastName}
          maxWidthInPx={450}
          fontSizeInPx={fontStyles.autoFittedName.sizeInPx}
          fontFamily={fontStyles.autoFittedName.family}
        />
      </div>
    </div>
  </div>
);

export const constructCoverSection = (personalData: PersonalDataContent) => ({
  containerPreference: ["cover"],
  height: DIN_A4_HEIGHT_IN_PX,
  tags: ["half_length", "cover"],
  render: () =>
    renderCoverSection({
      firstName: personalData.firstName ?? "",
      lastName: personalData.lastName ?? "",
    }),
});
