import React from "react";
import { SkillCategoryForm } from "@apply-high/interfaces";

import { ReactComponent as Trash } from "../assets/svgs/trash-alt-light.svg";
import { Button } from "@apply-high/components";
import {
  getCoreCompetencies,
  getSkillsAvailableAsCoreCompetency,
} from "../Util/skills";

type CoreCompetenciesEditorProps = {
  skillCategories: Array<SkillCategoryForm>;
  onChangeCoreCompetency: (skillCategory: SkillCategoryForm) => void;
};

const updateCoreCompetencyInSkillCategory = (
  skillCategories: Array<SkillCategoryForm>,
  skillId: string,
  isCoreCompetency: boolean
) => {
  const relatedSkillCategory = skillCategories.find((skillCategory) =>
    skillCategory.skills.find((skill) => skill.id === skillId)
  );
  const updatedSkillCategory: SkillCategoryForm = {
    ...relatedSkillCategory,
    skills: relatedSkillCategory.skills.map((skill) => {
      if (skill.id !== skillId) {
        return skill;
      }

      return {
        ...skill,
        isCoreCompetency: isCoreCompetency,
      };
    }),
  };

  return updatedSkillCategory;
};

const CoreCompetenciesEditor: React.FC<CoreCompetenciesEditorProps> = ({
  skillCategories,
  onChangeCoreCompetency,
}) => {
  const coreCompetencies = getCoreCompetencies(skillCategories);
  const skillsAvailableAsCoreCompetency = getSkillsAvailableAsCoreCompetency(
    skillCategories,
    coreCompetencies
  );

  return (
    <div>
      <h2 className="h4">Top-Fähigkeiten</h2>
      <p className="text-muted mb-2">
        Hier kannst Du 3 Deiner Fähigkeiten nochmal hervorheben.
      </p>
      <div className="row g-2">
        {coreCompetencies.map((coreCompetency) => (
          <div key={coreCompetency.id} className="col-auto">
            <Button
              size="small"
              onClick={() => {
                const updatedSkillCategory =
                  updateCoreCompetencyInSkillCategory(
                    skillCategories,
                    coreCompetency.id,
                    false
                  );
                onChangeCoreCompetency(updatedSkillCategory);
              }}
            >
              <span className="me-1">{coreCompetency.name}</span>
              <Trash />
            </Button>
          </div>
        ))}
        {coreCompetencies.length < 3 && (
          <div className="col-auto">
            <select
              name="coreCompetency"
              id="coreCompetency"
              className="form-select form-select-sm"
              value="default"
              onChange={(event) => {
                const skillId = event.target.value;
                const updatedSkillCategory =
                  updateCoreCompetencyInSkillCategory(
                    skillCategories,
                    skillId,
                    true
                  );
                onChangeCoreCompetency(updatedSkillCategory);
              }}
            >
              <option value="default">Wähle eine Top-Fähigkeit</option>
              {skillsAvailableAsCoreCompetency.map((skill) => (
                <option key={skill.id} value={skill.id}>
                  {skill.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoreCompetenciesEditor;
