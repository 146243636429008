import * as h from "vhtml";
import { WorkExperienceContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import { fontStyles } from "../font_styles";
import {
  constructTextWithLineBreaks,
  getTranslatedWorkExperienceType,
} from "../../../utility/index";

type RenderWorkExperienceParams = {
  showHeadline: boolean;
  index: number;
  height: number;
  start: string;
  end: string;
  workplaceAndLocation: string;
  type: string;
  employment: string;
  description?: string;
};

const renderWorkExperience = ({
  showHeadline,
  index,
  height,
  start,
  end,
  workplaceAndLocation,
  type,
  employment,
  description,
}: RenderWorkExperienceParams) => (
  <div
    class="work-experience"
    data-section-name={`work-experience:${index}`}
    data-assumed-height={height}
  >
    {showHeadline === true ? (
      <div class="headline">
        <div class="headline__circle"></div>
        <div class="headline__text">
          <span class="headline__first-letter">B</span>erufserfahrung
        </div>
      </div>
    ) : (
      ""
    )}
    <div class="work-experience__item">
      <div class="work-experience__date-column">
        <div class="work-experience__start-date">{start}</div>
        <div class="work-experience__end-date">{end}</div>
      </div>
      <div class="work-experience__content">
        <div class="work-experience__workplace">{workplaceAndLocation}</div>
        <div class="work-experience__employment">
          {type}, {employment}
        </div>
        {description !== undefined ? (
          <div class="work-experience__description">{description}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  </div>
);

/**
 * education timeline may fit on the first page or require another
 * section to be placed on the second page
 */
export const constructWorkExperiencesSections = (
  workExperienceItems: Array<WorkExperienceContent>
) => {
  if (workExperienceItems.length === 0) {
    return [];
  }

  const sections = workExperienceItems.map((workExperience, index) => {
    // headline has only influence on first entry
    const headlineHeight = index === 0 ? 180 : 0;
    const employmentHeight = 20;
    const entryMarginBottom = 30;

    const workplaceText = constructTextWithLineBreaks({
      font: fontStyles.subHeadline,
      text: `${workExperience.workplace}, ${workExperience.location}`,
      containerWidth: 530,
    });

    const descriptionText =
      typeof workExperience.description === "string"
        ? constructTextWithLineBreaks({
            font: fontStyles.baseRegular,
            text: workExperience.description,
            containerWidth: 530,
            includeExplicitNewLines: true,
          })
        : undefined;

    const entryHeight =
      headlineHeight +
      workplaceText.height +
      employmentHeight +
      (descriptionText !== undefined ? descriptionText.height : 0) +
      entryMarginBottom;

    return {
      isVisible: true,
      containerPreference: ["personal-data-rest", "curriculum_vitae"],
      tags: ["cv"],
      height: entryHeight,
      render: () =>
        renderWorkExperience({
          showHeadline: index === 0,
          start: format(workExperience.start, "MM/yyyy"),
          end:
            workExperience.end === undefined
              ? "Heute"
              : format(workExperience.end, "MM/yyyy"),
          workplaceAndLocation: workplaceText.htmlCode,
          description:
            descriptionText !== undefined
              ? descriptionText.htmlCode
              : undefined,
          employment: workExperience.employment,
          type: getTranslatedWorkExperienceType(workExperience.type),
          height: entryHeight,
          index: index,
        }),
    };
  });

  return sections;
};
