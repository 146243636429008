import * as h from "vhtml";

import { AutoFittedText } from "./AutoFittedText";
import { measureText } from "../text_util";
import { fontMap } from "../fontMap";

/*
   While <AutoFittedText /> makes a text exactly as wide as a certain width; <ConstrainedText /> constrains it only if it exceeds the width.
 */

type ConstrainTextParameters = {
  text?: string;
  fontSizeInPx: number;
  maxWidthInPx: number;
  fontFamily: keyof typeof fontMap;
  className?: string;
  isUpperCase?: boolean;
  letterSpacing?: number;
};
export const ConstrainedText = (
  constrainTextParameters: ConstrainTextParameters
): string => {
  const {
    text,
    fontFamily,
    fontSizeInPx,
    isUpperCase,
    className,
    letterSpacing,
    maxWidthInPx,
  } = constrainTextParameters;

  if (text === undefined) {
    return "";
  }

  const naiveWidth = measureText(text, fontFamily, fontSizeInPx, isUpperCase);

  if (naiveWidth <= maxWidthInPx) {
    return (
      <div
        class={className}
        style={`font-family: ${fontFamily}; font-size: ${fontSizeInPx}px`}
      >
        {text}
      </div>
    );
  }

  return (
    <AutoFittedText widthInPx={maxWidthInPx} {...constrainTextParameters} />
  );
};
