import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getFromLocalStorage, saveToLocaleStorage } from "./Storage";
import Layout from "./Components/Layout";
import PersonalDataStep from "./Steps/PersonalDataStep";
import WorkExperienceStep from "./Steps/WorkExperienceStep";
import EducationStep from "./Steps/EducationStep";
import SkillsStep from "./Steps/SkillsStep";
import { Steps } from "./Steps/Steps";
import { JobApplication, RenderContent } from "@apply-high/interfaces";
import DownloadStep from "./Steps/DownloadStep";
import { renderJobApplication } from "@apply-high/renderer";
import { createRenderContent } from "./Util/render-content-factory";
import LandingScreen from "./LandingScreen";

const renderPreviewHTML = (jobApplication: JobApplication) => {
  const renderContent = createRenderContent(jobApplication, false);

  return renderJobApplication({
    themeSlug: "zuse",
    colorId: "petrol",
    tag: "cv",
    renderContent: renderContent as RenderContent,
    addWatermark: false,
  });
};

const usePreviewHTML = (jobApplication: JobApplication) => {
  const [html, setHTML] = React.useState<string>(
    renderPreviewHTML(jobApplication)
  );

  React.useLayoutEffect(
    () => setHTML(renderPreviewHTML(jobApplication)),
    [jobApplication, setHTML, renderPreviewHTML]
  );

  return html;
};

const partners = ["apply-high", "jp-associates"];

function App() {
  const [industry, setIndustry] = React.useState<string>("unknown");
  const [jobApplication, updateJobApplication] = React.useState<JobApplication>(
    getFromLocalStorage()
  );
  const html = usePreviewHTML(jobApplication);

  React.useEffect(() => saveToLocaleStorage(jobApplication), [jobApplication]);

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<LandingScreen />} />
        {partners.map((partner) => (
          <Route
            key={partner}
            path={`/${partner}`}
            element={
              <Layout
                partner={partner}
                industry={industry}
                onIndustryChange={setIndustry}
                html={html}
              />
            }
          >
            <Route
              path={Steps.PERSONAL_DATA}
              element={
                <PersonalDataStep
                  jobApplication={jobApplication}
                  updateJobApplication={updateJobApplication}
                />
              }
            />
            <Route
              path={Steps.WORK_EXPERIENCE}
              element={
                <WorkExperienceStep
                  jobApplication={jobApplication}
                  updateJobApplication={updateJobApplication}
                />
              }
            />
            <Route
              path={Steps.EDUCATION}
              element={
                <EducationStep
                  jobApplication={jobApplication}
                  updateJobApplication={updateJobApplication}
                />
              }
            />
            <Route
              path={Steps.SKILLS}
              element={
                <SkillsStep
                  jobApplication={jobApplication}
                  updateJobApplication={updateJobApplication}
                  industry={industry}
                />
              }
            />
            <Route
              path={Steps.DOWNLOAD}
              element={
                <DownloadStep
                  partner={partner}
                  jobApplication={jobApplication}
                />
              }
            />
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
