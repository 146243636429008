import * as h from "vhtml";

import { Fragment } from "./Fragment";
export type Line = {
  content: string;
  justify: boolean;
};

type LinesParams = {
  lines: Array<Line>;
  fontFamily: string;
  fontSize: number;
  lineHeight: number;
};

export const Lines = ({
  lines,
  fontFamily,
  fontSize,
  lineHeight,
}: LinesParams) => (
  <div
    style={`font-family: '${fontFamily}'; font-size: ${fontSize}px; line-height: ${lineHeight}px; white-space: nowrap`}
  >
    {lines.map((line) =>
      line.justify === true ? (
        `<div style="text-align: justify; text-align-last: justify">${line.content}</div>`
      ) : (
        <Fragment>
          {line.content}
          <br />{" "}
        </Fragment>
      )
    )}
  </div>
);
