import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";

import { DIN_A4_WIDTH_IN_PX } from "../../../utility/index";

type LayoutParams = {
  default: string;
};

const Layout = (params: LayoutParams) => (
  <div class="default" data-container-name="default" data-assumed-height={1043}>
    {params.default}
  </div>
);

export const defaultLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "default",
      height: 1043,
      width: DIN_A4_WIDTH_IN_PX,
    },
  ],
};
