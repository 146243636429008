import * as chroma from "chroma-js";

export const getTextColorWithContrast = (color: string): string => {
  const hslColor = chroma(color).hsl();
  const newColor = hslColor[2] < 0.66 ? "#fff" : "#444";

  return newColor;
};

export const optimizeForegroundColorContrast = (
  foregroundColor: string,
  backgroundColor: string
) => {
  const foregroundColorChroma = chroma(foregroundColor);
  const foregroundColorLightValue = foregroundColorChroma.hsl()[2];
  const backgroundColorLightValue = chroma(backgroundColor).hsl()[2];
  const isForegroundDarker =
    foregroundColorLightValue < backgroundColorLightValue;

  return isForegroundDarker
    ? foregroundColorChroma.darken(1).hex()
    : foregroundColorChroma.brighten(1).hex();
};

interface DifferentColorParameters {
  color: string;
  difference: number;
  lightPercent?: number;
  saturationPercent?: number;
}

export const getDifferenceColor = (
  differentColorParameters: DifferentColorParameters
) => {
  const { color, difference, lightPercent, saturationPercent } =
    differentColorParameters;

  let [hue, saturation, light] = chroma(color).hsl();
  hue += difference;
  if (saturationPercent !== undefined) {
    saturation = saturation * saturationPercent;
  }
  if (lightPercent !== undefined) {
    light = light * lightPercent;
  }

  return chroma.hsl(hue, saturation, light).hex();
};
