import * as h from "vhtml";
import { SkillCategoryContent } from "@apply-high/interfaces";

import { fontStyles } from "../font_styles";
import { constructTextWithLineBreaks } from "../../../utility";

const renderSkills = (
  skillCategories: Array<{
    name: string;
    skills: Array<string>;
  }>,
  height: number
) => (
  <div data-section-name="skills" data-assumed-height={height}>
    <div class="headline">Fähigkeiten</div>
    <div class="skills">
      {skillCategories.map(({ name, skills }) => (
        <div>
          <div class="skills__category-headline">{name}</div>
          <ul class="skills__list">
            {skills.map((skill) => (
              <li>{skill}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
);

/*
XX Fähigkeiten                                                  <-- headlineHeight

Praktische Berufserfahrung     Sozialkompetenzen                <-- subHeadlineHeight

- Einkommensteuer              - Hohe Kommunikationsfähigkeit   <-- autocalculated(may have linebreaks)
- Körperschaftsteuer           - Eigeninitiative                <-- autocalculated(may have linebreaks)
- Bilanzsteuerrecht            - Selbständige Arbeitsweise      <-- autocalculated(may have linebreaks)
                                                                <-- rowMarginBottom
*/
export const constructSkillsSection = (
  skillCategories: Array<SkillCategoryContent>
) => {
  const headlineHeight = 68;
  const subHeadlineHeight = 24;
  const rowMarginBottom = 20;

  const preformattedSkillCategories = skillCategories
    .sort((a, b) => (a.skills.length <= b.skills.length ? 1 : -1))
    .map((category) => {
      const preformattedSkills = category.skills.map((skill) =>
        constructTextWithLineBreaks({
          text: skill,
          font: fontStyles.baseRegular,
          containerWidth: 300,
        })
      );
      return {
        name: category.name,
        skills: preformattedSkills.map((skill) => skill.htmlCode),
        height: preformattedSkills.reduce(
          (totalHeight, skill) => totalHeight + skill.height,
          0
        ),
      };
    });

  const contentHeight = preformattedSkillCategories.reduce(
    (totalHeight, category, index) => {
      if (index % 2 === 1) {
        return totalHeight;
      }
      const rightCategory = preformattedSkillCategories[index + 1];
      const contentHeight =
        rightCategory === undefined
          ? category.height
          : Math.max(category.height, rightCategory.height);

      // last row has no bottom margin in grid
      const actualRowMarginBottom =
        index === preformattedSkillCategories.length - 1 ? 0 : rowMarginBottom;

      const rowHeight =
        subHeadlineHeight + contentHeight + actualRowMarginBottom;
      return totalHeight + rowHeight;
    },
    0
  );

  const sectionHeight = headlineHeight + contentHeight;

  return {
    isVisible: skillCategories.length !== 0,
    containerPreference: ["split__right", "standard"],
    tags: ["cv"],
    height: sectionHeight,
    render: () => renderSkills(preformattedSkillCategories, sectionHeight),
  };
};
