import * as h from "vhtml";
import { EducationContent } from "@apply-high/interfaces";
import { format } from "date-fns";

import { constructTextWithLineBreaks } from "../../../utility/index";
import { fontStyles } from "../font_styles";

type RenderEducationParams = {
  showHeadline: boolean;
  index: number;
  height: number;
  start: string;
  end: string;
  institutionAndLocation: string;
  degree?: string;
  description?: string;
};

const renderEducation = ({
  showHeadline,
  index,
  height,
  start,
  end,
  institutionAndLocation,
  degree,
  description,
}: RenderEducationParams) => (
  <div
    class="education"
    data-section-name={`education:${index}`}
    data-assumed-height={height}
  >
    {showHeadline === true ? (
      <div class="headline">
        <div class="headline__circle"></div>
        <div class="headline__text">
          <span class="headline__first-letter">B</span>ildungsweg
        </div>
      </div>
    ) : (
      ""
    )}
    <div class="education__item">
      <div class="education__date-column">
        <div class="education__start-date">{start}</div>
        <div class="education__end-date">{end}</div>
      </div>
      <div class="education__content">
        <div class="education__institution">{institutionAndLocation}</div>
        <div class="education__degree">{degree}</div>
        {description !== undefined ? (
          <div class="education__description">{description}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  </div>
);

export const constructEducationSections = (
  educationItems: Array<EducationContent>
) => {
  const sections = educationItems.map((education, index) => {
    // headline has only influence on first entry
    const headlineHeight = index === 0 ? 180 : 0;
    const institutionText = constructTextWithLineBreaks({
      font: fontStyles.subHeadline,
      text: `${education.institution}, ${education.location}`,
      containerWidth: 530,
    });

    const entryMarginBottom = 30;
    const degreeHeight = 20;
    const descriptionHeight = 18;
    const entryHeight =
      headlineHeight +
      institutionText.height +
      degreeHeight +
      descriptionHeight +
      entryMarginBottom;

    return {
      containerPreference: ["personal-data-rest", "curriculum_vitae"],
      template: "sections/education/education.html",
      tags: ["cv"],
      height: entryHeight,
      render: () =>
        renderEducation({
          showHeadline: index === 0,
          start: format(education.start, "MM/yyyy"),
          end:
            education.end === undefined
              ? "Heute"
              : format(education.end, "MM/yyyy"),
          institutionAndLocation: institutionText.htmlCode,
          degree: education.degree,
          description: education.description,
          height: entryHeight,
          index: index,
        }),
    };
  });
  return sections;
};
