import { Divider, Checkbox, Button, FadeIn } from "@apply-high/components";
import { Formik } from "formik";
import { JobApplication } from "@apply-high/interfaces";
import { whitelabelOptInSchema } from "@apply-high/schemas";
import React, { useState } from "react";
import { Steps } from "./Steps";
import { getPdf } from "../Client";
import { download } from "../Util/helper";
import { ReactComponent as Edit } from "../assets/svgs/edit-light.svg";
import { ReactComponent as Download } from "../assets/svgs/download-light.svg";
import { Link } from "react-router-dom";

type DownloadStepProps = {
  partner: string;
  jobApplication: JobApplication;
};

type DownloadFormValues = {
  hasOptIn: boolean;
};

const DownloadStep: React.FC<DownloadStepProps> = ({
  partner,
  jobApplication,
}) => {
  const [hasError, setHasError] = useState(false);

  const onSubmit = async (values: DownloadFormValues) => {
    const { hasOptIn } = values;

    try {
      setHasError(false);
      const pdf = await getPdf(partner, hasOptIn, jobApplication);
      download(pdf, "Lebenslauf.pdf");
    } catch (error) {
      setHasError(true);
    }
  };

  return (
    <FadeIn isActive>
      <p className="text-muted">
        Du hast es geschafft und kannst Deinen Lebenslauf jetzt herunterladen.
      </p>
      <Formik<DownloadFormValues>
        initialValues={{ hasOptIn: false }}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={whitelabelOptInSchema}
      >
        {(formikProps) => (
          <form noValidate={true} onSubmit={formikProps.handleSubmit}>
            <Checkbox
              id="hasOptIn"
              label="Mit dem Download meines Lebenslaufs willige ich ein, dass JP associates mich telefonisch oder per E-Mail kontaktieren darf, um mir Jobangebote vorzuschlagen."
              checked={formikProps.values.hasOptIn}
              touched={formikProps.touched.hasOptIn}
              error={formikProps.errors.hasOptIn}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
            />
            {hasError === true && (
              <div className="alert alert-danger">
                Entschuldige, das hätte nicht passieren dürfen! Beim Download
                ist ein unerwarteter Fehler aufgetreten. Bitte versuche es
                später erneut oder kontaktiere uns.
              </div>
            )}
            <div className="mt-3">
              <Button
                type="submit"
                variant="strong"
                disabled={formikProps.isSubmitting}
                loading={formikProps.isSubmitting}
              >
                <span className="me-2">Lebenslauf herunterladen</span>
                <Download />
              </Button>
            </div>
            <Divider text="oder" />
            <p className="text-muted">
              Möchtest du noch Änderungen vornehmen? <br /> Dann kannst Du
              Deinen Lebenslauf hier nochmal überarbeiten.
            </p>
            <Button
              variant="outline"
              disabled={formikProps.isSubmitting}
              render={(props) => (
                <Link {...props} to={`../${Steps.PERSONAL_DATA}`} />
              )}
            >
              <span className="me-2">Lebenslauf bearbeiten</span>
              <Edit />
            </Button>
          </form>
        )}
      </Formik>
    </FadeIn>
  );
};

export default DownloadStep;
