import * as yup from 'yup';

const PASSWORD_REGEX = /^\S*$/;
const NO_URL_CHAR_REGEX = /^((?!(\/|\.)).)*$/;

export const loginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Bitte gib eine gültige E-Mail an.')
      .required('Bitte gib eine E-Mail an.')
      .lowercase()
      .strict(true),
    password: yup
      .string()
      .required('Bitte gib Dein Passwort an.')
      .matches(PASSWORD_REGEX, 'Passwörter dürfen keine Leerzeichen oder Tabs beinhalten.')
      .strict(true),
  })
  .strict(true);

export const registrationSchema = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .required('Bitte gib Deinen Vornamen an.')
      .matches(NO_URL_CHAR_REGEX, 'Der Name darf nicht "." oder "/" enthalten.')
      .strict(true),
    lastName: yup
      .string()
      .required('Bitte gib Deinen Nachnamen an.')
      .matches(NO_URL_CHAR_REGEX, 'Der Name darf nicht "." oder "/" enthalten.')
      .strict(true),
    email: yup
      .string()
      .email('Bitte gib eine gültige E-Mail an.')
      .required('Bitte gib Deine E-Mail an.')
      .lowercase()
      .strict(true),
    password: yup
      .string()
      .required('Bitte gib Dein Passwort an.')
      .matches(PASSWORD_REGEX, 'Passwörter dürfen keine Leerzeichen oder Tabs beinhalten.')
      .min(8, 'Für die Sicherheit Deiner Daten benötigt das Passwort mindestens acht Zeichen.')
      .strict(true),
    accepted: yup
      .boolean()
      .oneOf([true], 'Akzeptiere unsere Datenschutzerklärung und die allgemeinen Geschäftsbedingungen.')
      .strict(true),
  })
  .strict(true);

export const updateUserSchema = yup
  .object()
  .shape({
    firstName: yup.string().required('Bitte gib Deinen Vornamen an.').strict(true),
    lastName: yup.string().required('Bitte gib Deinen Nachnamen an.').strict(true),
    email: yup
      .string()
      .email('Bitte gib eine gültige E-Mail an.')
      .required('Bitte gib Deine E-Mail an.')
      .lowercase()
      .strict(true),
  })
  .strict(true);

export const updatePasswordSchema = yup
  .object()
  .shape({
    old: yup
      .string()
      .required('Bitte gib Dein altes Passwort an.')
      .matches(PASSWORD_REGEX, 'Passwörter dürfen keine Leerzeichen oder Tabs beinhalten.')
      .strict(true),
    updated: yup
      .string()
      .required('Bitte gib ein neues Passwort an.')
      .matches(PASSWORD_REGEX, 'Passwörter dürfen keine Leerzeichen oder Tabs beinhalten.')
      .min(8, 'Für die Sicherheit Deiner Daten benötigt das Passwort mindestens acht Zeichen.')
      .strict(true),
  })
  .strict(true);

export const requestPasswordRecoverySchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Bitte gib eine gültige E-Mail an.')
      .required('Bitte gib Deine E-Mail an.')
      .lowercase()
      .strict(true),
  })
  .strict(true);

export const recoverPasswordSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required('Bitte gib Dein neues Passwort an.')
      .matches(PASSWORD_REGEX, 'Passwörter dürfen keine Leerzeichen oder Tabs beinhalten.')
      .min(8, 'Für die Sicherheit Deiner Daten benötigt das Passwort mindestens acht Zeichen.')
      .strict(true),
  })
  .strict(true);
