import * as h from "vhtml";

import { fontMap } from "../fontMap";
import { Lines, Line } from "./Lines";
import { processLineBreaks, FontStyle } from "../text";

type ConstructTextWithLineBreaksParameters = {
  text: string;
  lineHeight?: number;
  font: FontStyle;
  containerWidth: number;
  justifyText?: boolean;
  letterSpacing?: number;
  includeExplicitNewLines?: boolean;
};

export const constructTextWithLineBreaks = (
  parameters: ConstructTextWithLineBreaksParameters
): {
  htmlCode: string;
  height: number;
} => {
  const {
    text,
    lineHeight,
    containerWidth,
    letterSpacing,
    includeExplicitNewLines,
  } = parameters;

  const fontFamily = parameters.font.family as keyof typeof fontMap;
  const fontSize = parameters.font.sizeInPx;

  const justifyText =
    parameters.justifyText === undefined ? false : parameters.justifyText;

  const lineHeightInPx = lineHeight === undefined ? fontSize * 1.5 : lineHeight;

  const lines: Array<Line> = [];
  const enterLine = (line: string, justify: boolean) => {
    lines.push({
      content: line,
      justify: justify,
    });
  };
  if (includeExplicitNewLines === true) {
    const explicitLines = text.split("\n");
    for (const explicitLine of explicitLines) {
      processLineBreaks(
        explicitLine,
        enterLine,
        fontFamily,
        fontSize,
        containerWidth,
        justifyText,
        letterSpacing
      );
    }
  } else {
    processLineBreaks(
      text,
      enterLine,
      fontFamily,
      fontSize,
      containerWidth,
      justifyText,
      letterSpacing
    );
  }

  return {
    htmlCode: (
      <Lines
        lines={lines}
        fontFamily={fontFamily}
        fontSize={fontSize}
        lineHeight={lineHeightInPx}
      />
    ),
    height: lineHeightInPx * lines.length,
  };
};
