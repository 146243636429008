import * as h from "vhtml";
import { PageLayout } from "@apply-high/interfaces";

import {
  Fragment,
  DIN_A4_HEIGHT_IN_PX,
  DIN_A4_WIDTH_IN_PX,
} from "../../../utility/index";

type LayoutParams = {
  curriculum_vitae: string;
};

const Layout = ({ curriculum_vitae }: LayoutParams) => (
  <Fragment>
    <svg
      class="splash-background"
      id="anschreiben"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewbox="0 0 595.28 841.89"
    >
      <path
        class="primary-color--darker"
        xmlns="http://www.w3.org/2000/svg"
        d="M593.17,0Q408.82,0,224.48,0c-.77.13-.81.73-.85,1.33a14.86,14.86,0,0,1,.29,3.12A44.46,44.46,0,0,0,230.86,23c4.63,7.37,10,14.25,13.88,22.1,2.07,4.17,4,8.33,4.7,13a129.6,129.6,0,0,1,.83,20.56,26,26,0,0,0,1.94,10c2.48,6.5,5.5,12.76,8.09,19.22,1.95,4.88,4.26,9.63,6.68,14.32,4.2,8.17,8.69,16.22,12.15,24.74,4.32,10.61,9.81,20.38,17.6,28.89A203.16,203.16,0,0,1,312.61,196c3.22,4.56,4.79,9.9,7.19,14.85,4,8.35,8.7,16.37,13.43,24.34a6.61,6.61,0,0,1,1.17,3.06,2.61,2.61,0,0,0,.63.43,14.41,14.41,0,0,1,2.42,3.23c.71,1.34,1.42,2.69,2.15,4a63.33,63.33,0,0,1,3.55,7.32c1.7,4.31,1.75,8.88,2.18,13.36a43.69,43.69,0,0,0,3.11,12.21c1.3,3.26,3.1,6.3,4.46,9.53,2.53,6,5.07,12.05,8,17.89,1.48,2.91,2.88,5.88,4.09,8.91a124,124,0,0,0,7.52,16.26A81.41,81.41,0,0,0,386.6,349.7c1.56,1.49,3.2,2.88,4.85,4.24,3.32,2.73,6.52,5.59,10.32,7.71a26.8,26.8,0,0,1,5.82,4.16c2.78,2.74,5.48,5.56,8.48,8.09a89.71,89.71,0,0,0,16.25,11.28A128.1,128.1,0,0,0,457,395.5c8.5,2.39,16.81,5.42,25.5,7.12,6.16,1.2,12.32,2.3,18.56,3.05,2.28.28,4.59.35,6.87.65a54.82,54.82,0,0,0,11.24.06c2.76-.21,5.53-.78,8.29-.76,8.61.06,17.21-.27,25.84.31,6.84.46,13.73.09,20.6.12a6.72,6.72,0,0,0,2.79-.6c5.92-2.69,12.28-3.12,18.61-3.72V.26A3.82,3.82,0,0,0,593.17,0Z"
      />
      <g class="primary-color--very-dark" xmlns="http://www.w3.org/2000/svg">
        <path
          class="st0"
          d="M579,45.9c-2.1-2.5-1.7-6.2,0.8-8.3c0.1-0.1,0.2-0.1,0.2-0.2c2.5-2,6.2-1.7,8.3,0.8c2.1,2.6,1.7,6.4-0.8,8.6 C584.9,48.9,581.1,48.6,579,45.9C579,46,579,45.9,579,45.9z"
        />
        <path
          class="st1"
          d="M568.3,94c-1.9,1.7-4.7,1.5-6.4-0.3c0,0,0,0,0,0c-1.6-2-1.3-4.8,0.6-6.4c1.9-1.5,4.6-1.3,6.2,0.5 c1.7,1.8,1.6,4.5-0.2,6.2C568.4,93.9,568.3,93.9,568.3,94z"
        />
        <path
          class="st1"
          d="M560.4,11.6c-1.5-2-1.1-4.9,0.8-6.4c2-1.5,4.9-1,6.4,1c0,0,0,0,0,0c1.5,1.9,1.1,4.7-0.8,6.1c0,0,0,0,0,0 C564.8,13.9,562,13.6,560.4,11.6C560.4,11.6,560.4,11.6,560.4,11.6z"
        />
        <path
          class="st2"
          d="M566.9,65.6c-1.6,1.3-3.9,1.1-5.2-0.5s-1.1-3.9,0.5-5.2l0,0c1.6-1.3,3.9-1.1,5.2,0.5S568.5,64.3,566.9,65.6z"
        />
        <path
          class="st3"
          d="M591.3,120.7c-0.7-1-0.6-2.3,0.2-3.2c0.9-0.9,2.4-0.8,3.3,0.1s0.8,2.4-0.1,3.3c0,0-0.1,0.1-0.1,0.1 C593.5,121.7,592.1,121.6,591.3,120.7z"
        />
        <path
          class="st4"
          d="M574.3,114.1c-1-1.6-0.8-3.7,0.6-4.9c1.6-1.1,3.7-0.8,4.9,0.7c1.1,1.5,0.9,3.7-0.6,4.9 C577.7,115.8,575.6,115.5,574.3,114.1z"
        />
        <path
          class="st5"
          d="M542.6,100.4c-1,0.8-2.5,0.7-3.3-0.3c-0.8-1-0.7-2.5,0.3-3.3c0,0,0.1-0.1,0.2-0.1c1.1-0.7,2.5-0.5,3.2,0.6 C543.7,98.3,543.5,99.7,542.6,100.4z"
        />
        <path
          class="st6"
          d="M520.1,27.5c-0.7-1-0.5-2.4,0.5-3.2c1-0.7,2.4-0.5,3.2,0.4c0.7,1,0.4,2.4-0.5,3.2 C522.2,28.7,520.8,28.5,520.1,27.5z"
        />
        <path
          class="st7"
          d="M530.1,8.5c-0.8-0.8-0.8-2.2,0-3c0,0,0.1-0.1,0.1-0.1c0.8-0.9,2.2-1,3.1-0.2s1,2.2,0.2,3.1 c-0.1,0.1-0.2,0.2-0.3,0.3C532.4,9.5,531,9.5,530.1,8.5C530.1,8.6,530.1,8.6,530.1,8.5z"
        />
        <path
          class="st8"
          d="M578.4,93.8c0.8,1,0.7,2.4-0.3,3.2c-1,0.8-2.4,0.7-3.2-0.3c-0.8-1-0.7-2.4,0.3-3.2 C576.1,92.7,577.6,92.8,578.4,93.8z"
        />
        <path
          class="st9"
          d="M556.2,39c-0.9-0.9-0.9-2.4,0.1-3.3c0.9-0.9,2.4-0.9,3.3,0.1c0.9,0.9,0.9,2.4-0.1,3.3c0,0-0.1,0-0.1,0.1 C558.5,40,557.1,39.9,556.2,39z"
        />
        <path
          class="st10"
          d="M508.8,0.8c1.6-1.3,4-1,5.3,0.6c1.2,1.7,0.9,4-0.7,5.3c-1.6,1.2-3.8,0.9-5.1-0.5C507,4.5,507.2,2.2,508.8,0.8z"
        />
      </g>
      <path
        class="primary-color--darker--desaturated"
        xmlns="http://www.w3.org/2000/svg"
        d="M108.66,713.18c-3.61,4.14-6.57,8.77-9.77,13.22A56.17,56.17,0,0,1,88,737.67c-5.42,4.28-11.08,8.24-16.4,12.67-4.21,3.49-8.21,7.27-12.73,10.39s-8.69,6.67-13,10.09c-5.58,4.45-10.73,9.43-16.62,13.53-3.71,2.57-7.11,5.6-11,8a64,64,0,0,1-14.77,7.2c-3.21,1-2.79.78-2.79,3.77q0,19.89,0,39.77H.28a5.19,5.19,0,0,1-.22-2.59Q0,655.81.06,471.1a4.74,4.74,0,0,1,.24-2.58c5.54,2.29,11.43,3.37,17.21,4.8,2.46.62,4.89,1.3,6.87,3.14,2.66,2.47,3.84,5.6,4.68,9,.67,2.68.41,5.4.48,8.1,0,1,.07,1.91.11,2.87.14,3.19,2.05,5.37,4.49,7A107.68,107.68,0,0,0,51.43,513c3,1.24,6.19,1.41,9.31,2,.31.06.64,0,.95,0a5,5,0,0,1,4.55,3.22,34.54,34.54,0,0,1,2.59,10.86c.3,3.55,1,6.91,3.09,9.9a13.16,13.16,0,0,1,2.21,7.45c.07,2.77.66,3.52,3.31,4.58a74.31,74.31,0,0,0,10.35,2.81c.77.19,1.55.35,2.33.49a2.15,2.15,0,0,1,1.71,1.5,48,48,0,0,1,2.52,9.91,60.44,60.44,0,0,1,.36,10.75c-.18,5.58,1.36,10.71,3.64,15.68a32.2,32.2,0,0,0,9.29,11.38c2,1.67,4.08,3.3,6.26,4.78,1.88,1.29,2.12,3.37,1.89,5.37a44.69,44.69,0,0,1-2.58,12.09c-2.12,5.16-4.11,10.37-5.92,15.65a19.52,19.52,0,0,1-4.4,6.75c-1.92,2.1-4,4.13-4.78,7a11.7,11.7,0,0,0-.48,3.06c0,5.51-.22,11,.1,16.54.23,4,.22,8,.45,11.94a16.15,16.15,0,0,0,1.93,7.33c1.41,2.4,2.28,5.09,4.09,7.29a10.83,10.83,0,0,0,2.7,2.66,2.71,2.71,0,0,1,1.34,2.37c.11,1.57.61,3.1,0,4.71A1.81,1.81,0,0,0,108.66,713.18Z"
      />
      <path
        class="primary-color--darker"
        xmlns="http://www.w3.org/2000/svg"
        d="M108.68,712c2.34-1.81,4.23-4.09,6.52-6a48,48,0,0,1,13.58-7.9c8.5-3.22,17-6.4,25.5-9.63a37.89,37.89,0,0,1,9.57-1.91c6.52-.7,13.07-.51,19.61-.56A162.29,162.29,0,0,1,202,687.31c7.37.78,14.64,2,21.89,3.49,5.06,1,10.11,2.13,15.19,3.08,3.75.7,7.51,1.31,11.3,1.79a152.48,152.48,0,0,0,21.67,1.44c1.81,0,3.62.47,5.47.39a114.17,114.17,0,0,0,15.65-1.6,49.78,49.78,0,0,1,11.6-1.24c3.61.17,7.16-.46,10.75-.5,4.95-.06,9.89.34,14.81.6,7.49.4,15,.56,22.45,1,8.39.46,16.76,0,25.13.4a83.27,83.27,0,0,1,9.73,1c4.31.69,8.65,1.18,12.93,2.15a77.23,77.23,0,0,1,13.52,4.69c7,3,13.92,6,20.85,9,3.94,1.73,7.87,3.51,11.76,5.37,6.91,3.3,14,6.23,21,9.24,6,2.58,12,5.17,18.07,7.64A240.35,240.35,0,0,0,508.92,743c9.1,2.69,18.09,5.7,27,8.87,4.66,1.66,9.38,3.1,14.13,4.46,6.37,1.83,12.52,4.27,18.67,6.71,7.41,2.94,14.81,5.9,22.24,8.79,1.32.51,2.47,1.54,4,1.44v68.59H.78c-.64-.66-.47-1.49-.47-2.28,0-12.7,0-25.41,0-38.11,0-1.92.65-3,2.45-3.53,9.5-2.91,17.37-8.6,25.2-14.48,9.09-6.8,17.32-14.64,26.42-21.43,12-9,23.34-18.87,35.38-27.84A40.44,40.44,0,0,0,98,725.31c2.59-3.54,5.08-7.16,7.67-10.72C106.48,713.5,107.25,712.35,108.68,712Z"
      />
    </svg>

    <div class="curriculum-vitae__layout">{curriculum_vitae}</div>
  </Fragment>
);

export const cvLayout: PageLayout = {
  render: Layout,
  containers: [
    {
      id: "curriculum_vitae",
      height: DIN_A4_HEIGHT_IN_PX - 100,
      width: DIN_A4_WIDTH_IN_PX,
    },
  ],
};
